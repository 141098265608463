<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="false"
    :dev-mode="devMode"
    :has-errored="false"
    class="score-timeline"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon">
    </template>

    <template #header>
      Score range
    </template>

    <template #actions>
      <download-export-button
        :name="`${exportName}-Compare-Score-Timeline`"
        :is-loading="isLoading"
        :get-el="getTrendEl"
        :get-csv-data="getCsvData"
        :make-ppt-slide="hasDate ? makePptSlide : undefined"
        :get-svg-export-config="getExportConfig"
        short-name="Compare-Score-Timeline"
        show-alerts
      ></download-export-button>
    </template>

    <template #menu>
      <div class="menu-list">
        <widget-menu
          :menus="menus"
          :vertical="false"
          :bound="$el"
          @onSelect="setMenuSelection"
        />
      </div>
    </template>

    <template #error-panel>
    </template>

    <template #content>
      <div v-if="isLoading" class="loading">
        <bf-spinner></bf-spinner>
      </div>
      <div v-else class="content">
        <div
          v-if="sliceOneDataTotal && sliceTwoDataTotal"
          class="overall-stats"
        >
          <div>
            <div>{{ sliceOneName }}</div>
            <div>{{ formatNumber(overallAggVal[0]) }}{{ overallSuffix }}</div>
            <div>{{ dataDisplayLabel }}</div>
          </div>
          <div>
            <div>{{ sliceTwoName }}</div>
            <div>{{ formatNumber(overallAggVal[1]) }}{{ overallSuffix }}</div>
            <div>{{ dataDisplayLabel }}</div>
          </div>
        </div>
        <template v-if="hasDate">
          <div v-if="timelineSeries.length > 0" class="timeline-container">
            <timeline
              timeline-id="score-timeline"
              :all-series="timelineSeries"
              :y-label="`${dataDisplayLabel} - ${sliceOneName}`"
              :y-label-right="`${dataDisplayLabel} - ${sliceTwoName}`"
              :y-axis-left-color="'#383838'"
              :y-axis-right-color="'#383838'"
              :y-range="yRange"
              :y-range-right="yRangeRight"
              :y-value-number-format="yNumberFormat"
              :y-axis-right-names="[`${dataDisplay} (${sliceTwoName})`]"
              :resolution="resolution.toLowerCase()"
              :x-label="dateField"
              :enable-legend="true"
              :records="[]"
              @series-visibility-changed="calculateYRange"
            />
          </div>
          <widget-message-panel v-else>
            <template #title>
              <span>No Data</span>
            </template>
            <template #message>
              <span>There is not sufficient data to display this widget.</span>
            </template>
          </widget-message-panel>
        </template>
      </div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import PptxGenJS from 'pptxgenjs'
import { PropType, computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import icon from 'assets/img/dashboards/dash-score.svg'
import { BfSpinner } from 'components/Butterfly'
import { MenuEntry, WidgetMenuOptions } from 'src/types/components/WidgetMenu.types'
import { Resolution, PivotData, TrendLine } from 'src/types/widgets.types'
import { Block, Requirements } from 'src/types/PivotData.types'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { COLUMN_LABELED_TYPES } from 'src/api/project'
import Timeline from 'src/components/project/analysis/results/widgets/Timeline.vue'
import { getAggregationOffset, getBoxValues, getDataMax, getDataMin, makeDoubleTimelineSlide } from '../DataWidgetUtils'
import dayjs from 'dayjs'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import { Analytics } from 'src/analytics'
import { FetchState } from "src/store/modules/data/mutations"
import QueryUtils from "src/utils/query"
import { formatCsvData } from "./ScoreTimeline.utils"
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'

// These regex match the `top/bottom x` agg method saved on the project schema.
const topBoxRegexLower = /top (\d+) box/
const botBoxRegexLower = /bot (\d+) box/

// These regex match the `top/bottom x` agg method used in the widget and saved in
// the dashboard config.
const topBoxRegexUpper = /Top (\d+) box/
const botBoxRegexUpper = /Bottom (\d+) box/

const resolutionOptions: Resolution[] = [
  'Daily',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Yearly',
]

type TimelineRecords = Record<string, Record<string, {countDocument: number}>>

export const regroup = (
  data: PivotData,
  dateField: string,
  group: string,
  seriesSettings: {
    name: string
    color: string
    lineStyle: TrendLine['lineStyle']
    visible: boolean
    accessor: string | ((d: PivotData['payload'][0]) => number)
  }[],
): [ TimelineRecords, TrendLine[]] => {
  const records: TimelineRecords = Object.fromEntries(
    seriesSettings.map((series) => [series.name, {}])
  )

  const seriesMap: Record<string, TrendLine> = {}

  for (const series of seriesSettings) {
    seriesMap[series.name] = {
      counts: [],
      datetimes: [],
      color: series.color,
      name: series.name,
      lineStyle: series.lineStyle,
      visible: series.visible,
    }
  }

  data.payload.forEach((dataPoint) => {
    if (dataPoint.group__ !== group) return

    const timestamp = dayjs(dataPoint[dateField]).valueOf()

    for (const series of seriesSettings) {
      seriesMap[series.name].counts.push(
        typeof series.accessor === 'string'
          ? +dataPoint[series.accessor]
          : series.accessor(dataPoint)
      )
      seriesMap[series.name].datetimes.push(timestamp)

      records[series.name][dataPoint[dateField]] = {
        countDocument: +dataPoint['frequency_cov']
      }
    }
  })

  return [ records, Object.values(seriesMap) ]
}

const roundDecimal = (val: number) => {
  return Math.round(val * 100) / 100
}

const formatNumber = (val: number): string => {
  return roundDecimal(val).toLocaleString('en', {useGrouping:true})
}

export default defineComponent({
  components: {
    WidgetFrame,
    WidgetMenu,
    BfSpinner,
    Timeline,
    WidgetMessagePanel,
    DownloadExportButton,
  },
  props: {
    sliceOneName: { type: String, required: true },
    sliceTwoName: { type: String, required: true },
    sliceOneFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },
    sliceTwoFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },
    sliceOneData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceTwoData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceOneDataTotal: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceTwoDataTotal: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    dashboardDateFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },

    devMode: {type: Boolean, required: false, default: false},
    masked: { type: Boolean, required: false, default: false },
    dashboardId: { type: Number, required: false, default: null },
    projectId: { type: Number, required: false, default: null },
    currentSite: { type: Object, default: ()=> null, required: false },
    currentProject: { type: Object, default: ()=> null, required: false },
    currentAnalysis: { type: Object, default: ()=> null, required: false },
    config: { type: Object as PropType<WidgetConfig<'score-timeline'> | null>, required: false, default: null },
    dateFields: { type: Array as PropType<SchemaColumn[]>, required: true },
    defaultDateField: { type: String, required: false, default: null },
    segmentFields: { type: Array as PropType<SchemaColumn[]>, required: false, default: () => [] },
    weekStart: { type: String, required: false, default: null },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    dashboardFilters: { type: Array as PropType<ChrysalisFilter[]>, required: false, default: () => [] },
    hasDate: { type: Boolean, required: false, default: false },
    exportName: { type: String, required: false, default: '' },
    dayFirstDates: { type: Boolean, required: false, default: false },
  },
  setup (props, { emit }) {
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const isLoading = computed(() => {
      return props.sliceOneData?.status === 'fetching' ||
             props.sliceTwoData?.status === 'fetching' ||
             props.sliceOneDataTotal?.status === 'fetching' ||
             props.sliceTwoDataTotal?.status === 'fetching'
    })
    const analytics = inject<Analytics>('analytics')

    const dataDisplay = ref<string>('')
    const resolution = ref<Resolution>('Monthly')
    const aggMethod = ref<string>('Average')
    const dateField = ref<string>('')

    const timelineSeries = ref<TrendLine[]>([])
    const records = ref({})

    const overallAggVal = ref<[number, number]>([0, 0])

    const overallSuffix = computed(() => {
      const isBoxAgg = isTopBox.value || isBottomBox.value
      return isBoxAgg ? '%' : ''
    })

    const getTrendEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const getCsvData = () => {
      return formatCsvData(
        timelineSeries.value,
        dateField.value,
        resolution.value,
        aggMethod.value,
      )
    }

    const getExportConfig = () => {
      return {
        dims: getTrendEl()?.getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `
      }
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      if (!props.hasDate) return

      const slide = pptx.addSlide()
      makeDoubleTimelineSlide(
        pptx,
        slide,
        [
          timelineSeries.value.slice(0, 1),
          timelineSeries.value.slice(1),
        ],
        props.exportName + ' - Compare Score Timeline',
        [
          `${dataDisplayLabel.value} - ${props.sliceOneName}`,
          `${dataDisplayLabel.value} - ${props.sliceTwoName}`,
        ],
        props.dayFirstDates,
      )
    }

    const updateConfig = () => {
      // For Top x and Bottom x, we don't specify the int box value (x) in the string,
      // This can cause clashes if it top/bottom x is changed from the project settings.
      let agg = aggMethod.value
      if (isTopBox.value) {
        agg = "Top x box"
      } else if (isBottomBox.value) {
        agg = "Bottom x box"
      }
      const options: NonNullable<typeof props.config>['options'] = {
        dataDisplay: dataDisplay.value,
        resolution: resolution.value,
        aggMethod: agg,
        dateField: dateField.value,
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const isTopBox = computed(() => {
      // Indicates if the current selected aggMethod is topBox.
      return Boolean(typeof aggMethod.value === "string" && aggMethod.value.match(topBoxRegexUpper))
    })

    const isBottomBox = computed(() => {
      // Indicates if the current selected aggMethod is bottomBox.
      return Boolean(typeof aggMethod.value === "string" && aggMethod.value.match(botBoxRegexUpper))
    })

    const currentSchemaColumn = computed(() => {
      return props.schema.find((col) => col.name === dataDisplay.value)
    })

    const topBoxVal = computed(() => {
      // This represents the X value for Top X aggregation if set in the score column
      let topBox = 2
      let agg = currentSchemaColumn.value?.score_aggregation
      let match
      if (
        typeof agg === "string" &&
        (match = agg.match(topBoxRegexLower))
      ) {
        topBox = parseInt(match[1])
      }
      return topBox
    })

    const botBoxVal = computed(() => {
      // This represents the X value for Bottom X aggregation if set in the score column
      let botBox = 2
      let agg = currentSchemaColumn.value?.score_aggregation
      let match
      if (
        typeof agg === "string" &&
        (match = agg.match(botBoxRegexLower))
      ) {
        botBox = parseInt(match[1])
      }
      return botBox
    })

    const defaultAggMethod = computed<string>(() => {
      // This computed prop gets the agg method from the schema and maps it to the method names we use in the
      // widget.
      // The possible agg methods from the schema at the time of write can be:
      // sum, average, median, top x box, bot x box.
      let agg = currentSchemaColumn.value?.score_aggregation || 'Average'
      if (agg.match(topBoxRegexLower)) {
        agg = `Top ${topBoxVal.value} box`
      } else if (agg.match(botBoxRegexLower)) {
        agg = `Bottom ${botBoxVal.value} box`
      } else if (agg === "average") {
        agg = "Average"
      } else if (agg === "median") {
        agg = "Median"
      } else if (agg === "sum") {
        agg = "Sum"
      }
      return agg
    })


    const aggOptions = computed(() => {
      let options: Record<string, string> = {
        'Average': 'mean',
        'Median': 'median',
        'Sum': 'sum',
      }
      options[`Top ${topBoxVal.value} box`] = `top ${topBoxVal.value} box`
      options[`Bottom ${botBoxVal.value} box`] = `bot ${botBoxVal.value} box`
      return options
    })

    const setOptionsFromConfig = () => {
      // If the aggregation Method in dashboard config is 'Top x box', 'Bottom x box',
      // we substitute the value of x with the computed topBoxVal || botBoxVal respectively.
      let agg = props.config?.options?.aggMethod || defaultAggMethod.value
      if (agg === "Top x box") {
        agg = `Top ${topBoxVal.value} box`
      } else if (agg === "Bottom x box") {
        agg = `Bottom ${botBoxVal.value} box`
      }
      const options = {
        dataDisplay: props.config?.options?.dataDisplay || allFields.value[0],
        resolution: props.config?.options?.resolution || 'Monthly',
        aggMethod: agg,
        dateField: props.config?.options?.dateField || props.defaultDateField,
      }
      setMenuSelection('Data', ['dataDisplay', options.dataDisplay], true)
      setMenuSelection('Resolution', ['resolution', options.resolution], true)
      setMenuSelection('Data', ['aggMethod', options.aggMethod], true)
      setMenuSelection('Data', ['dateField', options.dateField], true)
    }

    const refresh = () => {
      window.location.reload()
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const setMenuSelection = (
      menu: string,
      [title, value]: [string, string],
      fromConfig = false
    ) => {
      if (title === 'dataDisplay') {
        dataDisplay.value = value
      } else if (title === 'resolution') {
        resolution.value = value as Resolution
      } else if (title === 'aggMethod') {
        aggMethod.value = value
      } else if (title === 'dateField') {
        dateField.value = value
      }

      if (!fromConfig) {
        analytics?.track.scoreTimeline.changeField(title, value)
        updateConfig()
      }
    }

    const fetchData = (force = false) => {
      const blocks: Block[] = []
      const scoreRange = currentSchemaColumn.value?.score_range
      let filters: ChrysalisFilter[] = []
      if (currentSchemaColumn.value.exclude_out_of_range &&
        isScore.value &&
        scoreRange
      ) {
        filters.push({ field: dataDisplay.value, op: '>=', value: scoreRange[0] })
        filters.push({ field: dataDisplay.value, op: '<=', value: scoreRange[1] })
      }

      if (isTopBox.value || isBottomBox.value) {
        const scoreRange = currentSchemaColumn.value?.score_range
        if (!scoreRange) return
        let boxValues = getBoxValues(
          isTopBox.value ? 'top' : 'bottom',
          scoreRange,
          isTopBox.value ? topBoxVal.value : botBoxVal.value,
        )
        blocks.push({
          'pivot_field': dataDisplay.value,
          'aggfuncs': [{
            'new_column': 'frequency',
            'src_column': 'document_id',
            'aggfunc': 'count',
          }],
          'metric_calculator': {
            'type': 'box',
            'field': dataDisplay.value,
            'impact': false,
            'box_values': boxValues,
          },
        })
      } else {
        blocks.push({
          'aggfuncs': [{
            'new_column': 'aggVal',
            'src_column': dataDisplay.value,
            'aggfunc': aggOptions.value[aggMethod.value] || 'sum',
          }],
        })
      }

      if (!dataDisplay.value) return
      const requirements: Requirements = {
        blocks,
        date_fieldname: dateField.value,
        date_aggregation_offset: getAggregationOffset(resolution.value),
        week_start: props.weekStart,
      }

      const queryField = (filters: ChrysalisFilter[]) => ({
        queries: [{
          name: 'filter_query',
          value:  QueryUtils.convertDashboardFiltersToBotanicQueries(filters, props.dateFields.map(({ name }) => name)),
        }]
      })

      const getDateFilters = (filters: ChrysalisFilter[]) => {
        const dateFields = props.dateFields.map((d) => d.name)
        return filters.filter(({ field }) => dateFields.includes(field))
      }

      emit('requires',
        'compare-score-timeline-slice-one',
        {
          ...requirements,
          ...queryField(props.sliceOneFilters),
        },
        force,
        [
          ...getDateFilters(props.sliceOneFilters),
          ...filters,
        ],
      )

      emit('requires',
        'compare-score-timeline-slice-two',
        {
          ...requirements,
          ...queryField(props.sliceTwoFilters),
        },
        force,
        [
          ...getDateFilters(props.sliceTwoFilters),
          ...filters,
        ],
      )

      // Overall data
      emit('requires',
        'compare-score-timeline-overall-slice-one',
        {
          blocks: blocks,
          ...queryField(props.sliceOneFilters),
        },
        force,
        [
          ...props.dashboardDateFilters,
          ...filters,
        ],
      )
      emit('requires',
        'compare-score-timeline-overall-slice-two',
        {
          blocks: blocks,
          ...queryField(props.sliceTwoFilters),
        },
        force,
        [
          ...props.dashboardDateFilters,
          ...filters,
        ],
      )
    }

    const regroupData = (): void => {
      const isBoxAgg = isTopBox.value || isBottomBox.value

      if (
        !props.sliceOneData.data ||
        !props.sliceTwoData.data
      ) return

      timelineSeries.value = []
      records.value = {}

      ;[
        {
          name: `${dataDisplay.value} (${props.sliceOneName})`,
          data: props.sliceOneData.data,
          color: '#068CCC',
        },
        {
          name: `${dataDisplay.value} (${props.sliceTwoName})`,
          data: props.sliceTwoData.data,
          color: '#DC7070',
        },
      ].forEach(({ name, data, color }) => {
        const [ recs, groupedData ] = regroup(
          data,
          dateField.value,
          'filter_query',
          [
            {
              name,
              color,
              lineStyle: 'solid-line',
              visible: true,
              accessor: isBoxAgg
                ? (data) => +data[`${dataDisplay.value}|box%__`] / 100
                : 'aggVal',
            },
          ],
        )

        const rows = groupedData.filter(({ counts=[] }) => counts.length > 0)
        timelineSeries.value.push(...rows)
        Object.assign(records.value, recs)
      })

      const overallDataPointSliceOne = props.sliceOneDataTotal?.data?.payload.find((dataPoint) =>
        dataPoint.group__ === 'filter_query'
      )
      const overallDataPointSliceTwo = props.sliceTwoDataTotal?.data?.payload.find((dataPoint) =>
        dataPoint.group__ === 'filter_query'
      )

      overallAggVal.value = isBoxAgg
        ? [
          Number(overallDataPointSliceOne?.[`${dataDisplay.value}|box%__`] ?? 0),
          Number(overallDataPointSliceTwo?.[`${dataDisplay.value}|box%__`] ?? 0),
        ]
        : [
          Number(overallDataPointSliceOne?.['aggVal'] ?? 0),
          Number(overallDataPointSliceTwo?.['aggVal'] ?? 0),
        ]

      calculateYRange()
    }

    const dataDisplayLabel = computed(() => {
      return dataDisplay.value? `${dataDisplay.value} (${aggMethod.value})`: "No Numerical/Score columns Found"
    })

    const numericalFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list, field) => field.type === COLUMN_LABELED_TYPES.get('NUMBER')
          ? list.concat(field.name)
          : list,
        [] as string[]
      )
    })

    const scoreFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list, field) => field.type === COLUMN_LABELED_TYPES.get('SCORE')
          ? list.concat(field.name)
          : list,
        [] as string[]
      )
    })


    const isScore = computed(() => {
      return currentSchemaColumn.value?.type === COLUMN_LABELED_TYPES.get('SCORE')
    })

    const allFields = computed(() => {
      return [
        ...scoreFields.value,
        ...numericalFields.value,
      ]
    })

    type OnSelectParam =
      Parameters<NonNullable<WidgetMenuOptions['onSelect']>>

    const menus = computed<WidgetMenuOptions[]>(() => [
      {
        name: 'Data',
        applyButton: true,
        selection: dataDisplayLabel.value,
        validate: {
          'dataDisplay': (value: unknown) => {
            return !!value
          },
        },
        onSelect: (
          changes: OnSelectParam[0],
          applyChange: OnSelectParam[1],
        ) => {
          // If Top/Bot 2 is selected, unselect numerical fields as
          // they are not compatible with this aggregation method
          const agg = changes['aggMethod']?.[0] || aggMethod.value
          if (agg.match(topBoxRegexUpper) || agg.match(botBoxRegexUpper)) {
            const data = changes['dataDisplay']?.[0] || dataDisplay.value
            const isNumerical = numericalFields.value.includes(data)
            if (isNumerical) {
              applyChange('dataDisplay', undefined)
            }
          }
        },
        options: [
          numericalFields.value.length && [
            {
              title: 'Numerical Field',
              group: 'dataDisplay',
              type: 'menu',
              options: numericalFields.value,
              selected: [dataDisplay.value],
              showSelected: true,
              disabled: (changes: Record<string, [string]>) => {
                const agg = changes['aggMethod']?.[0] || aggMethod.value
                const disabled = agg.match(topBoxRegexUpper) || agg.match(botBoxRegexUpper)
                return disabled && `Not compatible with ${agg}`
              },
            },
          ],
          scoreFields.value.length && [
            {
              title: 'Score Field',
              group: 'dataDisplay',
              type: 'menu',
              options: scoreFields.value,
              selected: [dataDisplay.value],
              showSelected: true,
            },
          ],
        ].filter(Boolean) as MenuEntry[][],
        leftOptions: [
          [
            {
              title: 'Display as:',
              group: 'aggMethod',
              type: 'radio',
              options: Object.keys(aggOptions.value),
              selected: [aggMethod.value],
              showSelected: true,
            },
            {
              title: 'Date Field',
              group: 'dateField',
              type: 'radio',
              options: props.dateFields.map((field) => field.name),
              selected: [dateField.value],
              showSelected: true,
            },
          ],
        ],
      },
      props.hasDate && {
        name: 'Resolution',
        applyButton: false,
        selection: resolution.value,
        options: [
          [
            {
              title: 'Resolution',
              group: 'resolution',
              type: 'menu',
              options: resolutionOptions,
              selected: [resolution.value],
              showSelected: true,
            },
          ],
        ],
      },
    ].filter(Boolean) as WidgetMenuOptions[])

    const yRangeLeft = ref([0, 0])
    const yRangeRight = ref([0, 0])

    const calculateYRange = () => {
      const getMinMax = (data: TrendLine[]) => {
        const max = getDataMax(data, Infinity, 1)
        const min = getDataMin(data, max, -Infinity, 1)
        return [min, max]
      }

      const sliceOneData = timelineSeries.value.filter(({ name }) => name.endsWith(`(${props.sliceOneName})`))
      const sliceTwoData = timelineSeries.value.filter(({ name }) => name.endsWith(`(${props.sliceTwoName})`))
      yRangeLeft.value = getMinMax(sliceOneData)
      yRangeRight.value = getMinMax(sliceTwoData)
    }

    const yNumberFormat = computed(() => {
      if (['Top 2 box', 'Bot 2 box'].includes(aggMethod.value)) {
        return 'percentage'
      }
      return 'signAwareRoundedFloat'
    })

    watch(dataDisplay, (newVal, oldVal) => {
      // If the data display field changes, update the aggregation method
      // to match the schema's score aggregation method if set. Only do this
      // if the data display field has changed and the old value is not null,
      // so by default we use the widget's config.
      if (newVal !== oldVal && oldVal) {
        aggMethod.value = defaultAggMethod.value
      }
    })

    watch(defaultAggMethod, (newVal, oldVal) => {
      // There may be state inconsistencies, such that the defaultAggMethod
      // is calculated to the correct value after the widget is mounted.
      // In this case, the correct options for 'aggMethod' needs to be set
      // after the widget is set.
      // So we simply call `setOptionsFromConfig` again to update the non-computed
      // variables.
      // This function sets the dashboard config if set, otherwise uses the defaults
      // from the score column settings, so we can be sure that the right options
      // will be set everytime.
      if (newVal !== oldVal) {
        setOptionsFromConfig()
      }
    })

    watch(() => props.dashboardFilters, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        fetchData()
      }
    }, { deep: true })

    watch(() => props.config, () => {
      setOptionsFromConfig()
    }, {
      deep: true,
    })

    watch([
      dataDisplay,
      resolution,
      aggMethod,
      dateField,
    ], () => {
      fetchData()
    })

    watch(() => [
      props.sliceOneData,
      props.sliceTwoData,
      props.sliceOneDataTotal,
      props.sliceTwoDataTotal,
    ], (val) => {
      if (!val[0] || !val[1]) return
      regroupData()
    })

    onMounted(() => {
      setOptionsFromConfig()
      if (
        props.sliceOneData === null ||
        props.sliceTwoData === null ||
        props.sliceOneDataTotal === null ||
        props.sliceTwoDataTotal === null
      ) {
        fetchData()
      } else {
        regroupData()
      }
    })

    return {
      icon: icon as string,
      refresh,
      contact,
      isLoading,
      setMenuSelection,
      menus,
      timelineSeries,
      dataDisplay,
      resolution,
      dateField,
      yRange: yRangeLeft,
      yRangeRight,
      overallAggVal,
      dataDisplayLabel,
      formatNumber,
      yNumberFormat,
      aggMethod,
      defaultAggMethod,
      overallSuffix,
      calculateYRange,
      root,
      getTrendEl,
      getCsvData,
      makePptSlide,
      getExportConfig,
    }
  },
})
</script>

<style lang="sass" scoped>
@import '~assets/kapiche.sass'

.header-icon
  height: 32px
  width: 100%

.loading
  text-align: center
  padding: 100px 0

.content
  width: 100%

:deep(.insufficient-data-panel)
  height: unset

.overall-stats
  display: flex
  justify-content: center
  margin: 16px 0

  > div
    text-align: center
    font-weight: bold

    > div:nth-child(1)
      font-size: 14px
      font-weight: bold
      font-style: italic
      color: $text-black
      margin-bottom: 20px

    > div:nth-child(2)
      font-size: 24px
      margin-bottom: 6px

    > div:nth-child(3)
      font-size: 16px

    &:not(:first-child)
      margin-left: 32px

    &:nth-child(1)
      color: $blue

    &:nth-child(2)
      color: #DC7070

::v-deep
  .legend-buttons
    display: none
  .timeline-legend-container
    column-count: 2
    margin-top: -6px
    li
      text-align: center
      .clickable-legend
        display: inline-flex

</style>
