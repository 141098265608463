<template>
  <div class="loading-dots">
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingDots',
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.loading-dots
  display: flex
  align-items: center
  justify-content: center
  margin-right: 0.5px
  margin-bottom: 1.5px

.dot
  $size: 3px
  width: $size
  height: $size
  margin-left: 2px
  background-color: $subdued
  border-radius: 50%
  opacity: 0.3
  animation: dotAnimation 1s infinite
  transform: scale(1.2)

.dot:nth-child(1)
  animation-delay: 0s

.dot:nth-child(2)
  animation-delay: 0.2s

.dot:nth-child(3)
  animation-delay: 0.4s

@keyframes dotAnimation
  0%, 100%
    opacity: 0.3
  50%
    opacity: 1
</style>