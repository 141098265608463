<template>
  <div v-if="!requiredStateIsAvailable" class="loader">
    <bf-spinner text-pos="top">
      Loading ...
    </bf-spinner>
  </div>
  <div v-else class="dashboard-grid">
    <resize-listener @resize="resize" />
    <!-- ===================================================================== QUERY VIEW - QUERY NOT FOUND MESSAGE -->
    <!-- error message if query selected but doesn't exist -->
    <div v-if="isView['groupOrTheme'] && !query" class="no-queries">
      <img
        class="mark-faded" src="../../assets/img/mark-faded.png"
        srcset="../../assets/img/mark-faded@2x.png 2x, ../../assets/img/mark-faded@3x.png 3x"
      >
      <h3>Theme not found</h3>
      <p>We can't find the Theme you're looking for. </p>
      <p>It may have been deleted or you are using an outdated URL.</p>
      <br />
      <router-link class="back-button" :to="backToRoute">
        BACK TO OVERVIEW
      </router-link>
    </div>

    <!-- ========================================================================================= DASHBOARD HEADER -->
    <header v-if="!isView['zoomed']">
      <div class="header-metadata">
        <div>
          <span v-show="updatingCount">
            <bf-spinner size="mini" /> Updating counts
          </span>
          <span v-show="!updatingCount">
            <span :class="{ masked: isLoading, unmasked: !isLoading }" class="segment-filter-count">
              <strong>
                {{ number(numDocumentsQuery) }} of {{ number(numDocumentsTotal) }} {{ groupBy? 'groups':'records' }}
              </strong>
              &nbsp;({{ number(documentPercentQuery) }}%)
              <span class="interpunct">·</span>
              <strong>
                {{ number(numVerbatimsQuery) }} of {{ number(numVerbatimsTotal) }} verbatims
              </strong>
              &nbsp;({{ number(verbatimPercentQuery) }}%)
              <template v-if="isView['drillDown'] && dashboardMergedFilters.length > 0">
                <span class="interpunct">·</span>
                <strong>
                  {{ number(numDocumentsQuery) }} of {{ number(numDocumentsAllData) }} filtered records
                </strong>
                &nbsp;({{ number(documentPercentAllData) }}%)
              </template>
            </span>
          </span>
        </div>
        <div v-if="compareMode">
          <span v-show="updatingCount">
            <bf-spinner size="mini" /> Updating counts
          </span>
          <span v-show="!updatingCount">
            <span :class="{ masked: isLoading, unmasked: !isLoading }" class="segment-filter-count">
              <strong>
                {{ number(numDocumentsQueryCompare) }} of {{ number(numDocumentsTotal) }} {{ groupBy? 'groups':'records' }}
              </strong>
              &nbsp;({{ number(documentPercentQueryCompare) }}%)
              <span class="interpunct">·</span>
              <strong>
                {{ number(numVerbatimsQueryCompare) }} of {{ number(numVerbatimsTotal) }} verbatims
              </strong>
              &nbsp;({{ number(verbatimPercentQueryCompare) }}%)
            </span>
          </span>
        </div>
      </div>
    </header>

    <!-- ================================================================================================= TOOLBAR  -->
    <!-- this always shows but might not have anything in it as the controls shown as contextual -->
    <div class="header-controls">
      <!-- if zoomed -->
      <div v-if="isView['zoomed']" :style="{ marginTop: '15px' }">
        <router-link v-if="isView['overview']" class="back-button" :to="backToRoute">
          &lt; BACK TO OVERVIEW
        </router-link>
        <router-link v-if="isView['concept']" class="back-button" :to="backToRoute">
          &lt; BACK TO {{ concepts.join(' & ') || 'CONCEPT' }}
        </router-link>
        <router-link v-if="isView['segment']" class="back-button" :to="backToRoute">
          &lt; BACK TO {{ `${segment.fieldName}: ${segment.segment}` || 'SEGMENT' }}
        </router-link>
        <router-link v-if="isView['query']" class="back-button" :to="backToRoute">
          &lt; BACK TO {{ [query.name || 'THEME', ...concepts].join(' & ') }}
        </router-link>
        <router-link v-if="isView['themeGroup']" class="back-button" :to="backToRoute">
          &lt; BACK TO {{ [query.name || 'THEME GROUP', ...concepts].join(' & ') }}
        </router-link>
      </div>
      <!-- else on concept or query views -->
      <template v-else>
        <router-link v-if="isView['drillDown']" class="back-button" :to="backToRoute">
          &lt; BACK TO OVERVIEW
        </router-link>
      </template>

      <!-- Export limit prompt -->
      <modal-prompt :visible="showDataExportLimitModal" @close="showDataExportLimitModal = false">
        <template #header>
          <div>
            Sorry! This export is unavailable..
          </div>
        </template>
        <template #body>
          <div>
            We currently only support this export when you have {{ number(exportLimit) }} records or less, but this Dashboard has {{ number(numDocumentsQuery) }}.
            <br /><br />
            Please <a href="javascript:window.Intercom('show');">contact us</a> if you would like data exports for this Dashboard.
          </div>
        </template>
      </modal-prompt>
    </div>

    <!-- ========================================================================================= DASHBOARD HEADER part 2 query or concept -->
    <header v-if="!isView['zoomed'] && isView['drillDown']">
      <h2 :class="{ masked: isLoading, unmasked: !isLoading }">
        <template v-if="isView['groupOrTheme'] && query">
          <div class="header-group">
            <img class="header-icon" width="32px" height="32px" :src="dashQueriesIcon" alt="Dashboard queries icon">&nbsp;
            <div v-truncate="getGroupLabel(query) ? 50 : 100">
              {{ query.name.replace(/^group_/, '') }}
            </div>&nbsp;
            <div v-if="getGroupLabel(query)" v-truncate="50" class="group-label">
              [{{ getGroupLabel(query) }}]
            </div>
          </div>
        </template>
        <template v-if="isView['segment'] && segment">
          <div class="header-group">
            <img class="header-icon" width="32px" height="32px" :src="dashQueriesIcon" alt="Dashboard queries icon">&nbsp;
            <div v-truncate="100">
              {{ `${segment.fieldName}: ${segment.segment}` }}
            </div>
          </div>
        </template>
        <template v-if="concepts.length">
          <div v-for="c in concepts" :key="c" class="header-group">
            <svg class="header-icon" width="32px" height="32px">
              <clipPath id="clippy-path">
                <circle cx="16" cy="16" r="16" />
              </clipPath>
              <circle
                clip-path="url(#clippy-path)"
                cx="16" cy="16" r="16" :fill="getConceptColor(c)" stroke="black" stroke-opacity="7%" stroke-width="5px"
              />
            </svg>
            <p>&nbsp;&nbsp;{{ c }}</p>
          </div>
        </template>
      </h2>
    </header>

    <bf-modal :visible="viewGroupModalVisible" @close="viewGroupModalVisible = false">
      <bf-dialog @close="viewGroupModalVisible = false">
        <div class="conversation-viewer">
          <h2>
            Conversation Viewer
          </h2>
          <div>
            <div class="group-by-field">
              {{ groupBy }}: <span class="value">{{ showGroupValue }}</span>
            </div>
            <dropdown
              class="speaker-field-dropdown"
              position="is-bottom-left"
              @input="v => $emit('speaker-field-change', v)"
            >
              <template #trigger>
                Speaker field: <span class="speaker-field">{{ speakerField ? speakerField : 'Select the speaker field in your data...' }} <i class="icon dropdown"></i></span>
              </template>
              <template #default>
                <dropdown-item
                  v-for="option in categoricalFields"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.name }}
                </dropdown-item>
                <dropdown-item
                  v-if="speakerField"
                  :value="''"
                >
                  None
                </dropdown-item>
              </template>
            </dropdown>
            <div v-if="numGroupHitsNotShown > 0" class="group-hits-limit">
              Only showing the first {{ groupHits.length }} messages. {{ numGroupHitsNotShown }} messages not shown.
            </div>
            <div v-for="h in groupHits" :key="h._id" class="utterance" :class="{'utterance-highlight': h._id === groupSourceVerbatimId}">
              <div
                v-if="hasSentiment"
                class="sentiment-indicator"
                :style="{'background': getSentimentColour(h)}"
                :title="getSentimentTooltip(h)"
              >
                &nbsp;
              </div>
              <div class="speaker-label">
                {{ h[speakerField] }}
              </div>
              <div class="verbatim-text">
                {{ h[h._field] }}
              </div>
              <div class="utterance-date">
                {{ h[defaultDateField] }}
              </div>
            </div>
          </div>
        </div>
      </bf-dialog>
    </bf-modal>

    <div
      ref="columnWrapper"
      class="column-wrapper"
      :class="{
        'zoom-wrapper': isView['zoomed']
      }"
    >
      <div v-if="emptyWidgetState" class="empty-state">
        <h2>No widgets to display</h2>
        <p v-if="!currentUser.viewer">
          Click the widgets button in the toolbar above to change your display settings.
        </p>
        <br />
        <a :href="CONST.intercom_links.HIDE_WIDGETS" target="_blank">
          Why is nothing displaying?
        </a>
      </div>
      <div v-else-if="isLoading || updatingCount" class="dashboard-loading">
        <bf-spinner />
      </div>
      <div v-else-if="invalidDateState" class="empty-state">
        <h2>Invalid Date Filters</h2>
        <p>
          The selected date range falls outside the Dashboard date filter.
        </p>
      </div>
      <div v-else-if="emptyDataState" class="empty-state">
        <h2>No records found</h2>
        <p>Adjust filters above to broaden your search.</p>
      </div>
      <template v-else>
        <div
          v-for="widgetColumn, column in widgetList"
          :key="column"
          class="column"
        >
          <div
            v-for="widget, i in widgetColumn"
            :key="`${widget.name}_${column}_${i}`"
            class="dashboard-component"
          >
            <!-- eslint-disable-next-line vue/no-v-bind-arguments-order -->
            <component
              v-bind="widgetProps[widget.name].props"
              :is="widget.component"
              ref="widgets"
              v-on="widgetProps[widget.name].events"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import dayjs from 'dayjs'
  import { PropType, defineComponent, inject } from 'vue'
  import { Location } from "vue-router"
  import { mapGetters, mapActions, mapState } from 'vuex'
  import PptxGenJS from 'pptxgenjs'
  import Tooltip from 'tooltip.js'
  import dashQueriesIcon from 'assets/img/dashboards/dash-queries.svg'
  import { BfSpinner, BfModal, BfDialog, Dropdown, DropdownItem } from 'components/Butterfly'
  import Util from 'src/utils/general'
  import Query from 'src/api/query'
  import QueryUtils, { mergeDashboardFiltersWithBotanicQuery } from "src/utils/query"
  import Data from 'src/utils/data'
  import { mapKeysToState } from 'components/DataWidgets/DataWidgetUtils'
  import { stringify } from 'csv-stringify'
  import { allWidgets, getVisibleWidgets, processFilters, addValueToQueryParam, defaultConfig } from './Dashboard.utils'
  import FormatUtils from 'src/utils/formatters'
  import { AnyWidgetConfig, DashboardConfig, DashboardView, DateRangeTypeEnum, DrilldownWidgetName, OverviewWidgetName, WidgetName } from 'types/DashboardTypes'
  import ResizeListener from 'components/widgets/ResizeListener/ResizeListener.vue'
    import {
    FETCH_DATA,
    FETCH_DATA_QUERY,
    FETCH_DATA_CONTEXT_NETWORK,
    SET_WIDGET_CONFIG,
  } from 'src/store/types'

  import ModalPrompt from 'components/widgets/ModalPrompt.vue'
  // const ModalPrompt = () => import('components/widgets/ModalPrompt.vue')

  import { cloneDeep, isEqual } from 'lodash'
  import { fetch_keyphrases_data, fetch_pivot_data } from 'src/store/modules/data/api'
  import { dateRangeToFilter } from 'src/utils/dates'
  import { isQueryValid } from 'src/components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils'
  import { QueryRow, QueryType, SavedQuery } from 'src/types/Query.types'
  import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
  import { TrendLine } from 'src/types/widgets.types'

  const Overview =  defineComponent({
    components: {
      BfModal,
      BfDialog,
      BfSpinner,
      Dropdown,
      DropdownItem,
      ModalPrompt,
      ResizeListener,
    },
    props: {
      /** project id.  Only if in analyst view. */
      projectId: { type: Number, required: false, default: null },
      /** dashboard id.  In analyst view is integer id.  In viewer mode is a string. */
      dashboardId: { type: [ Number, String ], required: true },
      /** is this an analyst view?  usually passed via route */
      inAnalysis: { type: Boolean, required: true },
      /** string name of widget to display in zoom mode. */
      widget: { type: String, required: false, default: null },
      /** array of concepts */
      concepts: { type: Array as PropType<string[]>, required: false, default: () => [] },
      /** route object to use to navigate back from if in zoom or query mode */
      backToRoute: { type: Object, required: false, default: null },
      /** query id.  If present, assumption is that we are in query mode */
      queryId: { type: Number, required: false, default: null },
      segment: { type: Object as PropType<{ fieldName: string, segment: string }>, required: false, default: null },
      /** type of dashboard we are displaying */
      dashboardType: { type: String, required: false, default: 'overview' },
      /** Add a skeleton mask (used when reloading state between dashboards) */
      dashboardLoading: { type: Boolean, required: true },
      groupBy: { type: String, required: false, default: null },
      queryRows: { type: Array as PropType<QueryRow[]>, required: false, default: () => [] },
      queryRowsCompare: { type: Array as PropType<QueryRow[]>, required: false, default: () => [] },
      compareMode: { type: Boolean, required: false, default: false },
      speakerField: { type: String, required: false, default: 'Member or Agent/bot' },
    },
    setup  () {
      const themeToGroupNameMap = inject<Record<number, string>>('themeToGroupNameMapById')
      const groupToGroupNameMap = inject<Record<number, string>>('groupToGroupNameMapById')

      return {
        themeToGroupNameMap,
        groupToGroupNameMap,
      }
    },
    data () {
      return {
        dashQueriesIcon,
        dataRequirements: {},
        dataQueryRequirements: {},
        dataContextNetworkRequirements: {},
        dataKeys: {},
        currentFiltersTooltip: null as Tooltip|null,
        exportLimit: 100000,
        isExportingQuery: false,
        showDataExportLimitModal: false,
        widgetColumns: this.calculateColumns(window.innerWidth),
        viewGroupModalVisible: false,
        groupHits: null,
        showGroupValue: null,
        maxGroupHits: 500,
        numGroupHitsNotShown: 0,
        groupSourceVerbatimId: null,
        timelineCues: {
          "nps": "Click the button above to generate your insight cues for NPS Timeline.",
          "nps-compare": "Click the button above to generate your insight cues for NPS Compare Timeline.",
          "sentiment": "Click the button above to generate insight cues for Sentiment Timeline.",
          "timeline": "Click the button above to generate your insight cues for Timeline data.",
        },
        timelineCuesLoading: {"nps": false, "nps-compare": false, "sentiment": false, "timeline": false},
        syncing: false,
      }
    },
    computed: {
      ...mapGetters([
        'currentAnalysis',
        'currentModel',
        'currentDashboard',
        'currentUser',
        'savedQueries',
        'hasNPS',
        'hasSentiment',
        'hasNumericFields',
        'hasScoreFields',
        'featureFlags',
        'getFirstSchemaFieldNameWithType',
        'sortedFieldsLimited',
        'sortedFieldsUnlimited',
        'defaultDateField',
        'categoricalFields',
        'dateFields',
        'sortedSegmentsForFieldsLimited',
        'currentSite',
        'widgetBanners',
        'dashboardQueries',
        'dashboardWidgetConfig',
        'expandedSavedQueries',
        'expandedDashboardQueries',
        'expandedThemeGroups',
        'dashboardDateRange',
        'themeGroups',
      ]),
      ...mapState({
        fetchedData (state) {
          return mapKeysToState(state.data, this.dataKeys)
        }
      }),
      isLoading () {
        return this.dashboardLoading || this.syncing
      },
      emptyDataState (): boolean {
        return this.compareMode
          ? !this.numDocumentsQuery && !this.numDocumentsQueryCompare
          : !this.numDocumentsQuery
      },
      // Check if we have a date range filter that falls outside
      // the top-level dashboard date range
      invalidDateState (): boolean {
        const isOutsideRage = (filters: ChrysalisFilter[]) => {
          let rangeIsOutside = false

          const dashboardStart = this.dashboardDateRangeFilters.find((f) => f.op === '>=')
          const dashboardEnd = this.dashboardDateRangeFilters.find((f) => f.op === '<=')
          const dashboardField = dashboardStart?.field

          if (!dashboardField) return false

          const filterStart = filters.filter((f) => {
            return f.field === dashboardField && ['>=', '>'].includes(f.op)
          })

          const filterEnd = filters.filter((f) => {
            return f.field === dashboardField && ['<=', '<'].includes(f.op)
          })

          // The date range ends before the dashboard range
          const endBefore = filterEnd.some((f) => {
            return dayjs(f.value as string).isBefore(dayjs(dashboardStart.value))
          })

          // The date range starts after the dashboard range
          const startAfter = filterStart.some((f) => {
            if (typeof dashboardEnd === 'undefined') return false
            return dayjs(f.value as string).isAfter(dayjs(dashboardEnd.value))
          })

          rangeIsOutside = endBefore || startAfter

          return rangeIsOutside
        }

        return this.compareMode
          ? isOutsideRage(this.validQueryRows) && isOutsideRage(this.validQueryRowsCompare)
          : isOutsideRage(this.validQueryRows)
      },
      validQueryRows () {
        if (this.queryRows.length && !isQueryValid(this.queryRows)) return []
        return QueryUtils.convertBotanicQueriesToDashboardFilters(this.queryRows)
      },
      validQueryRowsCompare () {
        if (this.queryRowsCompare.length && !isQueryValid(this.queryRowsCompare)) return []
        return QueryUtils.convertBotanicQueriesToDashboardFilters(this.queryRowsCompare)
      },
      dashboardDateRangeFilters () {
        return dateRangeToFilter(
          this.dashboardDateRange?.dateField ?? '',
          this.dashboardDateRange?.type ?? DateRangeTypeEnum.ALL_TIME,
          this.dashboardDateRange?.dateFrom ?? '',
          this.dashboardDateRange?.dateTo ?? ''
        )
      },
      dashboardMergedFilters () {
        return this.dashboardDateRangeFilters.concat(this.validQueryRows)
      },
      dashboardMergedFiltersCompare () {
        return this.dashboardDateRangeFilters.concat(this.validQueryRowsCompare)
      },
      fetchParams () {
        return {
          projectId: this.currentDashboard?.project?.id,
          dashboardId: this.currentDashboard?.id,
          analysisId: this.currentDashboard?.analysis?.id,
          topicId: this.currentDashboard?.analysis?.topic_framework_id,
          chrysalisRef: this.currentDashboard?.project?.chrysalis_ref,
        }
      },
      keyExtras () {
        return [this.currentDashboard?.analysis?.modified]
      },
      isView (): DashboardView {
        return {
          drillDown: ['query', 'concept', 'theme-group', 'segment'].includes(this.dashboardType),
          groupOrTheme: ['query', 'theme-group'].includes(this.dashboardType),
          overview: this.dashboardType === 'overview',
          concept: this.dashboardType === 'concept',
          query: this.dashboardType === 'query',
          segment: this.dashboardType === 'segment',
          themeGroup: this.dashboardType === 'theme-group',
          zoomed: !!this.widget,
        }
      },
      emptyWidgetState (): boolean {
        return !Object.values(this.visibleWidgets).some(Boolean)
      },
      visibleWidgets (): Record<WidgetName, boolean> {
        return getVisibleWidgets(
          this.dashboardWidgetConfig,
          this.isView,
          this.hasSentiment,
          this.hasNPS,
          this.hasNumericFields,
          this.hasScoreFields,
          this.hasDate,
          this.widget,
          this.compareMode,
          this.featureFlags,
          this.currentSite?.ai_use,
        )
      },
      displayGroup () {
        if (['query', 'theme-group'].includes(this.dashboardType)) return [this.query?.name, ...this.concepts].join(' & ')
        if (this.dashboardType === 'concept') return this.concepts.join(' & ')
        if (this.dashboardType === 'segment') return `${this.segment.fieldName}:${this.segment.segment}`
        return 'overall__'
      },
      conceptQuery () {
        const includes = this.concepts.map((c: string) => {
          return {
              "type":"match_any",
              "includes":[
                {
                  "type":"text", "value": c
                }
              ]
            }
        })
        return {
          "type":"match_all",
          "includes": includes,
          "excludes": [],
        }
      },
      segmentQuery () {
        return {
          "type":"match_all",
          "includes": [{
            type: 'segment',
            field: this.segment?.fieldName,
            value: this.segment?.segment,
            operator: '=',
          }],
          "excludes": [],
        }
      },
      // This computed prop combines the current theme query with any concepts
      // that have also been selected in the route query. It does not include
      // the dashboard filters. For that value, use filteredQuery.
      themeQuery () {
        if (this.concepts.length) {
          const conceptQs = this.concepts.map((c: string) => {
            return {
                "type":"match_any",
                "includes":[
                  {
                    "type":"text", "value": c
                  }
                ]
              }
          })
          return {
            type: 'match_all',
            includes: [
              this.query.query_value,
              ...conceptQs,
            ]
          }
        }
        return this.query.query_value
      },
      fieldSegmentationWidgetFooterText () : Record<string, string> {
        return {
          'overview': 'Calculated relative to overall data (including filters).',
          'concept': 'Calculated relative to this concept (including filters).',
          'query': 'Calculated relative to this theme (including filters).',
          'theme-group': 'Calculated relative to this theme group (including filters).',
        }
      },
      requiredStateIsAvailable (): boolean {
        return (
          !!this.currentDashboard
          && !!this.currentSite
          && !!this.currentModel
          && !!this.currentAnalysis
        )
      },
      analysisId (): number | undefined {
        return this.currentDashboard?.analysis.id
      },
      sortedTopConceptsAsQueries () {
        // copy the concepts and sort them as expected here:
        // returning with the same Type as a regular query
        return this.currentModel?.sortedConcepts
          .slice()
          // sort by frequency (not by name)
          .sort((a, b) => a.frequencyRank - b.frequencyRank)
          // translate top concepts into "queries"
          .map((c: { name: string, concept_terms: string[] })=>({
            name: c.name,
            query_value: {
              type: "match_any",
              includes: c.concept_terms?.map((term) => {
                return {
                  type: 'text',
                  value: term,
                }
              }),
            },
          }))
      },
      dateFieldNames (): string[] {
        return this.dateFields?.map((f) => f.name) ?? []
      },
      filteredAllData () {
        let query = { type: 'match_all', includes: [{ type: 'all_data' }] }
        return mergeDashboardFiltersWithBotanicQuery(query, this.dashboardMergedFilters, this.dateFieldNames)
      },
      baseQuery () {
        if (this.isView['groupOrTheme'] && !!this.query) {
          return this.themeQuery
        } else if (this.isView['concept'] && this.concepts.length) {
          return this.conceptQuery
        } else if (this.isView['segment'] && !!this.segment) {
          console.log(this.segmentQuery)
          return this.segmentQuery
        } else {
          return {
            type: 'match_all',
            includes: [
              { type: 'all_data' },
            ],
          }
        }
      },
      currentQuery (): QueryType {
        let query = this.isView['groupOrTheme'] && this.query
          ? this.themeQuery
          : {
            type: 'match_all',
            includes: [
              { type: 'all_data' },
            ],
          }
          return query
      },
      filteredQuery () {
        let query = this.currentQuery
        return mergeDashboardFiltersWithBotanicQuery(query, this.dashboardMergedFilters, this.dateFieldNames)
      },
      filteredQueryCompare () {
        let query = {
          type: 'match_all',
          includes: [
            { type: 'all_data' },
          ],
        }
        return mergeDashboardFiltersWithBotanicQuery(query, this.dashboardMergedFiltersCompare, this.dateFieldNames)
      },
      filteredConceptQuery () {
        return mergeDashboardFiltersWithBotanicQuery(this.conceptQuery, this.dashboardMergedFilters, this.dateFieldNames)
      },
      filteredSegmentQuery () {
        return mergeDashboardFiltersWithBotanicQuery(this.segmentQuery, this.dashboardMergedFilters, this.dateFieldNames)
      },
      numDocumentStats (): Array<{ group__: string, frequency_cov: number }> | undefined {
        return this.fetchedData['dashboard_stats']?.data?.payload
      },
      numVerbatimsQuery () {
        return this.fetchedData.verbatim_count?.data?.total_hits ?? 0
      },
      numVerbatimsQueryCompare () {
        return this.fetchedData.verbatim_count_compare?.data?.total_hits ?? 0
      },
      numVerbatimsTotal () {
        return this.fetchedData.verbatim_total?.data?.total_hits ?? 0
      },
      numDocumentsQuery () {
        return this.numDocumentStats?.find(({ group__ }) => group__ === 'filtered_query')?.frequency_cov ?? this.numDocumentsAllData
      },
      numDocumentsQueryCompare () {
        return this.numDocumentStats?.find(({ group__ }) => group__ === 'filtered_query_compare')?.frequency_cov ?? this.numDocumentsAllData
      },
      numDocumentsAllData () {
        return this.numDocumentStats?.find(({ group__ }) => group__ === 'filtered_all_data')?.frequency_cov ?? this.numDocumentsTotal
      },
      numDocumentsTotal () {
        return this.numDocumentStats?.find(({ group__ }) => group__ === 'overall__')?.frequency_cov
      },
      updatingCount (): boolean {
        const loadingStateValues = ['fetching', undefined]
        return loadingStateValues.includes(this.fetchedData['dashboard_stats']?.status) ||
               loadingStateValues.includes(this.fetchedData['verbatim_count']?.status) ||
               loadingStateValues.includes(this.fetchedData['verbatim_total']?.status) ||
               (this.compareMode && loadingStateValues.includes(this.fetchedData['verbatim_count_compare']?.status))
      },
      segmentFields () {
        let is_defined = s => s.index !== undefined && s.index !== null
        // O(1) structure to lookup field membership in the "limited" fields.
        const limited = new Set(this.sortedFieldsLimited.filter(is_defined).map(f => f.name))
        // Now iterate over ALL fields, returning a structure that includes
        // whether the field is also part of the limited set.
        return this.sortedFieldsUnlimited.filter(is_defined).map(f=>(
          {
            name: f.name,
            type: f.type,
            unlimited: !limited.has(f.name),
          }
        ))
      },
      hasDate (): boolean | undefined {
        const schema = this.currentDashboard?.project.schema
        if (schema) {
          return Util.checkSchemaHasDate(schema)
        }
        return undefined
      },
      hasFiles (): boolean | undefined {
        return this.currentDashboard?.project?.file_based
      },
      documentPercentQuery (): number {
        return this.numDocumentsQuery ? ((this.numDocumentsQuery / this.numDocumentsTotal) * 100) : 0
      },
      verbatimPercentQuery (): number {
        return this.numVerbatimsQuery ? ((this.numVerbatimsQuery / this.numVerbatimsTotal) * 100) : 0
      },
      documentPercentQueryCompare (): number {
        return this.numDocumentsQueryCompare ? ((this.numDocumentsQueryCompare / this.numDocumentsTotal) * 100) : 0
      },
      verbatimPercentQueryCompare (): number {
        return this.numVerbatimsQueryCompare ? ((this.numVerbatimsQueryCompare / this.numVerbatimsTotal) * 100) : 0
      },
      documentPercentAllData (): number {
        return this.numDocumentsQuery && this.numDocumentsAllData > 0 ? ((this.numDocumentsQuery / this.numDocumentsAllData) * 100) : 0
      },
      queries () {
        return this.dashboardQueries
      },
      query () {
        if (this.dashboardType === 'query') {
          return this.expandedSavedQueries.find((q) => q.id === this.queryId )
        }
        if (this.dashboardType === 'theme-group') {
          return this.expandedThemeGroups.find((q) => q.id === this.queryId )
        }
        return null
      },
      rawQuery () {
        if (this.dashboardType === 'query') {
          return this.savedQueries.find((q) => q.id === this.queryId )
        }
        if (this.dashboardType === 'theme-group') {
          return this.savedQueries.find((q) => q.id === this.queryId )
        }
        return null
      },
      viewTitle (): string {
        if (this.isView['query'] && !!this.query) return [this.query?.name || 'THEME', ...this.concepts].join(' & ')
        if (this.isView['themeGroup'] && !!this.query) return [this.query?.name || 'THEME GROUP', ...this.concepts].join(' & ')
        if (this.isView['concept'] && this.concepts.length) return this.concepts.join(' & ')
        if (this.isView['segment'] && this.segment) return `${this.segment.fieldName}: ${this.segment.segment}`
        return ''
      },
      showDataExports (): boolean {
        return (!this.currentUser.viewer)
      },
      zoomToRoutes () {
        const inQuery = this.isView['groupOrTheme'] && !!this.queryId
        const hasConcepts = this.concepts.length
        const inConcept = this.isView['concept'] && hasConcepts

        const routeName = `${this.inAnalysis ? 'analysis' : 'viewer'}${inQuery?'-query':''}${inConcept?'-concept':''}-datawidget-zoom`

        const routeQuery = {
          filters: this.$route.query.filters,
          concept: [],
        }
        if (hasConcepts) {
          routeQuery['concept'] = this.concepts
        }

        const route = {
          name: routeName,
          params: {
            dashboardId: this.dashboardId,
            queryId: this.queryId,
            projectId: this.projectId,
            analysisId:  this.analysisId,
            widget: this.widget
          },
          query: routeQuery
        }

        const widgets = [
          'verbatims',
          'themes-concepts',
          'quadrant',
          'timeline',
          'segments',
          'emergent-concepts',
          'pivot-table',
          'segment-correlation',
          'key-phrases',
          'compare-themes-concepts',
          'compare-segments',
          'compare-timeline',
        ]

        return widgets.reduce((routes, widget)=>{
          routes[widget] = {
            ...route,
            params: {
              ...route.params,
              widget
            }
          }
          return routes
        }, {})
      },
      filterQuery () {
        return {
          name: 'filter',
          value: QueryUtils.convertDashboardFiltersToBotanicQueries(
            this.dashboardMergedFilters,
            this.dateFieldNames,
          )
        }
      },
      filterQueryCompare () {
        return {
          name: 'filter_compare',
          value: QueryUtils.convertDashboardFiltersToBotanicQueries(
            this.dashboardMergedFiltersCompare,
            this.dateFieldNames,
          )
        }
      },
      widgetProps (): Record<OverviewWidgetName, Record<string, unknown>> | Record<DrilldownWidgetName, Record<string, unknown>> {
        const shared = {
          'score-timeline': {
            props: {
              'dev-mode': this.featureFlags.dev_mode,
              'banner': this.widgetBanners['score-timeline'],
              'is-zoomed': this.widget==='score-timeline',
              'export-name': this.currentDashboard.name,
              'zoom-to-route': this.zoomToRoutes['score-timeline'],
              'config': this.getWidgetConfig('score-timeline'),
              'date-fields': this.currentModel.dateFields,
              'default-date-field': this.defaultDateField,
              'segment-fields': this.segmentFields,
              'week-start': this.currentDashboard?.project?.week_start,
              'data': this.fetchedData['score-timeline']?.data,
              'status': this.fetchedData['score-timeline']?.status,
              'overall-data': this.fetchedData['score-timeline-overall']?.data,
              'group': this.displayGroup,
              'schema': this.currentDashboard.project.schema,
              'dashboard-filters': this.dashboardMergedFilters,
              'has-date': this.hasDate,
              'day-first-dates': this.currentDashboard.project.day_first_dates,
            },
            events: {
              'requires': this.fetchWidgetData,
              'config-changed': (e) => this.configChanged('score-timeline', e),
            },
          },
          'nps-timeline': {
            props: {
              'is-zoomed': this.widget==='nps-timeline',
              'zoom-to-route': this.zoomToRoutes['nps-timeline'],
              'banner': this.widgetBanners['nps-timeline'],
              'group': this.displayGroup,
              'overall-data': this.fetchedData['nps-timeline-overall'],
              'date-fields': this.currentModel.dateFields,
              'default-date-field': this.defaultDateField,
              'week-start': this.currentDashboard?.project?.week_start,
              'dev-mode': this.featureFlags.dev_mode,
              'export-name': this.currentDashboard.name,
              'widget-config': true,
              'config': this.getWidgetConfig('nps-timeline'),
              'day-first-dates': this.currentDashboard.project.day_first_dates,
              'ai-use': this.currentSite.ai_use,
              'get-timeline-cues': this.featureFlags.timeline_cues,
              'timeline-cues': this.timelineCues["nps"],
              'timeline-cues-loading': this.timelineCuesLoading["nps"],
              'is-staff': this.currentUser.is_staff,
              ...this.fetchedData['nps-timeline'],
            },
            events: {
              'requires': this.fetchWidgetData,
              'timeline-cues': this.fetchTimelineCues,
              'config-changed': (e) => this.configChanged('nps-timeline', e),
            },
          },
          'sentiment-timeline': {
            props: {
              ...this.fetchedData['sentiment-timeline'],
              'is-zoomed': this.widget==='sentiment-timeline',
              'zoom-to-route': this.zoomToRoutes['sentiment-timeline'],
              'banner': this.widgetBanners['sentiment-timeline'],
              'group': this.displayGroup,
              'overall-data': this.fetchedData['sentiment-timeline-overall'],
              'date-fields': this.currentModel.dateFields,
              'default-date-field': this.defaultDateField,
              'week-start': this.currentDashboard?.project?.week_start,
              'dev-mode': this.featureFlags.dev_mode,
              'export-name': this.currentDashboard.name,
              'config': this.getWidgetConfig('sentiment-timeline'),
              'day-first-dates': this.currentDashboard.project.day_first_dates,
              'ai-use': this.currentSite.ai_use,
              'get-timeline-cues': this.featureFlags.timeline_cues,
              'timeline-cues': this.timelineCues["sentiment"],
              'timeline-cues-loading': this.timelineCuesLoading["sentiment"],
              'is-staff': this.currentUser.is_staff,
            },
            events: {
              'requires': this.fetchWidgetData,
              'config-changed': (e) => this.configChanged('sentiment-timeline', e),
              'timeline-cues': this.fetchTimelineCues,
            },
          },
          'timeline': {
            props: {
              ...(this.isView['drillDown'] ? this.fetchedData['timeline_query'] : this.fetchedData['timeline']),
              'banner': this.widgetBanners['timeline'],
              'is-zoomed': this.widget==='timeline',
              'field-frequency': this.fetchedData['field_frequency'],
              'dev-mode': this.featureFlags.dev_mode,
              'export-name': this.currentDashboard.name,
              'has-nps': this.hasNPS,
              'has-sentiment': this.hasSentiment,
              'has-numeric-fields': this.hasNumericFields,
              'queries': this.expandedDashboardQueries,
              'theme-groups': this.expandedThemeGroups,
              'segment-fields': this.segmentFields,
              'viewing-query': this.isView['drillDown'],
              'concept-query': this.isView['concept'] ? this.conceptQuery : null,
              'date-fields': this.currentModel.dateFields,
              'default-date-field': this.defaultDateField,
              'week-start': this.currentDashboard?.project?.week_start,
              'zoom-to-route': this.zoomToRoutes['timeline'],
              'sorted-segments-for-fields-limited': this.sortedSegmentsForFieldsLimited,
              'group': this.displayGroup,
              'schema': this.currentDashboard.project.schema,
              'config': this.getWidgetConfig('timeline'),
              'day-first-dates': this.currentDashboard.project.day_first_dates,
              'ai-use': this.currentSite.ai_use,
              'get-timeline-cues': this.featureFlags.timeline_cues,
              'timeline-cues': this.timelineCues["timeline"],
              'timeline-cues-loading': this.timelineCuesLoading["timeline"],
              'is-staff': this.currentUser.is_staff,
              'dashboard-filters': this.dashboardMergedFilters,
            },
            events: {
              'requires': this.fetchWidgetData,
              'config-changed': (e) => this.configChanged('timeline', e),
              'timeline-cues': this.fetchTimelineCues,
            },
          },
          'segments': {
            props: {
              'banner': this.widgetBanners['segments'],
              'group': this.displayGroup,
              'filter-query': this.isView['overview'] && this.dashboardMergedFilters.length > 0 ? this.filterQuery : null,
              'is-zoomed': this.widget==='segments',
              'export-name': this.currentDashboard.name,
              'data': this.fetchedData['segmentation'],
              'segment-fields': this.segmentFields,
              'dev-mode': this.featureFlags.dev_mode,
              'has-nps': this.hasNPS,
              'mode': this.dashboardType,
              'zoom-to-route': this.zoomToRoutes['segments'],
              'max-rows': this.widget==='segments' ? null : 11,
              'footer-text': this.fieldSegmentationWidgetFooterText[this.dashboardType],
              'config': this.getWidgetConfig('segments'),
            },
            events: {
              'requires': this.fetchWidgetData,
              'segment-clicked': this.toggleFilter,
              'config-changed': (e) => this.configChanged('segments', e),
              'go-to-segment': this.navigateToSegment,
            },
          },
          'emergent-concepts': {
            props: {
              'dev-mode': this.featureFlags.dev_mode,
              'banner': this.widgetBanners['emergent-concepts'],
              // This must not include the dashboard filters, they
              // are provided separately.
              'base-query': [this.baseQuery],
              // This option only used in unmapped
              'exclude-queries': [],
              'query-list-operation': 'intersection',
              'filters': this.dashboardMergedFilters,
              'topic-id': this.currentDashboard.analysis.topic_framework_id,
              'project-id': this.currentDashboard.project.id,
              'chrysalis-ref': this.currentDashboard.project.chrysalis_ref,
              'saved-queries': this.savedQueries,
              'is-zoomed': this.widget==='emergent-concepts',
              'export-name': this.currentDashboard.name,
              'zoom-to-route': this.zoomToRoutes['emergent-concepts'],
              'date-fields': this.currentModel.dateFields,
              'default-date-field': this.defaultDateField,
              'show-drilldown': this.dashboardType==='overview',
              'config': this.getWidgetConfig('emergent-concepts'),
              'groupby-not-supported': !!this.groupBy,
            },
            events: {
              'concept-clicked': (concept) => this.navigateToConcept(concept),
              'config-changed': (e) => this.configChanged('emergent-concepts', e),
            },
          },
        }

        if (this.dashboardType === 'overview') {
          return {
            ...shared,
            'compare-score-timeline': {
              props: {
                'slice-one-filters': this.dashboardMergedFilters,
                'slice-two-filters': this.dashboardMergedFiltersCompare,
                'slice-one-name': this.currentDashboard.slice1_name,
                'slice-two-name': this.currentDashboard.slice2_name,
                'slice-one-data': this.fetchedData['compare-score-timeline-slice-one'],
                'slice-two-data': this.fetchedData['compare-score-timeline-slice-two'],
                'slice-one-data-total': this.fetchedData['compare-score-timeline-overall-slice-one'],
                'slice-two-data-total': this.fetchedData['compare-score-timeline-overall-slice-two'],
                'dashboard-date-filters': this.dashboardDateRangeFilters,

                'dev-mode': this.featureFlags.dev_mode,
                'banner': this.widgetBanners['compare-score-timeline'],
                'is-zoomed': this.widget==='compare-score-timeline',
                'export-name': this.currentDashboard.name,
                'zoom-to-route': this.zoomToRoutes['compare-score-timeline'],
                'config': this.getWidgetConfig('compare-score-timeline'),
                'date-fields': this.currentModel.dateFields,
                'default-date-field': this.defaultDateField,
                'segment-fields': this.segmentFields,
                'week-start': this.currentDashboard?.project?.week_start,
                'status': this.fetchedData['compare-score-timeline']?.status,
                'group': this.displayGroup,
                'schema': this.currentDashboard.project.schema,
                'dashboard-filters': this.dashboardMergedFilters,
                'has-date': this.hasDate,
                'day-first-dates': this.currentDashboard.project.day_first_dates,
              },
              events: {
                'requires': this.fetchWidgetData,
                'config-changed': (e) => this.configChanged('compare-score-timeline', e),
              },
            },
            'compare-nps-timeline': {
              props: {
                'slice-one-filters': this.dashboardMergedFilters,
                'slice-two-filters': this.dashboardMergedFiltersCompare,
                'slice-one-name': this.currentDashboard.slice1_name,
                'slice-two-name': this.currentDashboard.slice2_name,
                'slice-one-data': this.fetchedData['compare-nps-timeline-slice-one'],
                'slice-two-data': this.fetchedData['compare-nps-timeline-slice-two'],
                'slice-one-data-total': this.fetchedData['compare-nps-timeline-overall-slice-one'],
                'slice-two-data-total': this.fetchedData['compare-nps-timeline-overall-slice-two'],
                'dashboard-date-filters': this.dashboardDateRangeFilters,
                'is-zoomed': this.widget==='compare-nps-timeline',
                'zoom-to-route': this.zoomToRoutes['compare-nps-timeline'],
                'banner': this.widgetBanners['compare-nps-timeline'],
                'group': this.displayGroup,
                'date-fields': this.currentModel.dateFields,
                'default-date-field': this.defaultDateField,
                'week-start': this.currentDashboard?.project?.week_start,
                'dev-mode': this.featureFlags.dev_mode,
                'export-name': this.currentDashboard.name,
                'widget-config': true,
                'config': this.getWidgetConfig('compare-nps-timeline'),
                'day-first-dates': this.currentDashboard.project.day_first_dates,
                'ai-use': this.currentSite.ai_use,
                'get-timeline-cues': this.featureFlags.timeline_cues,
                'timeline-cues': this.timelineCues["nps-compare"],
                'timeline-cues-loading': this.timelineCuesLoading["nps-compare"],
              },
              events: {
                'requires': this.fetchWidgetData,
                'timeline-cues': this.fetchTimelineCues,
                'config-changed': (e) => this.configChanged('compare-nps-timeline', e),
              },
            },
            'compare-sentiment-timeline': {
              props: {
                'slice-one-data': this.fetchedData['compare-sentiment-timeline-slice-one'],
                'slice-two-data': this.fetchedData['compare-sentiment-timeline-slice-two'],
                'slice-one-name': this.currentDashboard.slice1_name,
                'slice-two-name': this.currentDashboard.slice2_name,
                'is-zoomed': this.widget==='compare-sentiment-timeline',
                'zoom-to-route': this.zoomToRoutes['compare-sentiment-timeline'],
                'banner': this.widgetBanners['compare-sentiment-timeline'],
                'group': this.displayGroup,
                'slice-one-data-total': this.fetchedData['compare-sentiment-timeline-slice-one-total'],
                'slice-two-data-total': this.fetchedData['compare-sentiment-timeline-slice-two-total'],
                'slice-one-filters': this.dashboardMergedFilters,
                'slice-two-filters': this.dashboardMergedFiltersCompare,
                'date-fields': this.currentModel.dateFields,
                'default-date-field': this.defaultDateField,
                'week-start': this.currentDashboard?.project?.week_start,
                'dev-mode': this.featureFlags.dev_mode,
                'export-name': this.currentDashboard.name,
                'config': this.getWidgetConfig('compare-sentiment-timeline'),
                'day-first-dates': this.currentDashboard.project.day_first_dates,
              },
              events: {
                'requires': this.fetchWidgetData,
                'config-changed': (e) => this.configChanged('compare-sentiment-timeline', e),
              },
            },
            'compare-timeline': {
              props: {
                'slice-one-data': this.fetchedData['compare-timeline-slice-one'],
                'slice-two-data': this.fetchedData['compare-timeline-slice-two'],
                'slice-one-name': this.currentDashboard.slice1_name,
                'slice-two-name': this.currentDashboard.slice2_name,
                'slice-one-filters': this.dashboardMergedFilters,
                'slice-two-filters': this.dashboardMergedFiltersCompare,
                'field-frequency-slice-one': this.fetchedData['field-frequency-slice-one'],
                'field-frequency-slice-two': this.fetchedData['field-frequency-slice-two'],
                'banner': this.widgetBanners['compare-timeline'],
                'is-zoomed': this.widget==='compare-timeline',
                'dev-mode': this.featureFlags.dev_mode,
                'export-name': this.currentDashboard.name,
                'has-nps': this.hasNPS,
                'has-sentiment': this.hasSentiment,
                'has-numeric-fields': this.hasNumericFields,
                'queries': this.expandedDashboardQueries,
                'theme-groups': this.expandedThemeGroups,
                'segment-fields': this.segmentFields,
                'date-fields': this.currentModel.dateFields,
                'default-date-field': this.defaultDateField,
                'week-start': this.currentDashboard?.project?.week_start,
                'zoom-to-route': this.zoomToRoutes['compare-timeline'],
                'sorted-segments-for-fields-limited': this.sortedSegmentsForFieldsLimited,
                'group': this.displayGroup,
                'schema': this.currentDashboard.project.schema,
                'config': this.getWidgetConfig('compare-timeline'),
              },
              events: {
                'requires': this.fetchWidgetData,
                'config-changed': (e) => this.configChanged('compare-timeline', e),
              },
            },
            'compare-segments': {
              props: {
                'banner': this.widgetBanners['compare-segments'],
                'group': this.displayGroup,
                'slice-one-name': this.currentDashboard.slice1_name,
                'slice-two-name': this.currentDashboard.slice2_name,
                'slice-one-query': this.isView['overview'] && this.dashboardMergedFilters.length > 0 ? this.filterQuery : null,
                'slice-two-query': this.isView['overview'] && this.dashboardMergedFiltersCompare.length > 0 ? this.filterQueryCompare : null,
                'is-zoomed': this.widget==='compare-segments',
                'export-name': this.currentDashboard.name,
                'data-slice1': this.fetchedData['segmentation_slice1'],
                'data-slice2': this.fetchedData['segmentation_slice2'],
                'segment-fields': this.segmentFields,
                'dev-mode': this.featureFlags.dev_mode,
                'has-nps': this.hasNPS,
                'mode': this.dashboardType,
                'zoom-to-route': this.zoomToRoutes['compare-segments'],
                'max-rows': this.widget==='compare-segments' ? null : 11,
                'footer-text': this.fieldSegmentationWidgetFooterText[this.dashboardType],
                'config': this.getWidgetConfig('compare-segments'),
              },
              events: {
                'requires': this.fetchWidgetData,
                'segment-clicked': this.toggleFilter,
                'config-changed': (e) => this.configChanged('compare-segments', e),
                'go-to-segment': this.navigateToSegment,
              },
            },
            'pivot-table': {
              props: {
                'baseQuery': [],
                'schema': this.currentDashboard.project.schema,
                'config': this.getWidgetConfig('pivot-table'),
                'is-zoomed': this.widget==='pivot-table',
                'zoom-to-route': this.zoomToRoutes['pivot-table'],
                'saved-queries': this.expandedDashboardQueries,
                'theme-groups': this.expandedThemeGroups,
                'current-model': this.currentModel,
                'concepts': this.sortedTopConceptsAsQueries,
                'export-name': this.currentDashboard.name,
                'dashboard-id': this.currentDashboard.id,
                'has-sentiment': this.hasSentiment,
                'has-nps': this.hasNPS,
                'week-start': this.currentDashboard?.project?.week_start,
                ...this.fetchedData['pivot-table'],
              },
              events: {
                'requires': this.fetchWidgetData,
                'config-changed': (e) => this.configChanged('pivot-table', e),
              }
            },
            'themes-concepts': {
              props: {
                'data': this.fetchedData['themes-concepts'],
                'banner': this.widgetBanners['themes-concepts'],
                'dev-mode': this.featureFlags.dev_mode,
                'export-name': this.currentDashboard.name,
                'has-nps': this.hasNPS,
                'has-numeric-fields': this.hasNumericFields,
                'has-sentiment': this.hasSentiment,
                'footer-text': "Calculated relative to overall data (including filters).",
                'is-zoomed': this.widget==='themes-concepts',
                'queries': this.expandedDashboardQueries,
                'theme-groups': this.expandedThemeGroups,
                'concepts': this.sortedTopConceptsAsQueries,
                'is-filtered': this.dashboardMergedFilters.length > 0,
                'segment-fields': this.segmentFields,
                'zoom-to-route': this.zoomToRoutes['themes-concepts'],
                'config': this.getWidgetConfig('themes-concepts'),
                'schema': this.currentDashboard.project.schema,
                'dashboard-filters': this.dashboardMergedFilters,
              },
              events: {
                'requires': this.fetchWidgetData,
                'requires-phrases': this.fetchPhrases,
                'scroll-to-top': () => this.$emit('scroll-to-top'),
                'config-changed': (e) => this.configChanged('themes-concepts', e),
                'go-to-concept': this.navigateToConcept,
                'go-to-theme': this.navigateToTheme,
                'go-to-theme-group': this.navigateToThemeGroup,
              },
            },
            'compare-themes-concepts': {
              props: {
                'slice-one-filters': this.dashboardMergedFilters,
                'slice-two-filters': this.dashboardMergedFiltersCompare,
                'slice-one-name': this.currentDashboard.slice1_name,
                'slice-two-name': this.currentDashboard.slice2_name,
                'data': this.fetchedData['compare-themes-concepts'],
                'banner': this.widgetBanners['compare-themes-concepts'],
                'dev-mode': this.featureFlags.dev_mode,
                'export-name': this.currentDashboard.name,
                'has-nps': this.hasNPS,
                'has-numeric-fields': this.hasNumericFields,
                'has-sentiment': this.hasSentiment,
                'footer-text': "Calculated relative to overall data (including filters).",
                'is-zoomed': this.widget==='compare-themes-concepts',
                'queries': this.expandedDashboardQueries,
                'theme-groups': this.expandedThemeGroups,
                'concepts': this.sortedTopConceptsAsQueries,
                'is-filtered': this.dashboardMergedFilters.length > 0,
                'segment-fields': this.segmentFields,
                'zoom-to-route': this.zoomToRoutes['compare-themes-concepts'],
                'config': this.getWidgetConfig('compare-themes-concepts'),
                'schema': this.currentDashboard.project.schema,
              },
              events: {
                'requires': this.fetchWidgetData,
                'requires-phrases': this.fetchPhrases,
                'scroll-to-top': () => this.$emit('scroll-to-top'),
                'config-changed': (e) => this.configChanged('compare-themes-concepts', e),
                'go-to-concept': this.navigateToConcept,
                'go-to-theme': this.navigateToTheme,
                'go-to-theme-group': this.navigateToThemeGroup,
              },
            },
            'segment-correlation': {
              props: {
                'dev-mode': this.featureFlags.dev_mode,
                'is-zoomed': this.widget==='segment-correlation',
                'zoom-to-route': this.zoomToRoutes['segment-correlation'],
                'queries': this.expandedDashboardQueries,
                'schema': this.currentDashboard.project.schema,
                'config': this.getWidgetConfig('segment-correlation'),
                'go-to-pivot': this.goToPivot,
                'documentCount': this.numDocumentsTotal,
                'documentQuery': this.numDocumentsQuery,
                'totalDocsCount': this.currentDashboard.project.data_units,
                'dashboard-id': this.currentDashboard.id,
                'export-name': this.currentDashboard.name,
                'groupby-not-supported': !!this.groupBy,
                'has-nps': this.hasNPS,
                'to-query-route': this.inAnalysis
                  ? { name: 'analysis-dashboard-query-view', params: { analysisId: this.analysisId, projectId: this.projectId, queryId: null}, query: { filters: this.$route.query.filters } }
                  : { name: 'dashboard-query-view', params:{ dashboardId: this.dashboardId, queryId: null }, query: { filters: this.$route.query.filters } },
                ...this.fetchedData['segment-correlation'],
              },
              events: {
                'requires': this.fetchWidgetData,
                'config-changed': (e) => this.configChanged('segment-correlation', e),
                'toggle-filter': this.toggleFilter,
                'scroll-to-top': () => this.$emit('scroll-to-top'),
              },
            },
            'quadrant': {
              props: {
                'banner': this.widgetBanners['quadrant'],
                'is-zoomed': this.widget==='quadrant',
                'data': this.fetchedData['quadrant'],
                'dev-mode': this.featureFlags.dev_mode,
                'export-name': this.currentDashboard.name,
                'has-nps': this.hasNPS,
                'has-numeric-fields': this.hasNumericFields,
                'has-sentiment': this.hasSentiment,
                'segment-fields': this.segmentFields,
                'sorted-segments-per-field': this.sortedSegmentsForFieldsLimited,
                'queries': this.expandedDashboardQueries,
                'theme-groups': this.expandedThemeGroups,
                'zoom-to-route': this.zoomToRoutes['quadrant'],
                'concepts': this.sortedTopConceptsAsQueries,
                'config': this.getWidgetConfig('quadrant'),
                'schema': this.currentDashboard.project.schema,
                'dashboard-filters': this.dashboardMergedFilters,
              },
              events: {
                'requires': this.fetchWidgetData,
                'config-changed': (e) => this.configChanged('quadrant', e),
                'segment-clicked': this.toggleFilter,
                'scroll-to-top': () => this.$emit('scroll-to-top'),
                'go-to-concept': this.navigateToConcept,
                'go-to-theme': this.navigateToTheme,
                'go-to-theme-group': this.navigateToThemeGroup,
                'go-to-segment': this.navigateToSegment,
              },
            },
          }
        } else {
          return {
            ...shared,
            'ai-summary': {
              props: {
                'masked': this.isLoading,
                'dev-mode': this.featureFlags.dev_mode,
                'dashboard-id': this.currentDashboard.id,
                'project-id': this.currentDashboard.project.id,
                'current-project': this.currentDashboard.project,
                'current-analysis': this.currentDashboard.analysis,
                'query': this.baseQuery,
                'merged-filters': this.dashboardMergedFilters,
                'saved-queries': this.expandedDashboardQueries,
                'theme-name': this.viewTitle,
                'is-staff': this.currentUser.is_staff,
              },
              events: {
                'add-concept': this.addConceptToView,
                'segment-clicked': this.toggleFilter,
                'config-changed': (e: AnyWidgetConfig) => this.configChanged('ai-summary', e),
              },
            },
            'context-network': {
              props: {
                'banner': this.widgetBanners['context-network'],
                'dev-mode': this.featureFlags.dev_mode,
                'export-name': this.currentDashboard.name,
                'query': this.baseQuery,
                ...this.fetchedData['context-network'],
                'view-title': this.viewTitle,
              },
              events: {
                'requires': this.fetchContextNetworkData,
                'add-concept': this.addConceptToView,
                'go-to-concept': this.navigateToConcept,
              },
            },
            'verbatims': {
              props: {
                'banner': this.widgetBanners['verbatims'],
                'query': this.baseQuery,
                'group-by-field': this.groupBy,
                'saved-queries': this.savedQueries,
                'has-nps': this.hasNPS,
                'has-sentiment': this.hasSentiment,
                'has-date': this.hasDate,
                'has-files': this.hasFiles,
                'dev-mode': this.featureFlags.dev_mode,
                'nps-field-name': this.getFirstSchemaFieldNameWithType('NPS'),
                'model-topics': this.currentModel.topics,
                'model-terms': this.currentModel.terms,
                'model-colors': this.currentModel.conceptColours,
                'is-zoomed': this.widget==='verbatims',
                'zoom-to-route': this.zoomToRoutes['verbatims'],
                'show-annotations': this.currentDashboard && this.currentDashboard.project.show_verbatim_annotations,
                'sentiment-classifier': this.currentDashboard && this.currentDashboard.project.sentiment_classifier,
                ...this.fetchedData['verbatims'],
                'config': this.getWidgetConfig('verbatims'),
              },
              events: {
                'requires': this.fetchUnstructuredData,
                'config-changed': (e) => this.configChanged('verbatims', e),
                'show-grouped-records': this.showGroupedRecords,
              },
            },
            'query-details': {
              props: {
                'banner': this.widgetBanners['query-details'],
                'dev-mode': this.featureFlags.dev_mode,
                'query': this.rawQuery,
                'saved-queries': this.savedQueries,
                'project-id': this.projectId || (this.currentDashboard && this.currentDashboard.project && this.currentDashboard.project.id),
                'analysis-id': this.analysisId,
                'date-field-index': this.currentModel.dateFieldIndex || {},
                'concept-colours': this.currentModel.conceptColours,
                'user-is-viewer': !!this.currentUser.viewer,
              },
              events: {},
            },
            'key-phrases': {
              props: {
                'dev-mode': this.featureFlags.dev_mode,
                'queries': this.expandedDashboardQueries,
                'group': this.displayGroup,
                'export-name': this.currentDashboard.name,
                'zoom-to-route': this.zoomToRoutes['key-phrases'],
                'is-zoomed': this.widget==='key-phrases',
                'show-drilldown': true,
                'config': this.getWidgetConfig('key-phrases'),
                'groupby-not-supported': !!this.groupBy,
                'banner': this.widgetBanners['key-phrases'],
                ...this.fetchedData['key-phrases'],
                'view-title': this.viewTitle,
              },
              events: {
                'requires': this.fetchWidgetData,
                'phrase-clicked': (phrase: string) => this.navigateToConcept(phrase),
                'add-concept': this.addConceptToView,
                'config-changed': (e: AnyWidgetConfig) => this.configChanged('key-phrases', e),
              },
            },
          }
        }
      },
      widgetList () {
        const filteredWidgets =
          allWidgets.filter(({ name }) => this.visibleWidgets[name])

        const numColumns = Math.min(
          3, // max columns
          filteredWidgets.length,
          this.widgetColumns,
        )

        // How much weight each column should have to be roughly equal in height
        const weightSum = filteredWidgets.reduce((sum, { weight }) => sum + weight, 0)
        // We must allow at least the weight of the largest widget for it to fit
        const weightMax = Math.max(...filteredWidgets.map(({ weight }) => weight))
        const weightPerColumn = Math.max(weightMax, Math.ceil(weightSum / numColumns))

        const columns = []
        for (let i = 0; i < numColumns; i++) {
          const column = []
          let columnWeight = 0
          while (filteredWidgets.length) {
            // Continue to the next column if
            // the current one is too full
            const widget = filteredWidgets[0]
            columnWeight += widget.weight
            if (columnWeight > weightPerColumn && (i + 1) < numColumns)
              break

            column.push(widget)
            filteredWidgets.shift()
          }
          columns.push(column)
        }

        const renderColumns = []

        for (const column of columns) {
           if (column.length) renderColumns.push(column)
        }

        return renderColumns
      },
    },
    metaInfo () {
      let title = ''
      if (this.isView['query'] && !!this.query) {
        title = [`${this.query.name} Theme`, ...this.concepts].join(' & ')
      }

      if (this.isView['themeGroup'] && !!this.query) {
        title = [`${this.query.name} Theme Group`, ...this.concepts].join(' & ')
      }

      if (this.isView['concept'] && this.concepts.length) {
        title = `${this.concepts.join(' & ')} Concept`
      }

      if (this.isView['overview']) {
          title = `${this.currentDashboard.name} Dashboard - Kapiche`
      }

      return {
        title: `${title} ${this.isView['zoomed'] ? this.widget : 'View'} - Kapiche`
      }
    },
    watch: {
      widget () {
        this.fetchDataWidgetsData()
      },
      groupBy () {
        this.updateDocumentStats()
        this.fetchDataWidgetsData()
      },
      currentDashboard (val) {
        if (val) {
          this.fetchData()
        }
      },
      dashboardType: {
        immediate: true,
        handler (value, oldValue) {
          this.syncing = true
          this.$emit('set-dashboard-type', value, {
            query: this.query?.name,
            concepts: this.concepts,
            segment: this.segment,
          })
          if (oldValue && value !== oldValue) {
            setTimeout(() => {
              this.syncing = false
            }, 100)
          } else {
            this.syncing = false
          }
        }
      },
      compareMode (val, oldVal) {
        // Fetch data when compare mode is turned on
        if (!oldVal && val) {
          this.fetchData()
          this.fetchDataWidgetsData()
        }
      },
      dashboardMergedFiltersCompare () {
        if (!this.currentDashboard) return

        this.fetchData()
        this.fetchDataWidgetsData()
      },
      dashboardMergedFilters (val) {
        if (!this.currentDashboard) return

        this.fetchData()
        this.fetchDataWidgetsData()

        // Analytics
        const segmentFields = new Set()
        const segments = new Set()
        let dateField

        if (Array.isArray(val)) {
          val.forEach((v) => {
            if (this.dateFields.find((f) => f.name === v.field)) {
              dateField = v.field
            } else {
              segmentFields.add(v.field)
              segments.add(v.segment)
            }
          })
        }
        this.$analytics.track.dashboard.applySegmentFilter(
          this.currentDashboard.id,
          this.currentUser.viewer ? true : false,
          Array.from(segmentFields),
          segments.size,
          dateField !== undefined
        )
      },
      queries () {
        this.fetchDataWidgetsData()
      },
      concepts (newConcepts, oldConcepts) {
        if (!isEqual(oldConcepts, newConcepts)) {
          this.fetchData()
          this.trackViewEvent()
        }
      },
      queryId (newId, oldId) {
        if (oldId !== newId) {
          this.fetchData()
          this.fetchDataWidgetsData()
        }
        this.trackViewEvent()
      },
      segment (newId, oldId) {
        if (!isEqual(oldId, newId)) {
          this.fetchData()
          this.fetchDataWidgetsData()
        }
        this.trackViewEvent()
      },
      $route: {
        immediate: true,
        handler (to) {
          if (to.name.toLowerCase().endsWith('zoom')) {
            this.$analytics.track.dashboard.zoomWidget(this.dashboardId, this.currentUser.viewer, to.params.widget)
          }
        }
      }
    },
    mounted () {
      this.fetchData()
      this.trackViewEvent()
      this.$emit('set-ref', this)
    },
    methods: {
      ...mapActions({
        FETCH_DATA,
        FETCH_DATA_QUERY,
        FETCH_DATA_CONTEXT_NETWORK,
      }),
      number: FormatUtils.number,
      // Analytics for viewing dashboard
      trackViewEvent () {
        if (!this.currentDashboard) return

        switch (this.dashboardType) {
          case 'query': {
            // possible that the query requested doesn't exist so abort here
            if (!this.query) return
            this.$analytics.track.dashboard.viewTheme(
              this.currentDashboard.id,
              !!this.currentUser.viewer,
              this.query.id,
              [this.query.name, ...this.concepts].join(' & '),
            )
            break
          }
          case 'theme-group': {
            // possible that the query requested doesn't exist so abort here
            if (!this.query) return
            this.$analytics.track.dashboard.viewThemeGroup(
              this.currentDashboard.id,
              !!this.currentUser.viewer,
              this.query.id,
              [this.query.name, ...this.concepts].join(' & '),
            )
            break
          }
          case 'concept': {
            this.$analytics.track.dashboard.viewConcept(
              this.currentDashboard.id,
              !!this.currentUser.viewer,
              this.concepts
            )
            break
          }
          case 'overview': {
            this.$analytics.track.dashboard.view(
              this.currentDashboard.id,
              this.currentDashboard?.queries?.length,
              !!this.currentUser.viewer,
              2,
              {
                hasNPS: this.hasNPS,
                hasSentiment: this.hasSentiment,
                hasDate: this.hasDate
              }
            )
            break
          }
        }
      },
      getConceptColor (name: string): string {
        return this.currentModel.conceptColours[name] ?? '#cbcbcb'
      },
      _getIntensity (hit: object, type: string): number {
        const intensityScores = {
          negative: Math.max(0, hit._attributes[`${Data.INVISIBLE_PREFIX}plumeria_negative`]),
          positive: Math.max(0, hit._attributes[`${Data.INVISIBLE_PREFIX}plumeria_positive`]),
          neutral: Math.max(0, hit._attributes[`${Data.INVISIBLE_PREFIX}plumeria_neutral`])
        }
        const totalIntensity = Object.values(intensityScores).reduce((a, b) => a + b)
        if (isNaN(totalIntensity)) {
          // If we can't access the correct plumeria outputs,
          // just set intensity to 1
          return 1
        }
        return intensityScores[type] / totalIntensity
      },
      getSentimentColour (hit: object): string {
        const sentiment = hit['_attributes']['sentiment']
        if (sentiment === undefined) {
          return ''
        }
        if (sentiment === 'negative') {
          return `rgb(238, 56, 36, ${this._getIntensity(hit, sentiment)})`
        } else if (sentiment === 'positive') {
          return `rgb(33, 186, 69, ${this._getIntensity(hit, sentiment)})`
        }
        else if (sentiment === 'mixed') {
          return 'rgb(248, 149, 22, 0.5)'
          /*
          // Gradient for mixed is cool, but potentially misleading as
          // it gives false implication about where in the verbatim the
          // positive or negative sentiment occurs. Needs more thought.
          const pos = getIntensity('positive')
          const neg = getIntensity('negative')
          const mid = pos / (pos + neg) * 100
          return `linear-gradient(rgb(33, 186, 69, ${pos}) ${mid - 1}%, ${mid}%, rgb(238, 56, 36, ${neg})) ${mid + 1}%`
          */
        }
        return `rgb(127, 127, 127, ${this._getIntensity(hit, sentiment)})`
      },
      getSentimentTooltip (hit: object): string {
        const sent = hit['_attributes']['sentiment']
        if (sent === undefined) {
          return ''
        }
        if (sent === 'mixed') {
          // Mixed sentiment is based on logits at the sentence level
          // We only have verbatim-level logits, so we can't display intensity
          return 'Sentiment: Mixed'
        }
        return `Sentiment: ${sent.charAt(0).toUpperCase() + sent.slice(1)} \nIntensity: ${this._getIntensity(hit, sent).toFixed(2)}`
      },
      async fetchDataWidgetsData () {
        // TODO: if null then request all, otherwise just fetch that widget
        // need a canonical list of the widgets that can be used in the url
        // or otherwise a mapping of them to do this
        Object.keys(this.dataRequirements).forEach(widgetName => {
            this.fetchWidgetData(widgetName, ...this.dataRequirements[widgetName])
        })
        if (this.isView['drillDown']) {
          Object.keys(this.dataQueryRequirements).forEach(widgetName => {
            this.fetchUnstructuredData(widgetName, this.dataQueryRequirements[widgetName])
          })
          Object.keys(this.dataContextNetworkRequirements).forEach(widgetName => {
            this.fetchContextNetworkData(widgetName, ...this.dataContextNetworkRequirements[widgetName])
          })
        }
      },
      async fetchPhrases (id: string,
                          widget_requirements: Record<string, unknown>,
                          force = false,
                          use_filters = true,
                          fetch_method=fetch_keyphrases_data) {
        if (!this.currentDashboard) return

        // If `use_filters` is set to false then dashboard filters are not included in the call to FETCH_DATA
        // This option is needed for the segmentation chart widget to support observed/expected for filters
        // on the overview dashboard.
        const filters = use_filters ? processFilters(this.dashboardMergedFilters) : []
        const requirements = {...widget_requirements}
        const key = await this.FETCH_DATA({
          fetch_method,
          requirements,
          filters,
          force,
          params: this.fetchParams,
          keyExtras: this.keyExtras
        })

        // Vue.set(this.dataRequirements, id, [widget_requirements, force, use_filters, fetch_method])
        this.dataRequirements[id] = [widget_requirements, force, use_filters, fetch_method]
        // Vue.set(this.dataKeys, id, key)
        this.dataKeys[id] = key
      },
      async fetchWidgetData (
        id: string,
        widget_requirements: Record<string, unknown>,
        force = false,
        use_filters = true as boolean | Record<string, unknown>[],
        fetch_method=fetch_pivot_data,
      ) {
        if (!this.currentDashboard) return

        // If `use_filters` is set to false then dashboard filters are not included in the call to FETCH_DATA
        // This option is needed for the segmentation chart widget to support observed/expected for filters
        // on the overview dashboard.
        const filters = use_filters
          ? processFilters(
              use_filters === true ? this.dashboardMergedFilters : use_filters
            )
          : []
        const requirements = {...widget_requirements}

        // if viewing a query dashboard then only include that query in
        // the requirements payload
        if (this.isView['groupOrTheme']) {
          requirements.queries = [{
            name: [this.query?.name, ...this.concepts].join(' & '),
            value: this.themeQuery
          }]
        }

        // viewing a concept dashboard then only use a query for that
        // concept in the requirements payload
        if (this.dashboardType === 'concept') {
          requirements.queries = [{name: this.concepts.join(' & '), value: this.conceptQuery}]
        }

        if (this.dashboardType === 'segment') {
          requirements.queries = [{
            name: `${this.segment?.fieldName}:${this.segment?.segment}`,
            value: this.segmentQuery
          }]
        }

        if (!this.isView['drillDown'] && requirements.queries) {
          requirements.queries = requirements.queries.map(q=>{
            const sq = this.expandedSavedQueries.find(e=>e.name === q.name)
            return {
              name: sq?.name || q.name,
              value: sq?.query_value || q.value
            }
          })
        }

        if (this.groupBy) {
          requirements.groupby = this.groupBy
        }

        const key = await this.FETCH_DATA({
          fetch_method: fetch_method,
          requirements,
          filters,
          force,
          params: { ...this.fetchParams, id },
          keyExtras: this.keyExtras,
        })

        // Vue.set(this.dataRequirements, id, [widget_requirements, force, use_filters, fetch_method])
        this.dataRequirements[id] = [widget_requirements, force, use_filters, fetch_method]
        // Vue.set(this.dataKeys, id, key)
        this.dataKeys[id] = key
      },
      async fetchUnstructuredData (id, requirements) {
        if (!this.currentDashboard) return
        const projectId = this.currentDashboard.project.id
        const analysisId = this.currentDashboard.analysis.id
        const analysisModified = this.currentDashboard.analysis.modified
        const query = mergeDashboardFiltersWithBotanicQuery(requirements.query, this.dashboardMergedFilters, this.dateFieldNames)
        const options = requirements.options
        const key = await this.FETCH_DATA_QUERY(
          {
            projectId,
            analysisId,
            analysisModified,
            query,
            options,
          }
        )
        // Vue.set(this.dataQueryRequirements, id, requirements)
        this.dataQueryRequirements[id] = requirements
        // Vue.set(this.dataKeys, id, key)
        this.dataKeys[id] = key
      },
      async fetchContextNetworkData (id: string, requirements: Record<string, unknown>, force = false) {
        if (!this.currentDashboard) return
        const analysisModified = this.currentDashboard.analysis.modified
        const query = mergeDashboardFiltersWithBotanicQuery(requirements.query, this.dashboardMergedFilters, this.dateFieldNames)
        let options = requirements.options
        // Provide a baseline for determining influence;
        // Useful for calculating expected frequencies when
        // structured data filters are applied.
        const baselineQuery = QueryUtils.extractStructuredFiltersFromQuery(query)
        options = {
          ...options,
          baseline_query: JSON.stringify(baselineQuery)
        }

        const key = await this.FETCH_DATA_CONTEXT_NETWORK(
          {
            analysisModified,
            query,
            options,
            force,
            params: this.fetchParams,
            savedQueries: this.savedQueries
          }
        )
        // Vue.set(this.dataContextNetworkRequirements, id, [requirements, force])
        this.dataContextNetworkRequirements[id] = [requirements, force]
        // Vue.set(this.dataKeys, id, key)
        this.dataKeys[id] = key
      },
      async getVerbatimCount () {
        if (!this.currentDashboard) return
        const projectId = this.currentDashboard.project.id
        const analysisId = this.currentDashboard.analysis.id
        const analysisModified = this.currentDashboard.analysis.modified

        const allDataQuery = {
          type: 'match_all',
          includes: [
            { type: 'all_data' },
            { type: 'nonempty_data' },
          ]
        }

        let query

        if (this.isView['concept']) {
          query = this.filteredConceptQuery
        } else if (this.isView['segment']) {
          query = this.segmentQuery
        } else if (this.isView['groupOrTheme'] && this.query) {
          query = this.themeQuery
        } else {
          query = {
            type: 'match_all',
            includes: [
              { type: 'all_data' },
              // Exclude empty verbatims
              { type: 'nonempty_data' },
            ],
          }
        }

        const filteredQuery = mergeDashboardFiltersWithBotanicQuery({ ...query }, this.dashboardMergedFilters, this.dateFieldNames)
        const filteredQueryCompare = mergeDashboardFiltersWithBotanicQuery({ ...query }, this.dashboardMergedFiltersCompare, this.dateFieldNames)
        const options = { start: 0, limit: 0 }

        const key1 = await this.FETCH_DATA_QUERY(
          {
            projectId,
            analysisId,
            analysisModified,
            query: filteredQuery,
            options,
          }
        )
        const key2 = await this.FETCH_DATA_QUERY(
          {
            projectId,
            analysisId,
            analysisModified,
            query: allDataQuery,
            options,
          }
        )
        const key3 = this.compareMode && await this.FETCH_DATA_QUERY(
          {
            projectId,
            analysisId,
            analysisModified,
            query: filteredQueryCompare,
            options,
          }
        )

        // Vue.set(this.dataKeys, 'verbatim_count', key1)
        this.dataKeys['verbatim_count'] = key1
        // Vue.set(this.dataKeys, 'verbatim_total', key2)
        this.dataKeys['verbatim_total'] = key2
        if (this.compareMode) {
          // Vue.set(this.dataKeys, 'verbatim_count_compare', key3)
          this.dataKeys['verbatim_count_compare'] = key3
        }
      },
      async fetchTimelineCues (
        timelineType: string,
        data: TrendLine[],
        dateField: string,
        promptType: string,
        templateData: Record<string, string> = {},
      ) {
        try {
          const typeMap = {
            nps: "NPS Timeline Widget",
            nps_compare: "NPS Compare Timeline Widget",
            sentiment: "Sentiment Timeline Widget",
            timeline: "Timeline Widget",
          }
          this.$analytics.track.dashboard.timelineCues(this.currentDashboard.id, typeMap[timelineType])
          this.timelineCuesLoading[timelineType] = true
          let response_data =  await Query.generateTimelineCues(
            this.currentDashboard.project.id,
            this.currentDashboard.project.chrysalis_ref,
            this.currentDashboard.analysis.topic_framework_id,
            dateField,
            promptType,
            data,
            templateData,
          )
          this.timelineCues[timelineType] = response_data.payload.cues ?
            response_data.payload.cues:
            "Could not generate cues at this time, please contact support."
          // Used to debug and correlate prompts with the output from the model,
          // Do not remove.
          if (this.currentUser.is_staff) {
            console.log("Prepared Prompt for Cues:", response_data.payload.prepared_prompt)
          }
        } finally {
          this.timelineCuesLoading[timelineType] = false
        }
      },
      async updateDocumentStats () {
        const requirements = {
          // add block
          blocks: [
            {
              aggfuncs: [
                {
                  new_column: 'frequency_cov',
                  src_column: 'document_id',
                  aggfunc: 'count',
                },
              ],
            }
          ],
          // add queries
          queries: [
            // a query for the entire set count (denominator)
            // will be added by default as result group { name: 'overall__' }
            // query the filtered data count (denominator)
            {
              name: 'filtered_all_data',
              value: this.filteredAllData,
            },
            // optionally add the filtered query count (numerator)
            this.isView['groupOrTheme'] && this.query && {
              name: 'filtered_query',
              value: this.filteredQuery,
            },
            this.isView['concept'] && this.concepts.length && {
              name: 'filtered_query',
              value: this.filteredConceptQuery,
            },
            this.isView['segment'] && !!this.segment && {
              name: 'filtered_query',
              value: this.filteredSegmentQuery,
            },
            this.compareMode && {
              name: 'filtered_query_compare',
              value: this.filteredQueryCompare,
            },
          ].filter(Boolean),
        }

        if (this.groupBy) {
          requirements.groupby = this.groupBy
        }

        this.getVerbatimCount()

        const key = await this.FETCH_DATA({
          params: this.fetchParams,
          keyExtras: this.keyExtras,
          requirements,
          filters: []
          })
        // Vue.set(this.dataKeys, 'dashboard_stats', key)
        this.dataKeys['dashboard_stats'] = key
      },
      async updateFilterLabel () {
        if (this.currentFiltersTooltip) {
          this.currentFiltersTooltip.dispose()
          this.currentFiltersTooltip = null
        }
        if (this.dashboardMergedFilters?.length > 0) {
          let html_template =
            '<div class="active-filters-tooltip" style="font-size: 13px; padding: 5px 3px; min-width: 250px">' +
            '<h style="color: hsl(196, 12%, 63%); font-weight: bold">Dashboard filtering data by:</h>'
          for (const filter of this.dashboardMergedFilters) {
            html_template += `
              <p style="margin-bottom: 0;">
                <span style="text-transform: capitalize;">${Util.truncateText(filter.field, 60).replace(/__$/, '')}:</span>
                <strong>${filter.op === 'in' ? '' : filter.op} ${Util.truncateText([].concat(filter.value).map(x => x || '(No Value)').join(', '), 60)}</strong>
              </p>
            `
          }
          html_template += '</div>'

          let el = this.$el.querySelector('.segment-filter-count')
          if (!el) return
          this.currentFiltersTooltip = new Tooltip(el as HTMLElement, {
            html: true,
            container: this.$el as HTMLElement,
            placement: 'bottom',
            title: html_template,
            popperOptions: {
              removeOnDestroy: true
            }
          })
        }
      },
      async fetchData () {
        await this.updateDocumentStats()
        await this.updateFilterLabel()
      },
      toggleFilter (field: string, segment: string): void {
        const filter = [{field, segment}]

        if (field === 'All Segments') {
          const values = segment.split(':')
          filter[0].field = values[0].trim()
          filter[0].segment = values[1].trim()
        }
        this.$emit('toggleFilters', filter)
        this.$analytics.track.dashboard.toggleSegmentFilter(this.currentDashboard.id, field, segment)
      },
      async exportQuery () {
        if (this.numDocumentsQuery > this.exportLimit) {
          this.showDataExportLimitModal = true
          return
        }
        this.isExportingQuery = true
        try {
          let query
          let exportName = 'overview'
          if (this.isView['drillDown']) {
            query = this.baseQuery
            query = mergeDashboardFiltersWithBotanicQuery(query, this.dashboardMergedFilters, this.dateFieldNames)
            exportName = this.isView['groupOrTheme'] ? 'theme' : 'concept'
          } else {
            query = QueryUtils.convertDashboardFiltersToBotanicQueries(
              this.dashboardMergedFilters,
              this.dateFieldNames,
            )
          }
          const res = await Query.runQueryExport(
            this.currentDashboard.project.id,
            this.currentDashboard.analysis.id,
            query,
            this.savedQueries,
          )
          stringify([res.headers].concat(res.rows), (_, csvString) => {
            Util.downloadCsv(csvString, `dashboard-${exportName}-result`)
          })
          if (this.isView['query']) {
            this.$analytics.track.analysis.downloadExport(
              'Dashboard Query Results',
              'CSV',
              {
                queryName: [this.query?.name, ...this.concepts].join(' & '),
                queryValue: JSON.stringify(this.query),
                concepts: this.concepts,
              }
            )
          }
          if (this.isView['concept']) {
            this.$analytics.track.analysis.downloadExport(
              'Dashboard Concept Results',
              'CSV',
              {
                concepts: this.concepts.join(' & '),
              }
            )
          }
        } finally {
          this.isExportingQuery = false
        }
      },
      addConceptToView (newConcept: string): void {
        if (!newConcept) return
        const replacementConcepts = addValueToQueryParam(
          this.$route,
          'concept',
          newConcept,
        )
        if (replacementConcepts === undefined) return
        this.$router.push({
          path: this.$route.path,
          query: { ...this.$route.query, concept: replacementConcepts },
        })
        this.$emit('scroll-to-top')
      },
      navigateToConcept (concept: string) {
        if (!concept) return
        let location: Location

        if (this.inAnalysis) {
          location = {
            name: 'analysis-dashboard-concept-view',
            params: { analysisId: `${this.analysisId}`, projectId: `${this.projectId}`, concept: concept },
            query: { filters: this.$route.query.filters, concept: concept }
          }
        } else {
          location = {
            name: 'dashboard-concept-view',
            params:{ dashboardId: `${this.dashboardId}`, concept: concept },
            query: { concept: concept, filters: this.$route.query.filters } }
        }

        if (!location) return
        this.$router.push(location)
        this.$emit('scroll-to-top')
      },
      navigateToSegment (fieldName: string, segment: string) {
        let location: Location

        if (this.inAnalysis) {
          location = {
            name: 'analysis-dashboard-segment-view',
            params: {
              analysisId: `${this.analysisId}`,
              projectId: `${this.projectId}`,
              fieldName: fieldName,
              segment: segment,
            },
            query: {
              filters: this.$route.query.filters,
            }
          }
        } else {
          location = {
            name: 'dashboard-segment-view',
            params: {
              dashboardId: `${this.dashboardId}`,
              fieldName: fieldName,
              segment: segment,
            },
            query: {
              filters: this.$route.query.filters
            }
          }
        }

        if (!location) return
        this.$router.push(location)
        this.$emit('scroll-to-top')
      },
      navigateToTheme (id: number) {
        let location: Location
        const query = this.expandedDashboardQueries.find((g) => g.id === id)
        if (this.inAnalysis) {
          location = {
            name: 'analysis-dashboard-query-view',
            params: {
              analysisId: this.analysisId,
              projectId: this.projectId,
              queryId: query.id
            },
            query: {
              filters: this.$route.query.filters
              }
            }
        } else {
          location = {
            name: 'dashboard-query-view',
            params:{
             dashboardId: this.dashboardId,
             queryId: query.id
            },
            query: {
              filters: this.$route.query.filters
            }
          }
        }

        if (!location) return
        this.$router.push(location)
        this.$emit('scroll-to-top')
      },
      navigateToThemeGroup (id: number) {
        let location: Location
        const themeGroup = this.expandedThemeGroups.find((g) => g.id === id)
        if (this.inAnalysis) {
          location = {
            name: 'analysis-dashboard-theme-group-view',
            params: {
              analysisId: this.analysisId,
              projectId: this.projectId,
              queryId: themeGroup.id
            },
            query: {
              filters: this.$route.query.filters
            }
          }
        } else {
          location = {
            name: 'dashboard-theme-group-view',
            params:{
             dashboardId: this.dashboardId,
             queryId: themeGroup.id
            },
            query: {
              filters: this.$route.query.filters
            }
          }
        }

        if (!location) return
        this.$router.push(location)
        this.$emit('scroll-to-top')
      },
      calculateColumns (width: number) {
        return 1 + Math.floor(width / 1000)
      },
      resize (height: number, width: number) {
        this.widgetColumns = this.calculateColumns(width)
      },
      configChanged (name: WidgetName, config: AnyWidgetConfig): void {
        const widgets = cloneDeep(this.dashboardWidgetConfig) as DashboardConfig['widgets']
        const view = this.dashboardType === 'overview'
          ? 'overview'
          : 'drilldown'

        let foundOne = false
        const viewConfig = widgets[view].map((widget) => {
          if (widget.name !== name) return widget
          foundOne = true
          return {
            ...widget,
            ...config,
          }
        })

        if (!foundOne) {
          const defaultConf = defaultConfig().widgets[view].find((w) => w.name === name)
          if (!defaultConf) throw new Error(`Widget ${name} not found in config!`)
          viewConfig.push({
            ...defaultConf,
            ...config,
          })
        }

        this.$store.commit(SET_WIDGET_CONFIG,  {
          widgets: {
            ...widgets,
            [view]: viewConfig
          }
        })
      },
      getWidgetConfig (name: WidgetName): AnyWidgetConfig | undefined {
        const config: DashboardConfig['widgets'] = this.dashboardWidgetConfig
        const view = this.dashboardType === 'overview'
          ? 'overview'
          : 'drilldown'

        const widget = [...config[view]].find((w) => w.name === name)

        if (!widget) {
          // Return empty config if widget is not found
          return {
            options: {},
          } as AnyWidgetConfig
        }

        return widget
      },
      goToPivot (col: string, row: string) {
        this.configChanged('pivot-table', {
          options: {
            'colFields': [col],
            'rowFields': [row],
          },
        })

        this.$router.push(this.zoomToRoutes['pivot-table'])
      },
      async exportPpt () {
        const pptx = new PptxGenJS()

        for (const widget of this.$refs.widgets) {
          await widget.makePptSlide?.(pptx)
        }

        pptx.writeFile({
          fileName: `${this.currentDashboard.name}.pptx`
        })

        this.$analytics.track.analysis.downloadExport(
          'Export Dashboard', 'PPT', {
            dashboardName: this.currentDashboard.name,
          }
        )
      },
      showGroupedRecords (groupByField: string, groupByValue: string, sourceVerbatimId: string): void {
        const newQuery = {
          includes: [
            {type: "segment", operator: "=", field: groupByField, value: groupByValue}
          ],
          type: 'match_all',
          level: 'verbatim'
        }

        Query.runQuery(
          this.$route.params.projectId,
          this.$route.params.analysisId,
          newQuery,
          this.savedQueries,
          {
            limit: this.maxGroupHits,
            documents: false,
            networkModel: false
          }
        )
        .then((data) => {
          this.showGroupValue = groupByValue
          this.groupHits = data.hits
          this.groupSourceVerbatimId = sourceVerbatimId
          this.numGroupHitsNotShown = Math.max(0, data.total_hits - data.hits.length)
          this.groupHits.sort((x, y) => new Date(x[this.defaultDateField]) - new Date(y[this.defaultDateField]))
          this.viewGroupModalVisible = true
          this.$nextTick(() => {
            this.$el.querySelector('.utterance-highlight')?.scrollIntoView()
          })
        })
      },
      getGroupLabel (query: SavedQuery) {
        if (this.isView.query) {
          return this.themeToGroupNameMap?.[query.id]
        }
        if (this.isView.themeGroup) {
          return this.groupToGroupNameMap?.[query.id]
        }
      },
    }
  })
  export default Overview
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'
  @import 'assets/masked.sass'

  .empty-state
    margin-top: 10%
    text-align: center
    flex: 1
    h2
      color: $text-grey
    p
      color: $text-grey
      font-size: 16px

  .column-wrapper
    width: 100vw
    display: flex
    flex-direction: row
    flex: 1
    .column
      width: 600px // a width is needed so the columns stay proportional to each other as flexbox grows/shrinks them
      display: flex
      flex-direction: column
      align-items: center
      flex: 1 1 0
      padding: 0 15px
      &:first-of-type
        padding-left: 30px
      &:last-of-type
        padding-right: 30px


  .header-icon
    max-height: 32px

  // 1. this class is used to put a dropshadow on each unzoomed dashboard widget
  // 2. putting the shadow in widget-frame is preferable but there is a chrome bug
  //    which makes some of the widgets 'grey-out' in some situations
  // 3. in zoom mode, widget-frame handles a shadow on specific elements and we
  //    don't apply this class as it doesn't look right in zoom mode.
  .dashboard-component
    box-shadow: 0 1px 5px 0 rgba(0, 1, 1, 0.1)

  div.header-controls
    display: flex
    justify-content: space-between
    align-items: center
    background: $grey-background
    margin: 0 30px
    .export-button
      z-index: 1
      display: flex
      align-items: center
      color: $subdued
      cursor: pointer
      i
        opacity: 0.7
      div.loader
        margin-right: 0.25rem
      &:hover:not(.loading)
        color: $blue
      &.loading
        cursor: progress

  header
    padding: 15px 30px 10px
    margin-bottom: 5px
    display: flex
    flex-wrap: wrap
    position: sticky
    top: 0
    z-index: 4
    background: $grey-background
    .break
      flex-basis: 100%
      height: 0
    .group-label
      color: #aaa
      font-size: 32px

    h2
      font-size: rem(32px)
      font-family: Lato, sans-serif
      font-weight: bold
      display: flex
      align-items: center
      justify-content: center
      margin: -7px auto 14px
      .header-group
        display: flex
        align-items: center
        &:not(:last-of-type)::after
          content: 'AND'
          margin: auto 1rem
          color: $blue

    .header-metadata
      display: flex
      width: 100%
      flex-wrap: wrap

      strong
        white-space: nowrap

      > div
        flex: 1
        &:nth-child(1)
          margin-right: 40px

      span
        height: 24px
        align-items: center
        display: flex

        .bf-spinner-container
          margin-left: -4px

  .zoom-wrapper
    display: flex
    flex-direction: column
    flex: 1
    padding: 0 30px 0
    > div
      flex: 1

    .column
      width: unset
      &:first-of-type
        padding-left: 0
      &:last-of-type
        padding-right: 0

  .interpunct
    margin: auto 6px

  div.dashboard-grid
    display: flex
    flex-direction: column
    flex: 1
    margin: 1px 0 0 0
    padding-bottom: 30px
    overflow: auto
    .dashboard-component
      box-shadow: 0 1px 5px 0 rgba(0, 1, 1, 0.1)
      width: 100%

      &:not(:last-child)
        margin-bottom: 20px

    .zoom-wrapper
      .dashboard-component
        height: 100%

  a.back-button
    display: inline-block
    opacity: 0.9
    font-size: 12px
    font-weight: bold
    letter-spacing: 0.6px
    color: #068ccc
    line-height: 1.33
    padding-bottom: 15px
    text-transform: uppercase

  div.loader
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    font-size: 16px
    height: 80vh

  div.mason
    column-count: 2
    column-gap: 30px
    margin: 0 30px 0 30px
    div
      background-color: #eee
      margin: 0 0 1.5em
      width: 100%
      // https://developer.mozilla.org/en-US/docs/Web/CSS/break-inside
      // https://caniuse.com/#feat=multicolumn
      break-inside: avoid-column
      // For older firefox support
      page-break-inside: avoid
      // For webkit support
      -webkit-column-break-inside: avoid
      // Without this, firefox does weird things, see ch8080
      @supports (page-break-inside: avoid)
        display: inline-block
    @media only screen and (min-width: 1920px)
      column-count: 3
    @media only screen and (max-width: 1920px) and (min-width: 1200px)
      column-count: 2
    @media only screen and (max-width: 1200px)
      column-count: 1

  i.kapiche-icon-download
    color: $grey-dark

  .dashboard-loading
    width: 100%
    display: flex
    justify-content: center
    align-items: center

  .conversation-viewer
    min-height: 400px
    max-height: 75vh
    padding: 20px
    > div
      padding-bottom: 10px
    .group-by-field
      font-size: 16px
      margin: 20px 0
      .value
        font-weight: bold
    .speaker-field-dropdown
      cursor: pointer
      font-size: 16px
    .speaker-field
      color: $blue
      font-weight: bold
    .group-hits-limit
      color: $blue
      margin-top: 15px
      font-weight: bold
    .utterance
      margin: 20px 0
      padding: 5px 5px 5px 25px
      position: relative
      &.utterance-highlight
        background-color: $grey-light
      .sentiment-indicator
        height: calc(100% - 10px)
        width: 10px
        left: 0
        margin: 5px 0 0 0
        position: absolute
      .speaker-label
        font-size: 15px
        font-style: italic
      .verbatim-text
        font-size: 17px
        margin: 5px 0px
      .utterance-date
        font-size: 13px
        color: $grey-dark
</style>
