import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import Dashboard from 'pages/dashboard/Dashboard.vue'
import Overview from 'pages/dashboard/Overview.vue'

const dashboardRedirects: RouteRecordRaw[] = [{
  path: '/:site/v2/dashboards/:dashboardId/:path*',
  redirect: (to) => ({ path: to.path.replace('/v2/', '/') }),
}, {
  path: '/:site/projects/:projectId/analysis/:analysisId/v2/dashboards/:dashboardId/:path*',
  redirect: (to) => to.path.replace('/v2/', '/'),
}, {
  path: '/:site/projects/:projectId/analysis/:analysisId/dashboards/:dashboardId/:path*',
  redirect: (to) => to.path.replace('/dashboards/', '/workbench/'),
}, {
  path: '/:site/dashboards/:dashboardId/:path*',
  redirect: (to) => to.path.replace('/dashboards/', '/workbench/'),
}]

const get_concepts = (route: RouteLocationNormalized) => {
  return typeof route.query.concept === 'string'? [route.query.concept] : route.query.concept
}

const viewerChildren = [
  // overview
  {
    path: 'overview',
    component: Overview,
    name: 'dashboard-overview',
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      showProgressBar: false,
    },
    props: (route: RouteLocationNormalized) => ({
      dashboardId: route.params.dashboardId,
      inAnalysis: false,
      dashboardType: 'overview'
    }),
  },
  // Redirect old zoom routes
  { path: 'segment-chart-overview', redirect: 'segments' },
  { path: 'segment-chart-query/:queryId', redirect: 'query/:queryId/segments' },
  { path: 'themes-overview', redirect: 'themes-concepts' },
  { path: 'themes', redirect: 'themes-concepts' },


  // concept views
  {
    path: 'concept',
    name: 'dashboard-concept-view',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      concepts: get_concepts(route),
      dashboardType: 'concept',
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId
        },
        query: {
          filters: route?.query?.filters
        }
      }
    })
  },
  // widget zoom from concept view
  {
    path: 'concept/:widget',
    name: 'viewer-concept-datawidget-zoom',
    component: Overview,
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      widget: route.params.widget,
      concepts: get_concepts(route),
      dashboardType: 'concept',
      backToRoute: {
        name: 'dashboard-concept-view',
        params: {
          dashboardId: route.params.dashboardId
        },
        query: {
          concept: get_concepts(route),
          filters: route?.query?.filters
        }
      }
    }),
  },
  // widget zoom from overview
  {
    path: ':widget',
    name: 'viewer-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'overview',
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      widget: route.params.widget,
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId,
        },
        query: {
          filters: route?.query?.filters,
        },
      }
    }),
  },
  // query view from overview
  {
    path: 'query/:queryId',
    component: Overview,
    name: 'dashboard-query-view',
    meta: { requiresAuth: true, title: 'Query View' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'query',
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      queryId: parseInt(route.params.queryId as string),
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId
        },
        query: {
          filters: route?.query?.filters
        }
      }
    }),
  },
  {
    path: 'theme-group/:queryId',
    component: Overview,
    name: 'dashboard-theme-group-view',
    meta: { requiresAuth: true, title: 'Theme Group View' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'theme-group',
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      queryId: parseInt(route.params.queryId  as string),
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId
        },
        query: {
          filters: route?.query?.filters
        }
      }
    }),
  },
  {
    path: 'segment/:fieldName/:segment',
    component: Overview,
    name: 'dashboard-segment-view',
    meta: { requiresAuth: true, title: 'Segment View' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'segment',
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      segment: {
        fieldName: route.params.fieldName,
        segment: route.params.segment,
      },
      backToRoute: {
        name: 'dashboard-overview',
        params: {
          dashboardId: route.params.dashboardId
        },
        query: {
          filters: route?.query?.filters
        }
      }
    }),
  },
  // widget zoom from query
  {
    path: 'query/:queryId/:widget',
    name: 'viewer-query-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'query',
      inAnalysis: false,
      dashboardId: route.params.dashboardId,
      queryId: parseInt(route.params.queryId as string),
      widget: route.params.widget,
      backToRoute: {
        name: 'dashboard-query-view',
        params: { dashboardId: route.params.dashboardId, queryId: route.params.queryId },
        query: {
          filters: route?.query?.filters
        }
      }
    }),

  },
]

// viewer tailored dashboards
const viewerDashboards = {
  path: '/:site/workbench/:dashboardId',
  component: Dashboard,
  name: 'viewer-dashboard',
  meta: {
    requiresAuth: true,
    loginRoute: { name: 'register-viewer', params: { site: 'site', dashboardId: 'dashboardId'} }
  },
  redirect: { name: 'dashboard-overview' },
  props: (route: RouteLocationNormalized) => ({
    dashboardId: route.params.dashboardId,
    inAnalysis: false,
  }),
  children: viewerChildren,
}

const analystChildren = [
  // overview
  {
    path: 'overview',
    name: 'analysis-dashboard-overview',
    component: Overview,
    meta: {
      requiresAuth: true,
      showProgressBar: false,
      title: 'Dashboard',
    },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'overview',
      inAnalysis: true,
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      dashboardId: parseInt(route.params.dashboardId  as string),
    }),
  },
  // Redirect old zoom routes
  { path: 'segment-chart-overview', redirect: 'segments' },
  { path: 'segment-chart-query/:queryId', redirect: 'query/:queryId/segments' },
  { path: 'themes-overview', redirect: 'themes-concepts' },
  { path: 'themes', redirect: 'themes-concepts' },

  // concept views
  {
    path: 'concept',
    name: 'analysis-dashboard-concept-view',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard' },
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      dashboardId: route.params.dashboardId,
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      concepts: get_concepts(route),
      dashboardType: 'concept',
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId  as string),
          analysisId: parseInt(route.params.analysisId  as string),
          dashboardId: parseInt(route.params.dashboardId  as string)
        },
        query: {
          filters: route?.query?.filters
        }
      }
    })
  },
  // widget zoom from concept view
  {
    path: 'concept/:widget',
    name: 'analysis-concept-datawidget-zoom',
    component: Overview,
    props: (route: RouteLocationNormalized) => ({
      inAnalysis: true,
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      dashboardId: parseInt(route.params.dashboardId  as string),
      widget: route.params.widget,
      concepts: get_concepts(route),
      dashboardType: 'concept',
      backToRoute: {
        name: 'analysis-dashboard-concept-view',
        params: {
          projectId: parseInt(route.params.projectId  as string),
          analysisId: parseInt(route.params.analysisId  as string),
          dashboardId: parseInt(route.params.dashboardId  as string)
        },
        query: {
          concept: get_concepts(route),
          filters: route?.query?.filters
        }
      }
    }),
  },
  // widget zoom from overview
  {
    path: ':widget',
    name: 'analysis-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'overview',
      inAnalysis: true,
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      dashboardId: parseInt(route.params.dashboardId  as string),
      widget: route.params.widget,
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId  as string),
          analysisId: parseInt(route.params.analysisId  as string),
          dashboardId: parseInt(route.params.dashboardId  as string)
        },
        query: {
          filters: route?.query?.filters
        }
      }
    }),
  },
  // query view from overview
  {
    path: 'query/:queryId',
    component: Overview,
    name: 'analysis-dashboard-query-view',
    meta: { requiresAuth: true, title: 'Query View' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'query',
      inAnalysis: true,
      dashboardId: parseInt(route.params.dashboardId  as string),
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      queryId: parseInt(route.params.queryId  as string),
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId  as string),
          analysisId: parseInt(route.params.analysisId  as string),
          dashboardId: parseInt(route.params.dashboardId  as string)
        },
        query: {
          filters: route?.query?.filters
        }
      }
    }),
  },
  {
    path: 'theme-group/:queryId',
    component: Overview,
    name: 'analysis-dashboard-theme-group-view',
    meta: { requiresAuth: true, title: 'Theme Group View' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'theme-group',
      inAnalysis: true,
      dashboardId: parseInt(route.params.dashboardId  as string),
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      queryId: parseInt(route.params.queryId  as string),
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId  as string),
          analysisId: parseInt(route.params.analysisId  as string),
          dashboardId: parseInt(route.params.dashboardId  as string)
        },
        query: {
          filters: route?.query?.filters
        }
      }
    }),
  },
  {
    path: 'segment/:fieldName/:segment',
    component: Overview,
    name: 'analysis-dashboard-segment-view',
    meta: { requiresAuth: true, title: 'Segment View' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'segment',
      inAnalysis: true,
      dashboardId: parseInt(route.params.dashboardId  as string),
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      segment: {
        fieldName: route.params.fieldName,
        segment: route.params.segment,
      },
      concepts: get_concepts(route),
      backToRoute: {
        name: 'analysis-dashboard-overview',
        params: {
          projectId: parseInt(route.params.projectId  as string),
          analysisId: parseInt(route.params.analysisId  as string),
          dashboardId: parseInt(route.params.dashboardId  as string)
        },
        query: {
          filters: route?.query?.filters
        }
      }
    }),
  },
  // widget zoom from query
  {
    path: 'query/:queryId/:widget',
    name: 'analysis-query-datawidget-zoom',
    component: Overview,
    meta: { requiresAuth: true, title: 'Dashboard' },
    props: (route: RouteLocationNormalized) => ({
      dashboardType: 'query',
      inAnalysis: true,
      dashboardId: route.params.dashboardId,
      projectId: parseInt(route.params.projectId  as string),
      analysisId: parseInt(route.params.analysisId  as string),
      queryId: parseInt(route.params.queryId as string),
      concepts: get_concepts(route),
      widget: route.params.widget,
      backToRoute: {
        name: 'analysis-dashboard-query-view',
        params: {
          projectId: parseInt(route.params.projectId  as string),
          analysisId: parseInt(route.params.analysisId  as string),
          dashboardId: parseInt(route.params.dashboardId  as string),
          queryId: parseInt(route.params.queryId as string),
        },
        query: {
          concept: get_concepts(route),
          filters: route?.query?.filters
        }
      }
    }),
  },
]

// analyst tailored dashboards
const analystDashboards = {
  path: '/:site/projects/:projectId/analysis/:analysisId/workbench/:dashboardId',
  meta: { requiresAuth: true },
  name: 'analysis-dashboard',
  component: Dashboard,
  redirect: { name: 'analysis-dashboard-overview'},
  props: (route: RouteLocationNormalized) => ({
    inAnalysis: true,
    projectId: parseInt(route.params.projectId  as string),
    analysisId: parseInt(route.params.analysisId  as string),
    dashboardId: parseInt(route.params.dashboardId  as string),
    queryId: parseInt(route.params.queryId as string),
  }),
  children: analystChildren,
}


export const dashboardRoutes: RouteRecordRaw[] = [
  ...dashboardRedirects,
  viewerDashboards,
  analystDashboards,
]
