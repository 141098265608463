<template>
  <div class="wrapper">
    <div class="theme-builder">
      <div class="sidebar">
        <progress-bar
          :progress="progressCoverage"
          :goal="80"
          :ignored="percent(queryStats.ignored_verbatim_count, queryStats.total_verbatim_count)"
        />
        <div class="content">
          <el-tabs
            :model-value="activeTab"
            @update:model-value="activeTab = $event"
          >
            <el-tab-pane label="Concepts" name="concepts">
              <label>
                <toggle-slider-input
                  small
                  :value="hideMappedSidebar"
                  @input="hideMappedSidebar = $event"
                />
                Hide concepts already in a Theme
              </label>
              <concept-list
                class="tab-content"
                :concepts="keyConcepts"
                :is-loading="!currentModel || isLoading.keyConcepts"
                header-left="Key Concepts"
                header-right="#/% of all verbatims"
                @concept-clicked="sidebarConceptClicked"
              />
              <template v-if="ignoredConcepts.length > 0">
                <hr />
                <concept-list
                  class="tab-content ignored-concepts"
                  :concepts="ignoredConcepts"
                  :is-loading="!currentModel || isLoading.ignoredConceptsStats"
                  header-left="Ignored Concepts"
                  header-right=""
                  @concept-clicked="sidebarConceptClicked"
                />
              </template>
            </el-tab-pane>
            <el-tab-pane label="Themes" name="themes">
              <template v-if="isLoading.state">
                <div class="loading-wrapper">
                  <bf-spinner />
                </div>
              </template>
              <template v-else>
                <div class="buttons">
                  <bf-button
                    size="mini"
                    color="blue"
                    @click="addTheme"
                  >
                    CREATE
                  </bf-button>
                  <bf-button
                    size="mini"
                    color="green"
                    @click="saveAllThemes"
                  >
                    SAVE ALL
                  </bf-button>
                  <bf-button
                    size="mini"
                    color="transparent"
                    class="discard-button"
                    @click="resetButtonClick"
                  >
                    <icon name="revert" :size="14" color="#95a6ac" />
                    DISCARD ALL CHANGES
                  </bf-button>
                </div>
                <themes-tree-lazy
                  ref="tree"
                  class="tab-content"
                  :themes="stagedQueries || []"
                  :is-ready="true"
                  :selected-theme-i-d="selectedQueryID"
                  :group-tree="groupTree"
                  :top-level-data="topLevelData"
                  :has-changes="hasChanges"
                  :analysis-id="analysisId"
                  :project-id="projectId"
                  :save-theme="saveOrUpdateTheme"
                  :delete-theme="deleteTheme"
                  :load-level="loadItemCoverage"
                  :expanded-node-keys="expandedNodeKeys"
                  @theme-clicked="selectTheme"
                  @update-tree="updateTree"
                  @node-expand="nodeExpanded"
                  @node-collapse="nodeCollapsed"
                />
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="content">
        <div
          ref="scrollableContent"
          class="content-wrapper"
        >
          <div class="sticky">
            <div v-if="selectedQuery" class="breadcrumbs">
              <button @click="selectTheme(null)">
                Home
              </button>
              <span>&#62;</span>
              {{ selectedQuery.name }}
            </div>
            <div class="top-bar">
              <template v-if="selectedQuery !== null">
                <dropdown>
                  <template #trigger>
                    <span class="theme-name">
                      {{ selectedQuery.name }}
                      <i class="kapiche-icon-chevron-down"></i>
                    </span>
                  </template>
                  <template v-if="!isNewTheme">
                    <dropdown-item @click="showDashboardModal = true">
                      Add/Remove to Dashboards
                    </dropdown-item>
                    <dropdown-item @click="showThemeNameModal = 'rename'">
                      Rename
                    </dropdown-item>
                  </template>
                  <dropdown-item v-if="!isAutoTheme" class="red" @click="showThemeDeleteModal = true">
                    Delete
                  </dropdown-item>
                </dropdown>
                <template v-if="!isAutoTheme">
                  <bf-button
                    v-if="stagedQueryIsValid && hasChanges[selectedQuery.id] !== false"
                    size="mini"
                    color="green"
                    @click="isNewTheme ? showThemeNameModal = 'new_theme' : saveCurrentTheme()"
                  >
                    {{ isNewTheme ? 'SAVE AS NEW THEME...' : 'SAVE' }}
                  </bf-button>
                  <bf-button
                    v-if="!isNewTheme"
                    size="mini"
                    color="blue"
                    @click="duplicateTheme()"
                  >
                    SAVE AS...
                  </bf-button>
                  <bf-button
                    v-if="!isNewTheme && hasChanges[selectedQuery.id] !== false"
                    class="discard-button"
                    size="mini"
                    color="transparent"
                    @click="resetCurrentTheme"
                  >
                    <icon name="revert" :size="14" color="#95a6ac" /> DISCARD CHANGES
                  </bf-button>
                </template>
                <bf-button
                  v-if="featureFlags.dev_mode"
                  size="mini"
                  color="orange"
                  class="staff-only"
                  @click="() => showJSON = !showJSON"
                >
                  Show JSON
                </bf-button>
              </template>
              <template v-else>
                <span class="theme-name">
                  <strong>
                    Theme Builder Home
                  </strong>
                </span>
              </template>
            </div>
            <div v-if="showJSON" class="query-json">
              <textarea :value="JSON.stringify(selectedBotanicQuery, null, 2)" />
            </div>
            <filter-bar
              v-if="selectedQuery"
              :query-id="selectedQueryID"
              :query-name="selectedQuery.name"
              :query-scope="selectedQueryScope"
              :query-rows="selectedQueryRows"
              :concept-list="visibleConceptStrings"
              :allow-structured="currentProject.allow_structured_themes"
              :saved-queries="savedQueries.value"
              :current-site="currentSite"
              :current-project="currentProject"
              :current-analysis="currentAnalysis"
              :allow-dates="false"
              :location="location"
              @set-query-rows="queryUpdatedMethod"
            />
            <template v-if="selectedQuery !== null && stagedQueryIsValid && !isLoading.stats">
              <div class="query-stats">
                {{ comma(queryStats.record_count) }} of
                {{ comma(queryStats.total_record_count) }} records
                <span class="percent">
                  ({{ percent(queryStats.record_count, queryStats.total_record_count) }}%)
                </span>
                <span>&bull;</span>
                {{ comma(queryStats.verbatim_count) }} of
                {{ comma(queryStats.total_verbatim_count) }} verbatims
                <span class="percent">
                  ({{ percent(queryStats.verbatim_count, queryStats.total_verbatim_count) }}%)
                </span>
              </div>
              <div v-if="!hasNoResults" class="query-controls">
                <!-- Disabled for now due to back end hurdles -->
                <!-- <label>
                  <toggle-slider-input
                    small
                    :value="!!selectedQuery.exclude_mapped"
                    @input="setExcludeMapped"
                  />
                  Exclude mapped verbatims
                </label> -->
                <template v-if="currentProject.sentiment_classifier.includes('plumeria_')">
                  <!-- <span>&bull;</span> -->
                  <label>
                    Match on
                    <dropdown @change="changeQueryScope">
                      <template #trigger>
                        <span class="scope-label">
                          {{
                            {
                              'frame': 'verbatim',
                              'sentence': 'sentence',
                            }[selectedQuery.query_value.level || 'frame']
                          }}
                          <i class="kapiche-icon-chevron-down"></i>
                        </span>
                      </template>
                      <dropdown-item value="frame">
                        verbatim
                      </dropdown-item>
                      <dropdown-item value="sentence">
                        sentence
                      </dropdown-item>
                    </dropdown>
                  </label>
                </template>
              </div>
            </template>
          </div>
          <div>
            <div
              v-if="selectedBotanicQuery && selectedQuery !== null && stagedQueryIsValid"
              class="widget-container"
            >
              <div v-if="isLoading.stats" class="loading-theme">
                <bf-spinner />
              </div>
              <div v-else-if="hasNoResults" class="no-results">
                No records match this theme.
              </div>
              <template v-else>
                <div class="column">
                  <context-network
                    v-bind="fetchedData['context-network'] || {}"
                    :masked="false"
                    :dev-mode="false"
                    :height="640"
                    :export-name="currentAnalysis.name"
                    :query="selectedBotanicQuery"
                    @requires="fetchContextNetwork"
                  >
                    <template #interaction-menu="interactionMenuProps">
                      <button @click="addConceptToQuery(interactionMenuProps.label)">
                        Add <b>{{ interactionMenuProps.label }}</b> to theme
                      </button>
                      <button @click="replaceQueryWithConcept(interactionMenuProps.label)">
                        Replace query with <b>{{ interactionMenuProps.label }}</b>
                      </button>
                      <button v-if="showAddConceptAsOR" @click="addConceptAsOR(interactionMenuProps.label)">
                        Add <b>{{ interactionMenuProps.label }}</b> as an OR
                      </button>
                    </template>
                  </context-network>
                  <key-phrases
                    v-bind="fetchedData['key-phrases'] || {}"
                    :masked="false"
                    :dev-mode="false"
                    :export-name="currentAnalysis.name"
                    show-drilldown
                    :queries="[{
                      id: selectedQuery.id,
                      name: selectedQuery.name,
                      query_value: selectedExpandedBotanicQuery,
                    }]"
                    :group="'overall__'"
                    @requires="fetchKeyPhrases"
                  >
                    <template #interaction-menu="interactionMenuProps">
                      <button @click="addConceptToQuery(interactionMenuProps.label)">
                        Add <b>{{ interactionMenuProps.label }}</b> to theme
                      </button>
                      <button @click="replaceQueryWithConcept(interactionMenuProps.label)">
                        Replace query with <b>{{ interactionMenuProps.label }}</b>
                      </button>
                      <button v-if="showAddConceptAsOR" @click="addConceptAsOR(interactionMenuProps.label)">
                        Add <b>{{ interactionMenuProps.label }}</b> as an OR
                      </button>
                    </template>
                  </key-phrases>
                </div>
                <div class="column">
                  <ai-summary
                    :masked="false"
                    :dev-mode="false"
                    :project-id="projectId"
                    :current-site="currentSite"
                    :current-project="currentProject"
                    :current-analysis="currentAnalysis"
                    :query="selectedBotanicQuery"
                    :merged-filters="[]"
                    :saved-queries="stagedQueries"
                    :theme-name="selectedQuery?.name"
                    :is-staff="currentUser.is_staff"
                    widget-title="Theme Summary"
                  />
                  <verbatims-widget
                    v-bind="fetchedData['verbatims'] || {}"
                    :masked="false"
                    :dev-mode="false"
                    :query="selectedBotanicQuery"
                    :has-nps="hasNPS"
                    :has-sentiment="hasSentiment"
                    :has-date="hasDate"
                    :has-files="hasFiles"
                    :nps-field-name="npsFieldName"
                    :model-topics="currentModel.topics"
                    :model-terms="currentModel.terms"
                    :model-colors="currentModel.conceptColours"
                    :show-annotations="false"
                    :sentiment-classifier="currentProject.sentiment_classifier"
                    :config="verbatimsConfig"
                    :saved-queries="stagedQueries"
                    @requires="fetchVerbatims"
                    @config-changed="verbatimsConfigChanged"
                  />
                </div>
              </template>
            </div>
            <template v-else>
              <storyboard-page
                class="storyboard"
                :show-concept-list="false"
                :on-concept-clicked="storyboardConceptClicked"
                :default-dim-added-concepts="true"
                :query-list="stagedQueries"
                :dimmed-concepts="ignoredConceptNames"
              />
            </template>
          </div>
        </div>
      </div>
    </div>

    <theme-name-modal
      :visible="!!showThemeNameModal"
      :is-new-theme="isNewTheme || showThemeNameModal === 'new_theme'"
      :submit-errors="submitErrors"
      :values="{
        name: selectedQuery && selectedQuery.name,
        description: selectedQuery && selectedQuery.description,
      }"
      :dashboard-list="currentAnalysis.dashboards || []"
      :theme-group-list="themeGroups"
      :can-add-to-dashboard="canAddToDashboard"
      :dashboard-ids="selectedQuery && selectedQuery.dashboard_ids || []"
      @close="showThemeNameModal = false"
      @update-theme="renameFormSubmit"
    />

    <theme-dashboard-modal
      :visible="showDashboardModal"
      :dashboard-list="currentAnalysis.dashboards || []"
      :can-add-to-dashboard="canAddToDashboard"
      :dashboard-ids="selectedQuery && selectedQuery.dashboard_ids || []"
      @close="showDashboardModal = false"
      @update-theme="dashboardFormSubmit"
    />

    <theme-delete-modal
      v-if="selectedQuery"
      :visible="showThemeDeleteModal"
      :selected-query="selectedQuery"
      :saved-queries="savedQueries.value"
      :delete-theme="deleteTheme"
      @close="showThemeDeleteModal = false"
    />

    <!-- Reset confirm modal -->
    <confirm-modal
      ref="resetConfirmModal"
      title="Reset saved Themes"
    >
      This will discard all unsaved changes to saved themes.
      <br />
      Are you sure you want to continue?
    </confirm-modal>

    <clicked-outside
      :enabled="!!clickedStoryboardConcept.name"
      @clicked-outside="clearClickedConcept"
    >
      <floating-panel
        v-if="!!clickedStoryboardConcept.name"
        :x="clickedStoryboardConcept.x"
        :y="clickedStoryboardConcept.y + 8"
        visible
      >
        <div class="interaction-menu">
          <button
            @click="addConceptToQuery(clickedStoryboardConcept.name)"
          >
            Create new theme with "<b>{{ clickedStoryboardConcept.name }}</b>"
          </button>
          <button
            v-if="!clickedStoryboardConcept.dimmed"
            @click="goToUnmappedPage(clickedStoryboardConcept.name)"
          >
            View "<b>{{ clickedStoryboardConcept.name }}</b>" on unmapped page
          </button>
          <template v-if="ignoredConceptNames.includes(clickedStoryboardConcept.name)">
            <button
              @click="unignoreConcept(clickedStoryboardConcept.name)"
            >
              Unignore "<b>{{ clickedStoryboardConcept.name }}</b>"
            </button>
          </template>
          <template v-else>
            <button
              @click="ignoreConcept(clickedStoryboardConcept.name)"
            >
              Ignore "<b>{{ clickedStoryboardConcept.name }}</b>"
            </button>
          </template>
        </div>
      </floating-panel>
    </clicked-outside>

    <clicked-outside
      :enabled="!!clickedConcept.name"
      @clicked-outside="clearClickedConcept"
    >
      <floating-panel
        v-if="!!clickedConcept.name"
        :x="clickedConcept.x"
        :y="clickedConcept.y + 8"
        visible
      >
        <div class="interaction-menu">
          <button
            v-if="selectedQuery"
            @click="addConceptFromSidebar(clickedConcept.name, false)"
          >
            Add "<b>{{ clickedConcept.name }}</b>" to {{ selectedQuery.name }}
          </button>
          <button
            @click="addConceptFromSidebar(clickedConcept.name, true)"
          >
            Create new theme with "<b>{{ clickedConcept.name }}</b>"
          </button>
          <button
            v-if="hideMappedSidebar"
            @click="goToUnmappedPage(clickedConcept.name)"
          >
            View "<b>{{ clickedConcept.name }}</b>" on unmapped page
          </button>
          <template v-if="ignoredConceptNames.includes(clickedConcept.name)">
            <button
              @click="unignoreConcept(clickedConcept.name)"
            >
              Unignore "<b>{{ clickedConcept.name }}</b>"
            </button>
          </template>
          <template v-else>
            <button
              @click="ignoreConcept(clickedConcept.name)"
            >
              Ignore "<b>{{ clickedConcept.name }}</b>"
            </button>
          </template>
        </div>
      </floating-panel>
    </clicked-outside>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, computed, watch, ref, reactive, inject } from 'vue'
import { isEqual, cloneDeep } from 'lodash'

import {
  fetchThemes,
  useFetchData,
  getQueryRows,
  isQueryValid,
  makeNewThemeName,
  getConceptsFromQuery,
  CoverageNode,
  findNode,
  TreeDataNode,
  findParentNode,
} from './ThemeBuilder.utils'
import { SavedQuery, QueryElement, QueryType, QueryLocation } from 'src/types/Query.types'
import { Concept } from 'src/types/AnalysisTypes'
import { VerbatimsConfig } from 'src/types/DashboardTypes'
import { BfButton, BfSpinner } from 'components/Butterfly'
import QueryUtils, { hasUnstructured, expandQuery, countTextRows } from 'src/utils/query'
import Query, { UnmappedBodyType, expandQueryIfPossible, expandSavedQueryIfPossible, ThemeGroup } from 'src/api/query'
import ProjectAPI from 'src/api/project'
import VerbatimsWidget from 'components/DataWidgets/VerbatimsWidget/VerbatimsWidget.vue'
import ContextNetwork from 'components/DataWidgets/ContextNetwork/ContextNetwork.vue'
import KeyPhrases from 'components/DataWidgets/KeyPhrases/KeyPhrases.vue'
import AiSummary from 'components/DataWidgets/AiSummary/AiSummary.vue'
import ThemesTreeLazy from 'components/project/analysis/results/ThemeBuilder/ThemesTreeLazy.vue'
import ConceptList from './ConceptList.vue'
import { fetch_keyphrases_data, fetch_pivot_data } from 'src/store/modules/data/api'
import { SchemaColumn } from 'src/types/SchemaTypes'
import ThemeNameModal from './ThemeNameModal.vue'
import ThemeDashboardModal from './ThemeDashboardModal.vue'
import ThemeDeleteModal from './ThemeDeleteModal.vue'
import { useStore } from 'src/store'
import { CLEAR_REQUEST_ERRORS, SET_SAVED_QUERIES } from 'src/store/types'
import StoryboardPage from 'src/components/project/analysis/results/Storyboard.vue'
import { Analytics } from 'src/analytics'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
import Icon from 'src/components/Icon.vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import ClickedOutside from 'src/components/ClickedOutside.vue'
import { comma, percent } from 'src/utils/formatters'
import ConfirmModal from 'components/ConfirmModal.vue'
import ToggleSliderInput from 'src/components/forms/ToggleSliderInput.vue'
import ProgressBar from './ProgressBar.vue'
import { useRouter } from 'src/router'
import FilterBar from './FilterBar.vue'
import { PivotData } from 'src/types/widgets.types'
import { expandThemeGroup, Group, GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'

interface CoverageStats {
  all_frames: number
  frames_covered: number
  query_stats: CoverageNode[]
}

interface FetchedDataPayload {
  status: 'done' | 'fetching'
  data: any
  error: any
}

interface PayloadNode {
  id: string | number
  name: string
  type: 'theme' | 'group'
  children?: PayloadNode[]
  value?: SavedQuery['query_value']
  omit_from_overall: boolean
}

interface Coverage {
  all_frames: number
  frames_covered: number
}

const ERROR = {
  FETCH_THEMES: 'Failed to fetch themes.',
} as const

const ThemeBuilder = defineComponent({
  components: {
    BfButton,
    BfSpinner,
    VerbatimsWidget,
    ContextNetwork,
    KeyPhrases,
    ConceptList,
    ThemeNameModal,
    ThemeDeleteModal,
    StoryboardPage,
    Dropdown,
    DropdownItem,
    Icon,
    ThemeDashboardModal,
    FloatingPanel,
    ClickedOutside,
    ConfirmModal,
    ToggleSliderInput,
    ProgressBar,
    FilterBar,
    AiSummary,
    ThemesTreeLazy,
  },
  provide: {
    isOnDashboard: false,
  },
  beforeRouteLeave (to, from, next) {
    if (this.isUnsaved) {
      const confirm = window.confirm(
        'You have unsaved changes. Are you sure you want to leave?'
      )
      confirm ? next() : next(false)
    } else {
      next()
    }
  },
  props: {
    analysisId: { type: Number, required: true },
    projectId: { type: Number, required: true },
    currentModel: { type: Object, required: true },
    currentAnalysis: { type: Object, required: true },
    currentSite: { type: Object, required: true },
    currentProject: { type: Object, required: true },
    currentUser: { type: Object, required: true },
    featureFlags: { type: Object, required: true },
    state: { type: String, required: false, default: '' },
    hasNPS: { type: Boolean, required: false, default: false },
    hasSentiment: { type: Boolean, required: false, default: false },
    hasDate: { type: Boolean, required: false, default: false },
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()

    const analytics = inject<Analytics>('analytics')

    const tree = ref<InstanceType<typeof ThemesTreeLazy>>()

    const themeGroups = computed<any[]>(() => store.getters['themeGroups'] ?? [])

    const resetConfirmModal = ref<InstanceType<typeof ConfirmModal>>()

    const error = ref<null | typeof ERROR[keyof typeof ERROR]>(null)
    const fetchedData = reactive({
      'key-phrases': {} as FetchedDataPayload,
      'context-network': {} as FetchedDataPayload,
      'verbatims': {} as FetchedDataPayload,
      'ignored-concepts-stats': {} as FetchedDataPayload,
    })

    const totalCoverage = ref<Coverage>({
      all_frames: 0,
      frames_covered: 0,
    })

    const activeTab = ref<'concepts' | 'themes'>('themes')
    const verbatimsConfig = ref<VerbatimsConfig>(
      {
        name: 'verbatims',
        visible: true,
        options: {
          perPage: 50,
          orderBy: "most_relevant"
        }
      }
    )

    const showThemeNameModal = ref<false | 'rename' | 'new_theme'>(false)
    const showDashboardModal = ref(false)
    const showThemeDeleteModal = ref(false)
    const scrollableContent = ref<HTMLDivElement | null>(null)
    const showJSON = ref(false)
    const submitErrors = ref<string[]>([])
    const isLoading = reactive({
      state: true,
      themes: false,
      coverage: false,
      keyConcepts: false,
      stats: false,
      ignoredConceptsStats: false,
    })
    const queryStats = reactive({
      record_count: 0,
      verbatim_count: 0,
      total_record_count: 0,
      total_verbatim_count: 0,
      ignored_verbatim_count: 0,
    })

    const hasNoResults = computed(() => {
      return stagedQueryIsValid.value && queryStats.record_count === 0
    })

    // Concept clicked from sidebar
    const clickedConcept = ref<{
      name: string | null
      x: number
      y: number
    }>({
      name: null,
      x: 0,
      y: 0,
    })

    // Concept clicked from storyboard
    const clickedStoryboardConcept = ref<{
      name: string | null
      x: number
      y: number
      dimmed: boolean
    }>({
      name: null,
      x: 0,
      y: 0,
      dimmed: false,
    })

    // Concepts ignored from the sidebar
    const ignoredConceptNames = ref<string[]>(props.currentAnalysis.ignored_concepts ?? [])

    const ignoredConcepts = computed(() => {
      return allConcepts.value.filter((c) => {
        if (hideMappedSidebar.value &&
            mappedConcepts.value.includes(c.name)
        ) {
          return false
        }
        return ignoredConceptNames.value.includes(c.name)
      })
    })

    // Save ignored concepts to analysis
    watch(ignoredConceptNames, (newVal, oldVal) => {
      if (isEqual(newVal, oldVal)) return
      updateStats()
      ProjectAPI.updateAnalysisDisplayAttributes(store, {
        ignored_concepts: newVal,
      })
    })

    const { fetch } = useFetchData()

    // Static saved queries fetched from Botanic
    const savedQueries = reactive<{ value: SavedQuery[] }>({ value: [] })

    // Staged Queries Lists:
    //
    // Any changes to existing themes or new themes will be first reflected in the
    // staged lists below.
    // - 'stagedSavedQueries': List of Queries that initially reflect the savedQueries.
    //     Any unsaved changes made to the savedQueries exist in this list.
    // - 'stagedNewQueries': List of Any new unsaved Queries that are added via the ThemeBuilder.
    // - 'stagedQueries': List concatenation of `stagedNewQueries` and `stagedSavedQueries`.
    //      This list also acts as the base list for the sidebar.
    const stagedSavedQueries = reactive<{ value: SavedQuery[] }>({ value: [] })
    const stagedNewQueries = reactive<{ value: SavedQuery[] }>({ value: [] })
    const stagedQueries = computed(() =>
      stagedSavedQueries.value
        .concat(stagedNewQueries.value)
        .sort((a, b) => a.name > b.name ? 1 : -1)
    )

    // Current query
    const selectedQueryID = ref<number | null>(null)
    const selectedQuery = computed<SavedQuery | null>({
      get () {
        return stagedQueries.value.find((q) => q.id === selectedQueryID.value) ?? null
      },
      set (value: SavedQuery | null) {
        if (!value) return

        const replaceId = (arr: SavedQuery[]) => {
          return arr.map((q) => {
            if (q.id === value.id) {
              return value
            }
            return q
          })
        }

        if (value.is_new) {
          stagedNewQueries.value = replaceId(stagedNewQueries.value)
        } else {
          stagedSavedQueries.value = replaceId(stagedSavedQueries.value)
        }
      }
    })

    const selectedQueryScope = computed(() =>
      selectedQuery.value?.query_value.level ?? 'frame'
    )

    watch(selectedQueryScope, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        updateStats()
      }
    })

    const hideMappedSidebar = ref(false)

    // All concepts that exist within a theme
    const mappedConcepts = computed(() => {
      return stagedQueries.value.reduce((acc, q) => {
        const concepts = getConceptsFromQuery(q)
        return acc.concat(concepts)
      }, [] as string[])
    })

    // Key concepts for the sidebar, optionally filtering out mapped concepts
    const keyConcepts = computed(() => {
      return allConcepts.value.filter((c) => {
        if (hideMappedSidebar.value &&
            mappedConcepts.value.includes(c.name)
        ) {
          return false
        }
        return !ignoredConceptNames.value.includes(c.name)
      })
    })

    // Current query in QueryRow format
    const selectedQueryRows = ref<QueryElement[]>([])

    // Update coverage if selectedQueryRows changes
    watch(selectedQueryRows, () => {
      if (selectedQueryID.value != null) {
        updateStats()
      }
    })

    // Populate selectedQueryRows when theme is selected
    watch(selectedQueryID, (newVal) => {
      if (newVal == null || !selectedQuery.value?.query_value) {
        selectedQueryRows.value = []
        return
      }
      const rows = getQueryRows(selectedQuery.value.query_value)
      selectedQueryRows.value = rows
    })

    // Current query in Botanic format
    const selectedBotanicQuery = computed(() => {
      return QueryUtils.queryRowsToBotanic(
        selectedQueryRows.value,
        selectedQueryScope.value,
      )
    })

    const selectedExpandedBotanicQuery = computed(() => {
      return expandQueryIfPossible(selectedBotanicQuery.value, savedQueries.value)
    })

    // Track changes to stagedQueries
    const hasChanges = computed(() => {
      const hasChanged = (q1: SavedQuery, q2: SavedQuery) => {
        return (
          !isEqual(q1.query_value, q2.query_value) ||
          !isEqual(q1.name, q2.name) ||
          !isEqual(q1.description, q2.description) ||
          !isEqual(q1.dashboard_ids, q2.dashboard_ids)
        )
      }
      return stagedQueries.value.reduce((map, query) => {
        const savedQuery = savedQueries.value.find((q) => q.id === query.id)
        map[query.id] = savedQuery != null
          ? hasChanged(query, savedQuery)
          : true
        return map
      }, {} as Record<number, boolean>)
    })

    const isUnsaved = computed(() => {
      return Object.values(hasChanges.value).some((v) => v)
    })

    const stagedQueryIsValid = computed(() => {
      return isQueryValid(selectedQueryRows.value)
    })

    // Theme name modal

    const renameFormSubmit = async (query: SavedQuery, newTheme = false) => {
      if (!selectedQuery.value) return

      if (newTheme && !isNewTheme.value) {
        addTheme(selectedQuery.value)
        analytics?.track.themeBuilder.saveAsFromTheme(selectedQuery.value.id, query.name)
      }

      if (newTheme) {
        selectedQuery.value = {
          ...selectedQuery.value,
          ...query,
        }
      }

      let closeModal = true

      await saveCurrentTheme(newTheme ? undefined : query)
        .catch((res) => {
          store.dispatch(CLEAR_REQUEST_ERRORS)
          submitErrors.value = res.body?.non_field_errors ?? []
          closeModal = false
        })

      if (closeModal) {
        showThemeNameModal.value = false
      }

      if (!newTheme) {
        analytics?.track.themeBuilder.renameTheme(query.id, query.name)
      }
    }

    const dashboardFormSubmit = async (query: SavedQuery) => {
      let closeModal = true

      await saveCurrentTheme(query)
        .catch((res) => {
          store.dispatch(CLEAR_REQUEST_ERRORS)
          submitErrors.value = res.body?.non_field_errors ?? []
          closeModal = false
        })

      if (closeModal) {
        showDashboardModal.value = false
      }
    }

    // Reset errors on modal close
    watch(() => showThemeNameModal.value, (newVal) => {
      if (!newVal) {
        submitErrors.value = []
      }
    })

    const canAddToDashboard = computed(() => {
      return hasUnstructured(expandQuery('query', selectedBotanicQuery.value, savedQueries.value))
    })

    const npsFieldName = computed(() => {
      const schema: SchemaColumn[] = props.currentProject.schema
      const field = schema.find((field) => field.typename === 'NPS')
      return field?.name
    })

    const isNewTheme = computed(() => !!selectedQuery.value?.is_new)

    const isAutoTheme = computed(() => {
      return selectedQueryRows.value.some((q) => q.field === 'aitopic')
    })

    const allConcepts = computed<Concept[]>(() => {
      return props.currentModel?.topics_list
    })

    const visibleConceptStrings = computed<string[]>(() => {
      return allConcepts.value.filter(c => !ignoredConceptNames.value.includes(c.name)).map((c) => c.name as string).sort((c1, c2) => c1.localeCompare(c2))
    })

    const progressCoverage = computed(() => {
      const data = totalCoverage.value
      const all_frames = Math.max(data.all_frames, 1)
      return Math.round(data.frames_covered / all_frames * 100) || 0
    })

    const showAddConceptAsOR = computed(() => {
      return countTextRows(selectedQueryRows.value) === 1
    })

    const queryUpdatedMethod = computed(() => {
      return selectedQuery.value === null
        ? (val: QueryElement[]) => {
          addTheme()
          setQuery(val)
        }
        : setQuery
    })

    /** Component methods **/

    const addConceptToQuery = (concept: string) => {
      let newRow: QueryElement = {
        type: 'text',
        values: [concept],
        operator: 'includes',
      }
      queryUpdatedMethod.value(selectedQueryRows.value.concat(newRow))
      scrollToTop()
      clearClickedConcept()
    }

    const verbatimsConfigChanged = (updated: VerbatimsConfig) => {
      verbatimsConfig.value = updated
    }

    const addConceptAsOR = (concept: string) => {
      const rows = selectedQueryRows.value.map(r => {
        if (r.type !== 'text') return r
        r.values = (r.values ?? []).concat([concept])
        return r
      })
      setQuery(rows)
      scrollToTop()
    }

    // const parseId = (id: string | null) => {
    //   let parsedId = parseInt((id ?? "").replace(/^(query_|group_)/, ''))
    //   if (isNaN(parsedId)) {
    //     throw Error('Invalid ID: ' + parsedId)
    //   }
    //   return parsedId
    // }

    const changeQueryScope = (newScope: 'frame' | 'sentence') => {
      stagedQueries.value.map((q) => {
        if (q.id === selectedQueryID.value) q.query_value.level = newScope
      })
    }

    const replaceQueryWithConcept = (concept: string) => {
      let newRow: QueryElement = {
        type: 'text',
        values: [concept],
        operator: 'includes',
      }
      setQuery([newRow])
      scrollToTop()
    }

    const loadThemes = (overwriteStaged = true) => {
      isLoading.themes = true
      Object.assign(savedQueries, [])
      return fetchThemes(props.projectId, props.analysisId)
        .then((res) => {
          savedQueries.value = cloneDeep(res)
          store.commit(SET_SAVED_QUERIES, cloneDeep(res))
          if (overwriteStaged) {
            stagedSavedQueries.value = cloneDeep(res)
          }
          return updateStats()
        })
        .catch(() => error.value = ERROR.FETCH_THEMES)
        .finally(() => isLoading.themes = false)
    }

    const loadCoverage = async () => {
      try {
        isLoading.coverage = true
        await loadGroupTree()
      } catch (e) {
        console.error(e)
      } finally {
        isLoading.coverage = false
      }
    }

    const groupTree = ref<GroupOrTheme[]>([])

    const countThemes = (item: GroupOrTheme): number => {
      if (item.type === 'group') {
        return item.children.reduce((acc, child) => acc + 1 + countThemes(child), 0)
      } else {
        return 0
      }
    }

    const fetchCoverage = (queries: PayloadNode[]): Promise<[Coverage, TreeDataNode[]]> => {
      const fetchParams = [
        props.projectId,
        queries,
        props.currentAnalysis.topic_framework_id,
        props.currentProject.chrysalis_ref,
      ]

      const cacheKey = { name: 'coverageV2', fetchParams }
      return fetch(cacheKey, Query.getThemeStatsV2, fetchParams)
        .then(async (result: CoverageStats) => {
          const items = result.query_stats.map((data) => {
            const dataId = data.id.toString()
            if (dataId.startsWith("new_query_")) {
              const treeNode: TreeDataNode = {
                id: dataId,
                type: data.type,
                name: data.name,
                themeCount: 0,
                coverage: data.coverage,
                updating: false,
                num_hits: data.num_hits,
                leaf: true,
              }
              return treeNode
            }

            const type: GroupOrTheme['type'] = dataId.startsWith('query') ? 'theme' : 'group'
            const id = +dataId.replace(/^(query|group)_/, '')
            let node: GroupOrTheme | undefined
            if (id === -1) {
              node = {
                id: -1,
                name: 'Ungrouped themes',
                type: 'group',
                children: ungroupedThemes.value,
              }
            } else {
              node = findNode(groupTree.value, {
                id,
                type,
              })!
            }

            // Show 0 coverage for empty groups
            const coverage =
              node.type === 'group' &&
              node.children.length === 0
                ? 0
                : data.coverage

            const treeNode: TreeDataNode = {
              id: data.id.toString(),
              type,
              name: node.name,
              themeCount: countThemes(node),
              coverage: coverage,
              updating: false,
              num_hits: data.num_hits,
              leaf: type === 'theme',
            }

            tree?.value?.updateNodeData(dataId, treeNode)
            return treeNode
          })
          return [ result, items ]
        })
    }

    const nodesToPayload = (items: GroupOrTheme[]): PayloadNode[] => {
      return items.reduce((arr, item) => {
        let newArr = arr.slice()
        if (item.type === 'group') {
          const group = expandThemeGroup(item as Group, stagedSavedQueries.value)
          newArr.push({
            id: `group_${group.id}`,
            name: group.name,
            type: 'theme',
            value: group.query_value,
            omit_from_overall: item.children.length === 0,
          } as PayloadNode)
        } else {
          const query = stagedSavedQueries.value.find((q) => q.id === Number(item.id))
          if (query) {
            const expanded = expandQuery(query.name, query.query_value, savedQueries.value)
            newArr.push({
              id: `query_${query.id}`,
              name: query.name,
              type: 'theme',
              value: expanded,
            } as PayloadNode)
          } else {
            console.error(new Error(`Saved Query not found for theme ${item.id}`))
          }
        }
        return newArr
      }, [] as PayloadNode[])
    }

    const ungroupedThemes = computed(() => {
      return groupTree.value?.filter((node) => {
        if (node.type === 'theme') {
          return true
        }
        return false
      }) ?? []
    })

    const updateSelectedCoverage = async () => {
      let id = selectedQueryID.value
      if (id == null || !hasChanges.value[id] || !selectedQuery.value) return

      const node = findNode(groupTree.value, {
        type: 'theme',
        id: id,
      })
      if (!node) return

      const nodes: GroupOrTheme[] = [node]
      let parent = findParentNode(groupTree.value, { id: id, type: 'theme' })
      if (!parent) {
        nodes.push({
          id: -1,
          name: 'Ungrouped themes',
          type: 'group',
          children: ungroupedThemes.value,
        } as Group)
      } else {
        while (parent) {
          nodes.push(parent)
          parent = parent.id !== -1
            ? findParentNode(groupTree.value, { id: parent.id, type: parent.type })
            : {
              id: -1,
              name: 'Ungrouped themes',
              type: 'group',
              children: ungroupedThemes.value,
            } as Group
        }
      }

      const payload = nodesToPayload(nodes)
      setUpdating(payload, true)
      return fetchCoverage(payload)
    }

    const setUpdating = <T extends { id: string | number }>(nodes: T[], updating: boolean) => {
      nodes.forEach((node) => {
        tree?.value?.updateNodeData(node.id.toString(), { updating })
      })
    }

    const topLevelData = ref<TreeDataNode[]>([])

    const loadTopLevel = async () => {
      let nodes: GroupOrTheme[] = groupTree.value

      nodes = nodes.filter((node) => {
        return node.type !== 'theme'
      })

      nodes.push({
        id: -1,
        name: 'Ungrouped themes',
        type: 'group',
        children: ungroupedThemes.value,
      })

      const payload = nodesToPayload(nodes)
      const validNewQueries = stagedNewQueries.value
        .filter(({ query_value }) => {
          const rows = getQueryRows(query_value)
          return isQueryValid(rows)
        })
      validNewQueries.forEach((q) => {
        const expanded = expandQuery(q.name, q.query_value, savedQueries.value)
        payload.push({
          id: `new_query_${q.id}`,
          name: q.name,
          type: 'theme',
          value: expanded,
          omit_from_overall: !hasUnstructured(q.query_value),
        })
      })
      const [ stats, items ] = await fetchCoverage(payload)
      totalCoverage.value.all_frames = stats.all_frames
      totalCoverage.value.frames_covered = stats.frames_covered
      topLevelData.value = items
      return items
    }

    const loadItemCoverage = async (item: TreeDataNode) => {
      const id = +item.id.replace(/^group_/, '')
      const node = id !== -1
        ? findNode(groupTree.value, {
          id: +item.id.replace(/^group_/, ''),
          type: 'group',
        })
        : {
          id: -1,
          name: 'Ungrouped themes',
          type: 'group',
          children: ungroupedThemes.value,
        } as Group

      if (node?.type === 'group') {
        setUpdating([item], true)
        const payload = nodesToPayload(node.children)
        const [ , items ] = await fetchCoverage(payload)
        setUpdating([item], false)
        return items
      } else {
        return []
      }
    }

    const loadGroupTree = async () => {
      const { group_tree } = await ThemeGroup.list(props.projectId, props.analysisId)
      groupTree.value = group_tree
    }

    const loadIgnoredConceptsStats = () => {
        if (ignoredConceptNames.value.length === 0) {
          return
        }
        isLoading.ignoredConceptsStats = true
        const queries = ignoredConceptNames.value.map((c: string) => {
          return {
            name: c,
            query_value: {
              type: "match_all",
              includes: [
                {
                  type: "text",
                  value: c,
                },
                {
                  type: "segment",
                  field: "Token Count",
                  operator: "<=",
                  value: "2"
                }
              ]
            }
          }
        })
        const fetchParams = [
          props.projectId,
          queries,
          props.currentAnalysis.topic_framework_id,
          props.currentProject.chrysalis_ref,
        ]
        const cacheKey = { name: 'ignoredConceptsStats', fetchParams }
        return fetch(cacheKey, Query.getThemeStats, fetchParams)
        .then((result: CoverageStats) => {
            fetchedData['ignored-concepts-stats'] = {
              status: 'done',
              error: null,
              data: result?.query_stats.map((c) => {
                return {
                  name: c.name,
                  frequency: c.num_hits,
                  nonEmptyCoverage: c.coverage,
                }
              }),
            }
          })
          .catch((error) => {
            fetchedData['ignored-concepts-stats'] = {
              status: 'done',
              error,
              data: null,
            }
        }).finally(() => isLoading.ignoredConceptsStats = false)
    }
    // Select currently focused theme
    const selectTheme = (id: number | null) => {
      selectedQueryID.value = id
    }

    // Delete row at index of staged query
    const deleteRow = (index: number) => {
      const rows = selectedQueryRows.value.filter((_, i) => i !== index)
      setQuery(rows)
    }

    // Set staged query
    const setQuery = (rows: QueryElement[]) => {
      if (!selectedQuery.value) return
      selectedQueryRows.value = rows
      const botanic = QueryUtils.queryRowsToBotanic(rows, selectedQueryScope.value)
      selectedQuery.value = {
        ...selectedQuery.value,
        query_value: botanic,
      }
    }

    const addTheme = (copy: Partial<SavedQuery> = {}) => {
      const newTheme: SavedQuery = {
        name: makeNewThemeName(stagedQueries.value),
        description: '',
        query_value: {
          level: props.currentProject.query_scope_default ?? 'frame',
        } as SavedQuery['query_value'],
        dashboard_ids: [],
        ...copy,

        analysis: props.analysisId,
        project: props.projectId,
        created: '',
        modified: '',
        is_new: true,
        id: Date.now(),
        exclude_mapped: false,
        theme_group: null,
      }
      stagedNewQueries.value.push(newTheme)
      selectTheme(newTheme.id)
    }

    const duplicateTheme = () => {
      if (!selectedQuery.value) return
      showThemeNameModal.value = 'new_theme'
    }

    const deleteTheme = async (theme: SavedQuery, conflicts: SavedQuery[] = []) => {
      const removeOrReplaceState = (theme: SavedQuery, replace?: SavedQuery) => {
        if (theme.is_new) {
          const index = stagedNewQueries.value.findIndex((q) => q.id === theme.id)
          replace
            ? stagedNewQueries.value.splice(index, 1, replace)
            : stagedNewQueries.value.splice(index, 1)
        } else {
          let index = stagedSavedQueries.value.findIndex((q) => q.id === theme.id)
          replace
            ? stagedSavedQueries.value.splice(index, 1, replace)
            : stagedSavedQueries.value.splice(index, 1)

          index = savedQueries.value.findIndex((q) => q.id === theme.id)
          replace
            ? savedQueries.value.splice(index, 1, replace)
            : savedQueries.value.splice(index, 1)
        }
        if (selectedQueryID.value === theme.id) {
          selectedQueryID.value = null
        }
      }

      // Update queries that contain the query to be deleted
      const requests = conflicts.map(async (conflict) => {
        const level = conflict.query_value.level ? conflict.query_value.level : 'frame'
        const rows = QueryUtils.botanicToQueryRows(conflict.query_value)

        rows.forEach((row, i) => {
          if (row.type === 'query') {
            row.values = row.values?.filter((v) => v !== theme.id.toString())

            if (!row.values?.length) {
              rows.splice(i, 1)
            }
          }
        })

        return rows.length === 0
          ? Query.deleteSavedQuery(conflict.project, conflict.analysis, conflict.id)
            .then(() => {
              removeOrReplaceState(conflict)
            })
          : Query.updateSavedQueryV2(conflict.project, conflict.analysis, {
              query_value: QueryUtils.queryRowsToBotanic(rows, level),
              id: conflict.id,
            })
            .then(() => {
              removeOrReplaceState(conflict, {
                ...conflict,
                query_value: QueryUtils.queryRowsToBotanic(rows, level),
              })
            })
      })

      await Promise.all(requests)

      showThemeDeleteModal.value = false

      if (isNewTheme.value) {
        removeOrReplaceState(theme)
      } else {
        await Query.deleteSavedQuery(
          props.projectId,
          props.analysisId,
          theme.id,
        ).then(() => {
          removeOrReplaceState(theme)
          updateStats()
          analytics?.track.themeBuilder.deleteTheme(theme.id)
        })
      }
    }

    const resetButtonClick = async () => {
      const confirm = await resetConfirmModal.value?.confirm()
      if (confirm) {
        analytics?.track.themeBuilder.discardAllChanges()
        // resetState(false)
        refresh()
      }
    }

    const resetState = async (resetNew = true) => {
      queryStats.record_count = 0
      queryStats.total_record_count = 0
      queryStats.verbatim_count = 0
      queryStats.total_verbatim_count = 0
      selectedQueryID.value = null
      stagedSavedQueries.value = []
      totalCoverage.value = {
        all_frames: 0,
        frames_covered: 0,
      }
      groupTree.value = []
      if (resetNew) stagedNewQueries.value = []
      await loadThemes()
    }

    const resetCurrentTheme = () => {
      const id = selectedQueryID.value
      const savedQuery = savedQueries.value.find((q) => q.id === id)
      if (!savedQuery || !selectedQuery.value) return

      const rows = getQueryRows(savedQuery.query_value)
      setQuery(rows)

      selectedQuery.value = {
        ...selectedQuery.value,
        name: savedQuery.name,
        query_value: {
          ...selectedQuery.value.query_value,
          level: savedQuery.query_value.level,
        },
      }
    }

    // Save all saved themes with changes
    const saveAllThemes = () => {
      const requests = stagedQueries.value.map((q) => {
        return hasChanges.value[q.id]
          ? saveOrUpdateTheme(q)
          : Promise.resolve()
      })

      return Promise.all(requests)
    }

    const saveCurrentTheme = (partial?: Partial<SavedQuery> | undefined) => {
      if (!selectedQuery.value) return Promise.reject()
      return saveOrUpdateTheme(selectedQuery.value, partial)
    }

    // If partial payload is provided, only those fields will be updated,
    // leaving currently staged changes to the current query unsaved
    const saveOrUpdateTheme = (
      query: SavedQuery,
      partial?: Partial<SavedQuery> | undefined,
    ) => {
      const rows = getQueryRows(query.query_value)
      // Bail if invalid query
      if (!isQueryValid(rows)) {
        return Promise.resolve()
      }
      const saveMethod = query.is_new
        ? Query.createSavedQueryV2
        : Query.updateSavedQueryV2

      const payload = {
        ...query,
        ...partial ?? {},
      }

      return saveMethod(
        props.projectId,
        props.analysisId,
        payload,
      )
      .then(async (res) => {
        // Track create/save event
        if (query.is_new) {
          analytics?.track.themeBuilder.createTheme(query.name, query.id)
        } else {
          analytics?.track.themeBuilder.saveTheme(query.name, query.id)
        }
        // Remove newly saved theme from stagedNewQueries
        // NOTE: The id returned from botanic in response is
        // different from the id assigned to query by frontend,
        // so we compare using the local `query.id` instead.
        stagedNewQueries.value =
          stagedNewQueries.value
            .filter((q) => q.id !== query.id)

        // Refresh savedQueries
        loadThemes(false)

        // Insert new theme into stagedSavedQueries
        const savedQuery = res.body ?? res
        const index = stagedSavedQueries.value.findIndex((q) => q.id === savedQuery.id)
        if (index !== -1) {
          stagedSavedQueries.value[index] = partial
            ? {
              ...stagedSavedQueries.value[index],
              ...partial,
            }
            : savedQuery
        } else {
          stagedSavedQueries.value.push(savedQuery)
        }

        // Replace selectedQueryID with botanic savedQuery.id
        // if the selected query is being saved
        selectedQueryID.value = query.id === selectedQueryID.value?
          savedQuery.id :
          selectedQueryID.value

        if (query.is_new) {
          updateTree()
        }
      })
    }

    const fetchWidgetData = (
      type: 'key_phrases' | 'context_network' | 'verbatims'
    ) => {
      return (
        name: keyof typeof fetchedData,
        params: {
          query: QueryType,
          options: Record<string, number | string>,
        }
      ) => {
        fetchedData[name] = {
          status: 'fetching',
          error: null,
          data: null,
        }

        let fetchFn: (...args: any[]) => PromiseLike<any> = fetch_pivot_data
        let fetchParams: unknown[] = []

        if (type === 'key_phrases') {
          fetchFn = fetch_keyphrases_data
          fetchParams = [
            {
              projectId: props.projectId,
              chrysalisRef: props.currentProject.chrysalis_ref,
              topicId: props.currentAnalysis.topic_framework_id,
            },
            params,
          ]
        } else if (type === 'context_network') {
          const baselineQuery = QueryUtils.extractStructuredFiltersFromQuery(params.query)
          fetchFn = Query.generateContextNetwork
          fetchParams = [
            props.projectId,
            props.analysisId,
            props.currentProject.chrysalis_ref,
            props.currentAnalysis.topic_framework_id,
            params.query,
            savedQueries.value,
            {
              ...params.options,
              baseline_query: JSON.stringify(baselineQuery)
            },
          ]
        } else if (type === 'verbatims') {
          fetchFn = Query.runQuery
          fetchParams = [
            props.projectId,
            props.analysisId,
            params.query,
            savedQueries.value,
            {
              ...params.options,
            }
          ]
        }

        const cacheKey = { fetchParams, name }
        fetch(cacheKey, fetchFn, fetchParams)
          .then((result) => {
            fetchedData[name] = {
              status: 'done',
              error: null,
              data: result,
            }
          })
          .catch((error) => {
            fetchedData[name] = {
              status: 'done',
              error,
              data: null,
            }
          })
      }
    }

    const fetchVerbatimCount = () => {
      const query = {
        includes: [
          selectedExpandedBotanicQuery.value,
          { type: 'nonempty_data' },
        ],
        excludes: [],
        type: "match_all",
      }
      const countFetchParams = [
        props.projectId,
        props.analysisId,
        query,
        savedQueries.value,
        {
          start: 0,
          limit: 0,
        },
      ]

      const countCacheKey = { countFetchParams, name: 'verbatim_count' }
      return Promise.all([
        fetch(countCacheKey, Query.runQuery, countFetchParams)
          .then((result) => {
            const count = result.count
            queryStats.verbatim_count = count
          }),
      ])
    }

    const fetchIgnoredVerbatimCount = () => {
      const validStagedQueries = stagedQueries.value.reduce((arr, q) => {
        const rows = getQueryRows(q.query_value)
        if (!isQueryValid(rows)) return arr

        // Convert to botanic format, this process excludes any rows with
        // empty values that would otherwise error if sent to Chrysalis
        const query_value = QueryUtils.queryRowsToBotanic(rows, q.query_value.level ?? 'frame')
        return arr.concat({
          ...q,
          query_value,
        })

      }, [] as SavedQuery[])

      const base_query: QueryType = {
        "level": "frame",
        "type": "match_all",
        "includes": [{
          type: 'match_any',
          includes: ignoredConceptNames.value.map((c) => ({
            type: 'text',
            value: c,
          })),
        }, {
          type: 'match_any',
          includes: [{
            type: "segment",
            field: 'Token Count',
            operator: '<=',
            value: "2"
          }]
        }],
      }

      const exclude_queries_list = validStagedQueries.map((q) => ({
        "value": expandSavedQueryIfPossible(q.name, q.query_value, savedQueries.value),
        "name": q.name,
      }))

      const unmappedBody: UnmappedBodyType = {
        "base_query": base_query,
        "exclude_queries_list": exclude_queries_list,
      }

      const countFetchParams = [
        props.projectId,
        unmappedBody,
        props.currentAnalysis.topic_framework_id,
        props.currentProject.chrysalis_ref,
        {
          start: 0,
          limit: 0,
          documents: false,
          networkModel: false,
        },
      ]

      const totalFetchParams = [
        props.projectId,
        props.analysisId,
        {
          type: 'match_all',
          includes: [
            { type: 'all_data' },
            { type: 'nonempty_data' },
          ]
        },
        savedQueries.value,
        {
          start: 0,
          limit: 0,
        },
      ]

      const countCacheKey = { countFetchParams, name: 'ignored_verbatim_count' }
      const totalCacheKey = { countFetchParams, name: 'total_verbatim_count' }

      const fetchCoverage = ignoredConceptNames.value.length === 0
        ? Promise.resolve({ count: 0 })
        : fetch(countCacheKey, Query.runUnmapped, countFetchParams)

      return Promise.all([
        fetchCoverage
          .then((result) => {
            const count = result.total_hits
            queryStats.ignored_verbatim_count = count
          }),
        fetch(totalCacheKey, Query.runQuery, totalFetchParams)
          .then((result) => {
            const count = result.count
            queryStats.total_verbatim_count = count
          }),
      ])
    }

    // Fetch record count for selected theme
    const fetchRecordCount = () => {
      isLoading.stats = true

      const requirements = {
        blocks: [{
          aggfuncs: [{
            new_column: 'frequency_cov',
            src_column: 'document_id',
            aggfunc: 'count',
          }],
        }],
        queries: [{
          name: 'filtered_query',
          value: selectedExpandedBotanicQuery.value,
        }],
      }

      const fetchParams = [
        {
          projectId: props.projectId,
          chrysalisRef: props.currentProject.chrysalis_ref,
          topicId: props.currentAnalysis.topic_framework_id,
        },
        requirements,
      ]
      const cacheKey = { fetchParams, name: 'record_count' }
      return fetch(cacheKey, fetch_pivot_data, fetchParams)
        .then((result: PivotData) => {
          const count = result.payload.find((d) => d.group__ === 'filtered_query')?.frequency_cov
          const overall = result.payload.find((d) => d.group__ === 'overall__')?.frequency_cov
          queryStats.record_count = +(count ?? 0)
          queryStats.total_record_count = +(overall ?? 0)
          isLoading.stats = false
        })
    }

    const updateStats = async () => {
      // Update selected theme stats
      await Promise.all([
        updateSelectedCoverage(),
        stagedQueryIsValid.value && fetchVerbatimCount(),
        stagedQueryIsValid.value && fetchRecordCount(),
      ])

      // Udpdate global stats
      await Promise.all([
        loadTopLevel(),
        loadIgnoredConceptsStats(),
        fetchIgnoredVerbatimCount(),
      ])
    }

    const addConceptFromSidebar = (concept: string, newTheme: boolean) => {
      if (newTheme) {
        addTheme()
        setQuery([{
          type: 'text',
          values: [concept],
          operator: 'includes',
        }])
      } else {
        addConceptToQuery(concept)
      }

      clearClickedConcept()
      scrollToTop()
    }

    const sidebarConceptClicked = (concept: string, el: HTMLElement) => {
      const { x, y } = el.getBoundingClientRect()
      clickedConcept.value = {
        name: concept,
        x: x + 10,
        y: y,
      }
    }
    const storyboardConceptClicked = (concept: string, el: HTMLElement, dimmed: boolean) => {
      const { x, y } = el.getBoundingClientRect()
      clickedStoryboardConcept.value = {
        name: concept,
        x: x + 10,
        y: y,
        dimmed,
      }
    }
    const clearClickedConcept = () => {
      clickedConcept.value = {
        name: null,
        x: 0,
        y: 0,
      }
      clickedStoryboardConcept.value = {
        name: null,
        x: 0,
        y: 0,
        dimmed: false,
      }
    }

    const ignoreConcept = (concept: string) => {
      ignoredConceptNames.value = ignoredConceptNames.value.concat([concept])
      clearClickedConcept()
      analytics?.track.themeBuilder.ignoreConcept(concept)
    }
    const unignoreConcept = (concept: string) => {
      ignoredConceptNames.value = ignoredConceptNames.value.filter((c) => c !== concept)
      clearClickedConcept()
    }

    const scrollToTop = () => {
      scrollableContent.value?.scrollTo({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    }

    const setExcludeMapped = (value: boolean) => {
      selectedQuery.value = {
        ...selectedQuery.value,
        exclude_mapped: value,
      } as SavedQuery
    }

    const goToUnmappedPage = (concept: string) => {
      analytics?.track.themeBuilder.viewOnUnmapped(concept)
      router.push({
        name: 'unmapped',
        params: {
          projectId: props.projectId.toString(),
          analysisId: props.analysisId.toString(),
          navigatedConcept: concept,
        },
      })
    }

    const updateTree = async () => {
      await refresh(false)
    }

    // Ungrouped themes is expanded by default
    const expandedNodeKeys = ref<string[]>(['group_-1'])
    const nodeExpanded = (node: TreeDataNode) => {
      expandedNodeKeys.value = [
        ...expandedNodeKeys.value,
        node.id,
      ]
    }
    const nodeCollapsed = (node: TreeDataNode) => {
      expandedNodeKeys.value = expandedNodeKeys.value.filter((id) => id !== node.id)
    }

    const refresh = async (resetNew = true) => {
      try {
        isLoading.state = true
        await resetState(resetNew)
        await loadCoverage()
        await loadTopLevel()
      } finally {
        isLoading.state = false
      }
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      error,
      savedQueries,
      selectTheme,
      isLoading,
      activeTab,
      addConceptToQuery,
      addConceptAsOR,
      replaceQueryWithConcept,
      deleteRow,
      setQuery,
      addTheme,
      changeQueryScope,
      resetState,
      fetchedData,
      hasFiles: true, // TODO: file_based exists only on currentDashboard.project
      fetchContextNetwork: fetchWidgetData('context_network'),
      fetchKeyPhrases: fetchWidgetData('key_phrases'),
      fetchVerbatims: fetchWidgetData('verbatims'),
      npsFieldName,
      showJSON,
      isNewTheme,
      queryUpdatedMethod,
      saveOrUpdateTheme,
      saveCurrentTheme,
      resetCurrentTheme,
      deleteTheme,
      stagedQueries,
      hasChanges,
      selectedQuery,
      selectedQueryID,
      selectedQueryRows,
      selectedBotanicQuery,
      selectedExpandedBotanicQuery,
      progressCoverage,
      showAddConceptAsOR,
      stagedQueryIsValid,
      allConcepts,
      visibleConceptStrings,
      queryStats,
      showThemeNameModal,
      canAddToDashboard,
      renameFormSubmit,
      submitErrors,
      showThemeDeleteModal,
      showDashboardModal,
      dashboardFormSubmit,
      duplicateTheme,
      percent,
      sidebarConceptClicked,
      storyboardConceptClicked,
      clickedConcept,
      clickedStoryboardConcept,
      clearClickedConcept,
      addConceptFromSidebar,
      ignoreConcept,
      unignoreConcept,
      ignoredConcepts,
      ignoredConceptNames,
      isUnsaved,
      selectedQueryScope,
      scrollableContent,
      comma,
      resetConfirmModal,
      resetButtonClick,
      setExcludeMapped,
      hasNoResults,
      hideMappedSidebar,
      keyConcepts,
      goToUnmappedPage,
      verbatimsConfig,
      verbatimsConfigChanged,
      saveAllThemes,
      location: QueryLocation.ThemeBuilder,
      updateStats,
      updateTree,
      themeGroups,
      groupTree,
      loadItemCoverage,
      topLevelData,
      tree,
      nodeExpanded,
      nodeCollapsed,
      expandedNodeKeys,
      isAutoTheme,
    }
  }
})

export default ThemeBuilder
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .theme-builder
    display: flex
    flex-direction: row
    height: 100%
    user-select: none
    > .sidebar
      $width: 380px
      position: relative
      min-width: $width
      width: $width
      display: flex
      flex-direction: column
      padding: 0 !important
      margin-right: 20px

      ::v-deep
        .el-tabs__content
          overflow-y: scroll
          flex: 1
        .el-tabs
          height: 100%
          display: flex
          flex-direction: column

      > .content
        flex: 1
        overflow-y: hidden

      .tab-title
        padding: 0 20px
        width: 100%
        &:hover
          color: $blue
      .tab-content
        padding-left: 26px
        padding-right: 26px
        flex: 1
      .active
        color: $blue

      .buttons
        display: flex
        justify-content: space-between
        padding: 0 26px
        white-space: nowrap
        margin: 0 0 20px

    > .content
      flex: 1
      padding-right: 20px
      .error
        color: $red
        background: lighten($red, 40%)

  .buttons
    margin-top: auto

  .content-wrapper
    display: flex
    flex-direction: column
    height: 100%
    padding-right: 20px
    overflow-y: auto
    overflow-x: hidden

    > div:nth-child(2)
      flex: 1

  .widget-container
    background: $grey-light-background
    display: flex
    flex: 1
    flex-direction: column
    align-items: center
    flex-wrap: wrap
    align-items: flex-start
    justify-content: center
    height: 100%
    user-select: text

    .column
      flex: 1
      display: flex
      flex-direction: row
      align-items: stretch
      justify-content: center
      width: 100%
      &:not(:last-child)
        margin-bottom: 40px

    .widget
      min-width: 400px
      flex: 1
      &:not(:first-child)
        margin-left: 20px !important

    @media screen and (max-width: 1920px)
      .column
        flex-direction: column !important

      .widget
        &:not(:first-child)
          margin-left: 0 !important
          margin-top: 40px !important

  .sidebar
    background: #fff
    padding: 30px
    border-radius: 4px

  .top-bar
    height: 34px
    line-height: 34px
    margin-bottom: 15px
    display: flex
    align-items: center
    ::v-deep .dropdown-menu
      margin-top: 9px !important

    > *
      white-space: nowrap
      &:not(:last-child)
        margin-right: 14px

    .theme-name
      margin-right: 20px
      font-size: 22px
      font-weight: 600
      cursor: pointer
      i
        font-size: 9px
        margin-left: 3px
        position: relative
        top: -1px


  .query-stats, .query-controls
    color: $text-black
    margin: 15px 0
    white-space: nowrap
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    span
      font-size: 8px
      margin: 0 10px
      opacity: 0.8
    .percent
      font-size: inherit
      font-weight: normal
      margin: 0 0 0 6px

  .bf-button
    &.staff-only
      position: relative
      padding-right: 70px
      &::after
        position: absolute !important
        right: 4px !important
        top: 50% !important
        margin-top: -1em !important
        height: 2em !important

  .query-json
    border-radius: 4px
    background: #fff
    margin-block: 20px

    textarea
      width: 100%
      resize: vertical
      min-height: 200px
      border: none

  .storyboard
    min-height: 800px
    padding-top: 20px !important
    height: 100%

  .red
    color: $red
    &:hover
      color: $red !important

  .discard-button
    color: $text-grey !important
    padding-left: 0
    padding-right: 0
    .icon-wrapper
      margin-right: 4px
    span
      background-color: $text-grey !important
      margin-right: 8px

  .wrapper
    flex: 1
    height: 100%

  .sticky
    position: sticky
    top: 0
    z-index: 9
    background: #f6f6f6

  .interaction-menu
    background: $white
    padding: 20px 30px
    box-shadow: 0px 1px 5px 1px rgba(0, 1, 1, 0.1)
    z-index: 999
    border-radius: 5px
    width: max-content
    ::v-deep hr
      border: 0
      border-top: 1px solid $grey
      padding: 5px 0
    ::v-deep button
      border: 0
      padding: 0
      display: block
      font-size: 16px
      line-height: 24px
      color: $text-black
      cursor: pointer
      white-space: nowrap
      &:not(:last-child)
        margin-bottom: 10px
      &:hover
        color: lighten($text-black, 30%)

  .query-controls
    margin-bottom: 15px
    > label
      font-weight: normal
      display: flex
      align-items: center

    .switch
      margin-right: 6px

  .no-results,
  .loading-theme
    width: 100%
    text-align: center
    font-size: 20px
    color: $text-grey

  .scope-label
    font-size: 16px !important
    margin: 0 0 0 6px !important
    color: $blue
    cursor: pointer
    position: relative
    top: 1.5px
    i
      font-size: 9px
      margin-left: 2px

  .ignored-concepts
    ::v-deep .concept-item
      filter: grayscale(1)
      opacity: 0.5

  .el-tab-pane
    padding-top: 10px
    padding-bottom: 20px
    height: 100%
    display: flex
    flex-direction: column
    > hr
      border: 0
      border-top: 1px solid $grey
      margin: 20px 20px

    > label
      margin: 0 20px 16px
      display: block
      > .switch
        margin-right: 6px

  ::v-deep
    .el-tabs__item
      padding: 0 0 0 26px !important
      font-weight: bold
      text-transform: uppercase
      letter-spacing: 0.3px
      color: $text-black
      box-shadow: none !important

  .breadcrumbs
    color: $text-black
    border-bottom: 1px solid transparentize($text-grey, 0.5)
    padding-bottom: 4px
    margin-bottom: 15px
    text-transform: uppercase
    font-size: 12.5px
    font-weight: bold
    opacity: 0.6
    button
      font-weight: bold
      text-transform: uppercase
      background: none
      color: $text-grey
      border: none
      padding: 0
      cursor: pointer
      &:hover
        color: $text-black
    span
      margin: 0 10px

  .loading-wrapper
    flex: 1
    display: flex
    justify-content: center
    align-items: center
</style>
