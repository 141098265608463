import { client } from "src/axios"
import { Project } from "src/types/ProjectTypes"

export const listProjects = async () => {
  const { data } = await client.get<Project[]>('/projects/trial/')
  return data
}

export const getProject = async (id: number) => {
  const { data } = await client.get<Project>(`/projects/trial/${id}`)
  return data
}