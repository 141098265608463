<template>
  <div class="wrapper">
    <trial-header />
    <workbench
      :dashboard-id="dashboardId"
      :drilldown="drilldown"
      :drilldown-id="drilldownId"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import Workbench, { Drilldown } from "./Workbench/Workbench.vue"
import TrialHeader from "./Header.vue"

export default defineComponent({
  name: "TrialResults",
  components: {
    Workbench,
    TrialHeader,
  },
  props: {
    dashboardId: { type: Number, required: true },
    drilldown: {
      type: [String, Boolean] as PropType<Drilldown>,
      default: false,
    },
    drilldownId: {
      type: String as PropType<string | null>,
      default: null
    },
  },
})
</script>

<style lang="scss" scoped>
@import "assets/kapiche.sass";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
