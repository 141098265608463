import Overview from "pages/trial/Overview.vue"
import Results from "pages/trial/Results.vue"
import Register from "pages/trial/Register.vue"
import { RouteRecordRaw } from "vue-router"

/**
 * All these routes are used for the free trial.
 *
 * We've seperated these out to make it easier to work on/understand the
 * trial flow.
 */
export const trialRoutes: RouteRecordRaw[] = [
  {
    path: "trial",
    meta: { site: "trial" },
    children: [
      {
        path: "",
        component: Overview,
        name: "trial-overview",
        meta: {
          requiresAuth: false,
          title: "Trial Home",
        },
        props: true
      },
      {
        path: "results/:dashboardId",
        component: Results,
        name: "trial-results",
        meta: {
          requiresAuth: false,
          title: "Results",
        },
        props: (route) => ({
          dashboardId: parseInt(route.params.dashboardId as string),
        }),
      },
      {
        path: "results/:dashboardId/theme/:drilldownId",
        component: Results,
        name: "trial-results-drilldown-theme",
        meta: {
          requiresAuth: false,
          title: "Results - Theme Drilldown",
        },
        props: (route) => ({
          dashboardId: parseInt(route.params.dashboardId as string),
          drilldownId: parseInt(route.params.drilldownId as string),
          drilldown: "theme",
        }),
      },
      {
        path: "results/:dashboardId/theme-group/:drilldownId",
        component: Results,
        name: "trial-results-drilldown-theme-group",
        meta: {
          requiresAuth: false,
          title: "Results - Theme Group Drilldown",
        },
        props: (route) => ({
          dashboardId: parseInt(route.params.dashboardId as string),
          drilldownId: parseInt(route.params.drilldownId as string),
          drilldown: "theme_group",
        }),
      },
      // {
      //   path: "results/:dashboardId/concept/:drilldownId",
      //   component: Results,
      //   name: "trial-results-drilldown-concept",
      //   meta: {
      //     requiresAuth: false,
      //     title: "Results - Theme Concept",
      //   },
      //   props: (route) => ({
      //     dashboardId: parseInt(route.params.dashboardId as string),
      //     drilldown: "concept",
      //   }),
      // },
      // {
      {
        path: "/register",
        component: Register,
        name: "trial-registration",
        meta: {
          requiresAuth: true,
          title: "Trial Home",
        },
        props: true
      },
    ]
  }
]
