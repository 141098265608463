<template>
  <div class="legend">
    <div class="widget-sort-controls">
      <div>
        SHOW
      </div>
      <div class="sort-inputs">
        <el-dropdown
          class="dropdown-input"
          trigger="click"
          @command="$emit('update-dots-visible', $event)"
        >
          <div>
            {{ dotsVisible === Infinity ? 'All' : `Top ${dotsVisible}` }}
            <i class="icon dropdown"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="option in [
                  ['Top 5', 5],
                  ['Top 10', 10],
                  ['Top 15', 15],
                  ['Top 20', 20],
                  ['Top 25', 25],
                  ['Top 30', 30],
                  ['Top 40', 40],
                  ['All', Infinity],
                ]"
                :key="option[0]"
                :command="option[1]"
              >
                {{ option[0] }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span>
          by
        </span>
        <el-dropdown
          class="dropdown-input"
          trigger="click"
          @command="$emit('update-sort-method', $event)"
        >
          <div>
            {{ sortOptions.find((o) => o[1] === sortMethod)?.[0] }}
            <i class="icon dropdown"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="option in sortOptions"
                :key="option[0]"
                :command="option[1]"
              >
                {{ option[0] }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="dot-container">
      <div class="dots-grid">
        <div
          v-for="(dot, index) in visibleDots"
          :key="dot.id"
          class="dot-item"
        >
          <span class="number">{{ index + 1 }}</span>
          <span class="dot" :style="{ backgroundColor: dot.color }"></span>
          <div class="label">
            <span :title="dot.label">{{ dot.label }}</span>
            <span
              v-if="getGroupLabel(dot.id)"
              class="group-tag"
            >
              [{{ getGroupLabel(dot.id) }}]
            </span>
          </div>
        </div>
      </div>
      <button
        v-if="dots.length > dotsVisible"
        @click="$emit('update-dots-visible', Infinity)"
      >
        Show {{ dots.length - dotsVisible }} more concepts
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { QuadrantChartDot } from "src/types/components/Charts.types"
import { SortMethod } from "./QuadrantWidget.utils"

export default {
  props: {
    dots: {
      type: Array as PropType<QuadrantChartDot[]>,
      required: true,
    },
    dotsVisible: {
      type: Number as PropType<number>,
      default: 5,
    },
    sortOptions: {
      type: Array as PropType<[string, SortMethod][]>,
      required: true,
    },
    sortMethod: {
      type: String as PropType<SortMethod>,
      required: true,
    },
    groupLabelMap: {
      type: Object as PropType<Record<number, string>>,
      required: true,
    },
  },
  setup (props) {
    const visibleDots = computed(() => {
      return props.dots.slice(0, props.dotsVisible)
    })

    const getNumericId = (id: string) => {
      return Number(id.replace(/^(q_|group_)/, ''))
    }

    const getGroupLabel = (id: string) => {
      return props.groupLabelMap[getNumericId(id)]
    }

    return {
      visibleDots,
      getGroupLabel,
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.dot-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 26px;
  margin: 20px 0;
}
.zoomed {
  .dot-container {
    padding: 0;
  }
}

.dots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 6px;
  width: 100%;
}
.dot-item {
  display: flex;
  align-items: center;
  min-width: 0;

  .number {
    color: $subdued;
    width: 15px;
    text-align: right;
    font-weight: bold;
    font-size: 13px;
    direction: rtl;
  }
  .dot {
    $size: 10px;
    width: $size;
    min-width: $size;
    height: $size;
    border-radius: 50%;
    margin: 0 8px;
  }
  .label {
    font-size: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
button {
  margin-top: 20px;
  padding: 0;
  background-color: none;
  color: $blue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.sort-inputs {
  line-height: 30px;
  > span {
    vertical-align: top;
  }
}
</style>