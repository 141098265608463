import { ComputedRef, watch, ref, onBeforeUnmount, Ref } from "vue"
import QueryAPI from "src/api/query"
import { useFetchData } from "src/components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils"
import { fetch_keyphrases_data, fetch_pivot_data } from "src/store/modules/data/api"
import { Dashboard } from "src/types/DashboardTypes"
import { QueryType, SavedQuery } from "src/types/Query.types"
import QueryUtils, { mergeDashboardFiltersWithBotanicQuery } from "src/utils/query"
import { Requirements } from "src/types/PivotData.types"
import { ChrysalisFilter } from "src/types/DashboardFilters.types"
import { ExpandedGroup, processFilters } from "src/pages/dashboard/Dashboard.utils"
import { FetchStatus } from "src/types/widgets.types"

export type Drilldown = "theme" | "concept" | "theme_group" | false

// Response from the pivot endpoint.
export interface PivotData {
  payload: (Record<string, number> & {
    frequency_cov: number
    group__: string
  })[]
  stats: {
    "aggregation time: overall": number
    "cut time": number
    "filtering_time": number
    "horizontal concat": number
    "parquet_load_time": number
    "sentiment merge time": number
    "vertical concat": number
  }
}

// Response from the context network endpoint.
interface ContextNetworkData {
  total_hits: number
  model: ContextNetworkModel
}
interface ContextNetworkModel {
  n_frames: number
  n_empty_frames: number
  avg_frame_terms: number
  n_non_english_frames: number
  concepts: ContextNetworkConcept[]
  mst: [number, number][]
  clusters: number[][]
  links: number[][]
  driving_concepts: string[]
}
interface ContextNetworkConcept {
  name: string
  frequency: number
  variants: string[]
  position: [number, number]
  influence_strength: number
}


// This is the data wrapper we pass to widgets
export interface FetchedDataPayload<T> {
  status: FetchStatus
  data: T | undefined
  error: string | undefined
}

export type FetchedData =
  Omit<Record<string, FetchedDataPayload<PivotData> | undefined>, "context-network" | "verbatims"> & {
    "context-network"?: FetchedDataPayload<ContextNetworkData> | undefined
    "verbatims"?: FetchedDataPayload<RunQueryResponse> | undefined
  }

export enum WorkbenchMode {
  TrialOverview = "trial_overview",
  TrialDrilldown = "trial_drilldown",
}

export interface CoverageStats {
  records: number
  totalRecords: number
  verbatims: number
  totalVerbatims: number
}

interface RunQueryResponse {
  count: number
  hits: never[]
  limit: number
  sort_field: string | null
  sort_order: string
  start: number
  total_hits: number
}


// Creates a new cache instance
const { fetch } = useFetchData()

export const fetchTunnelData = <T>(
  key: string,
  requirements: Requirements,
  dashboard: Dashboard,
  filters: ChrysalisFilter[] = [],
  fetch_method = fetch_pivot_data,
  force = false,
) => {
  const fetchParams = [
    {
      id: key,
      projectId: dashboard.project.id,
      chrysalisRef: dashboard.project.chrysalis_ref,
      topicId: dashboard.analysis.topic_framework_id,
    },
    requirements,
    filters,
  ]

  const cacheKey = { fetchParams, name: key }
  return fetch<T>(cacheKey, fetch_method, fetchParams, force)
}

// Fetch record coverage stats for the given botanic query.
const fetchRecordCoverage = (
  expandedQuery: QueryType,
  dashboard: Dashboard,
) => {
  const requirements = {
    blocks: [{
      aggfuncs: [{
        new_column: "frequency_cov",
        src_column: "document_id",
        aggfunc: "count",
      }],
    }],
    queries: [{
      name: "filtered_query",
      value: expandedQuery,
    }],
  }

  return fetchTunnelData<PivotData>("record_count", requirements, dashboard)
}

// Fetch verbatim coverage for the given botanic query.
export const fetchVerbatims = (
  savedQueries: SavedQuery[],
  dashboard: Dashboard,
  { query, options }: {
    query: QueryType,
    options: {
      start: number
      limit: number
    },
  }
) => {
  const countFetchParams = [
    dashboard.project.id,
    dashboard.analysis.id,
    query,
    savedQueries,
    options,
  ]

  const countCacheKey = { countFetchParams, name: "verbatim_count" }
  return fetch<RunQueryResponse>(countCacheKey, QueryAPI.runQuery, countFetchParams)
}

// Fetch the context network.
export const fetchContextNetwork = (
  validFilterRows: ComputedRef<ChrysalisFilter[]>,
  fetchedData: FetchedData,
  dateFieldNames: ComputedRef<string[]>,
  savedQueries: ComputedRef<SavedQuery[]>,
  dashboard: ComputedRef<Dashboard>,
  id: string,
  requirements: {
    query: QueryType,
    options: {
      num_network_concepts: number,
      baseline_query?: string,
    }
  },
) => {
  fetchedData[id] = {
    status: "fetching",
    error: undefined,
    data: undefined,
  }

  const query = mergeDashboardFiltersWithBotanicQuery(
    requirements.query,
    validFilterRows.value,
    dateFieldNames.value,
  )

  const baselineQuery = QueryUtils.extractStructuredFiltersFromQuery(query)
  const options = {
    ...requirements.options,
    baseline_query: JSON.stringify(baselineQuery)
  }

  const contextNetworkParams = [
    dashboard.value.project.id,
    dashboard.value.analysis.id,
    dashboard.value.project.chrysalis_ref,
    dashboard.value.analysis.topic_framework_id,
    query,
    savedQueries.value,
    options,
  ]

  const countCacheKey = { contextNetworkParams, name: "context_network" }
  fetch<ContextNetworkData>(countCacheKey, QueryAPI.generateContextNetwork, contextNetworkParams)
    .then((result) => {
      fetchedData['context-network'] = {
        status: "done",
        error: undefined,
        data: result,
      }
    })
    .catch((error: string) => {
      fetchedData['context-network'] = {
        status: "done",
        error,
        data: undefined,
      }
    })
}

// Fetch record and verbatim coverage stats for given filters.
export const fetchCoverageStats = async (
  baseQuery: QueryType,
  filters: ChrysalisFilter[],
  savedQueries: SavedQuery[],
  dateFields: string[],
  dashboard: Dashboard,
): Promise<CoverageStats> => {
 const expandedQuery =
  mergeDashboardFiltersWithBotanicQuery(
    baseQuery,
    filters,
    dateFields,
  )

  const getVerbatimReqs = (query: QueryType | null) => ({
    query: {
      includes: [
        query,
        { type: "nonempty_data" },
      ].filter(Boolean),
      excludes: [],
      type: "match_all",
    } as QueryType,
    options: {
      start: 0,
      limit: 0,
    },
  })

  // TODO: We need a better way to fetch verbatim coverage/count.
  // This is the same method as we use on the dashboard due to the
  // pivot endpoint counting frames but not verbatims.
  const [
    recordCoverage,
    verbatimCoverage,
    totalVerbatimCount,
  ] = await Promise.all([
    fetchRecordCoverage(expandedQuery, dashboard),
    fetchVerbatims(savedQueries, dashboard, getVerbatimReqs(expandedQuery)),
    fetchVerbatims(savedQueries, dashboard, getVerbatimReqs(null)),
  ])

  return {
    records: recordCoverage.payload.find(({ group__ }) => group__ === "filtered_query")?.frequency_cov ?? 0,
    totalRecords: recordCoverage.payload.find(({ group__ }) => group__ === "overall__")?.frequency_cov ?? 0,
    verbatims: verbatimCoverage.count,
    totalVerbatims: totalVerbatimCount.count,
  }
}

export const fetchWidgetData = (
  validFilterRows: ComputedRef<ChrysalisFilter[]>,
  fetchedData: FetchedData,
  dashboard: ComputedRef<Dashboard>,
  drilldownQuery: ComputedRef<SavedQuery | ExpandedGroup | null>,
  method: "pivot" | "phrases",
) => (
  id: string,
  widget_requirements: Record<string, unknown>,
  force = false,
  use_filters = true as boolean | ChrysalisFilter[],
) => {
  // Widgets can opt to include dashboard filters or specify their own.
  const filters = use_filters
      ? processFilters(
          use_filters === true
            ? validFilterRows.value
            : use_filters
        )
      : []

  fetchedData[id] = {
    status: "fetching",
    error: undefined,
    data: undefined,
  }

  let fetchMethod = fetch_pivot_data
  if (method === "phrases") {
    fetchMethod = fetch_keyphrases_data
  }

  if (drilldownQuery.value) {
    widget_requirements.queries = [{
      name: drilldownQuery.value.name,
      value: drilldownQuery.value.query_value,
    }]
  }

  fetchTunnelData<PivotData>(
    id,
    widget_requirements,
    dashboard.value,
    filters,
    fetchMethod,
    force,
  )
  .then((result) => {
    fetchedData[id] = {
      status: "done",
      error: undefined,
      data: result,
    }
  })
  .catch((error: string) => {
    fetchedData[id] = {
      status: "done",
      error,
      data: undefined,
    }
  })
}

// Get the ID of the top-most visible widget in the scroll container.
export function useTopVisibleWidget (
  scrollContainerRef: Ref<HTMLDivElement | undefined>,
  widgetRefs: Ref<HTMLDivElement[]>,
) {
  const topWidgetId = ref<string | null>(null)
  let ticking = false

  const updateTopWidget = () => {
    if (!scrollContainerRef.value) return

    const containerRect = scrollContainerRef.value.getBoundingClientRect()
    const halfway = containerRect.top + containerRect.height / 2

    let topWidget = null
    let maxTop = -Infinity

    if (scrollContainerRef.value.scrollTop <= 0) {
      topWidget = widgetRefs.value[0]
    } else {
      for (const widget of widgetRefs.value) {
        if (widget) {
          const rect = widget.getBoundingClientRect()
          const widgetTop = rect.top

          if (widgetTop <= halfway && widgetTop > maxTop) {
            maxTop = widgetTop
            topWidget = widget
          }
        }
      }
    }

    if (topWidget) {
      if (topWidget.id !== topWidgetId.value) {
        topWidgetId.value = topWidget.id
      }
    }
  }

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        updateTopWidget()
        ticking = false
      })
      ticking = true
    }
  }

  watch(scrollContainerRef, (newVal, oldVal) => {
    if (oldVal) {
      oldVal.removeEventListener("scroll", onScroll)
    }
    if (newVal) {
      newVal.addEventListener("scroll", onScroll)
      updateTopWidget()
    }
  }, {
    immediate: true,
  })

  onBeforeUnmount(() => {
    if (scrollContainerRef.value) {
      scrollContainerRef.value.removeEventListener("scroll", onScroll)
    }
  })

  return { topWidgetId }
}
