<template>
  <widget-frame
    :zoomed="false"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="hasErrored"
    :banner="banner"
    class="context-network"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Network icon">
    </template>
    <template #header>
      Context Network
    </template>
    <template #actions>
      <download-export-button
        ref="exportButton"
        :name="`${exportName}-Context Network`"
        :is-loading="isLoading"
        :get-el="getNetworkEl"
        :get-svg-export-config="getExportConfig"
        :make-ppt-slide="makePptSlide"
        :basic-svg-export="true"
        short-name="Context Network"
      ></download-export-button>
      <a
        :href="CONST.widget_help_links.context_network"
        class="widget-action help"
        target="_blank"
      >
        <i class="kapiche-icon-info"></i>
      </a>
    </template>
    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        Start: {{ new Date(startTime) }}<br />
        Done: {{ new Date(doneTime) }}<br />
        Elapsed: {{ (doneTime - startTime) / 1000 }} seconds<br />
        Status: {{ status }}<br />
        Error: {{ error }}
        <hr />
        {{ data }}
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div v-if="error" class="error-panel">
        <h3>
          <img
            class="errorIcon"
            :src="errorIcon"
            alt="widget error icon"
          />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button> or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button> if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template #content>
      <div class="network-wrapper" :style="`height:${height}px`">
        <!-- Network visualisation -->
        <template v-if="model && !isInsufficientData">
          <network-layout
            v-bind="$attrs"
            ref="networkLayout"
            :height="height"
            :model="model"
            :draw-hulls="false"
            node-tooltip-click-text="Click to drilldown into this Concept"
            @loaded="isNetworkDrawn = true"
          >
            <template #interaction-menu="interactionMenuProps">
              <slot name="interaction-menu" :label="interactionMenuProps.label">
                <button @click="$emit('go-to-concept', interactionMenuProps.label)">
                  Drill into <b>{{ interactionMenuProps.label }}</b>
                </button>
                <button @click="$emit('add-concept', interactionMenuProps.label)">
                  Drill into the intersection of
                  <b>{{ viewTitle }}</b>
                  and
                  <b>{{ interactionMenuProps.label }}</b>
                </button>
              </slot>
            </template>
          </network-layout>
        </template>

        <!-- No data -->
        <template v-else-if="model && isInsufficientData">
          <div class="no-data">
            <div>{{ noDataMsg }}</div>
          </div>
        </template>
      </div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PptxGenJS from 'pptxgenjs'
import WidgetFrame from 'src/components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-context-network.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import NetworkLayout from "components/project/analysis/results/widgets/NetworkLayout.vue"

export default defineComponent({
  components: { WidgetFrame, DownloadExportButton, NetworkLayout },
  props: {
    /** data to render */
    data: { type: Object, required: false, default: null },
    exportName: { type: String, required: false, default: '' },
    /** `fetching`, `done` or `''` */
    status: { type: String, required: false, default:'' },
    /** error object for dev panel */
    error: { type: String, required: false, default: null },
    /** nicer error message for user  */
    userError: { type: String, required: false, default: null },
    startTime: { type: Number, required: false, default: null },
    doneTime: { type: Number, required: false, default: null },
    devMode: { type: Boolean, required: false, default: false },
    /** widget banner to display */
    banner: { type: Object, default: ()=>null, required: false },
    minConcepts: { type: Number, default: 3, required: false },
    noDataMsg: { type: String, default: 'Not enough data for network', required: false },
    height: { type: Number, default: 450, required: false },
    query: { type: Object, required: false, default: null },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    /** The title of the current view so that we can use it in the
     * interaction menu. Should be something like "store & prices" */
    viewTitle: { type: String, required: false, default: '' },
  },
  data () {
    return {
      icon,
      isNetworkDrawn: false,
      errorIcon,
    }
  },
  computed: {
    isLoading (): boolean {
      return this.status === 'fetching'
    },
    hasErrored (): boolean {
      return this.error !== null
    },
    isInsufficientData (): boolean {
      const len = this.model?.concepts?.length || 0
      return len < this.minConcepts
    },
    model () {
      return this.data?.model
    }
  },
  watch : {
    query () {
      this.fetchData()
    }
  },
  mounted (): void {
    this.fetchData()
  },
  methods: {
    refresh (): void {
      window.location.reload()
    },
    contact (): void {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    },
    reload (): void {
      this.fetchData(true)
    },
    getExportConfig (): Record<string, string|number> {
      return this.$refs.networkLayout.getExportConfig()
    },
    getNetworkEl (): Element|null {
      return this.$el.querySelector('.network-wrapper svg')
    },
    fetchData (force=false): void {
      this.$emit('requires', 'context-network', {
        query: this.query,
        options: {
          num_network_concepts: 25,
        },
      }, force)
    },
    async makePptSlide (pptx: PptxGenJS) {
      const slide = pptx.addSlide()
      // Unfortunately our PPTX library doesn't seem to preserve aspect ratio.
      // The sizing types it provides are supposed to adjust the image while
      // preserving aspect ratio but they didn't seem to work as documented.
      // So, here we are simply calculating the aspect ratio from the root
      // element of the context network. This element is passed into the image
      // generation code.
      const bbox = this.getNetworkEl()?.getBoundingClientRect()
      const aspectRatio = bbox.width / bbox.height
      slide.addImage({
        data: await this.$refs.exportButton.generateImageData('image/png'),
        x: 1.25,
        y: 0.8,
        w: 4 * aspectRatio,
        h: 4
      })
    },
  },
})
</script>

<style lang="sass" scoped>
  .header-icon
    height: 32px
    width: 32px
</style>

<style lang="sass" scoped>
  @import '~assets/kapiche.sass'

  ::v-deep hr.keyline
    opacity: 0
  ::v-deep div.header
    height: 120px
    border-bottom: 1px solid #e5e5e5
  ::v-deep div.content
    padding: 0

  .network-wrapper
    width: 100%

  .error-panel
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    padding-bottom: 30px

  .message
    display: flex
    flex-direction: row
    justify-content: center
    background-color: rgba(255,0,0, 0.1)
    padding: 6px
    color: $text-black
    width: 100%
    max-height: 30px
    position: absolute
    bottom: 0


  .errorIcon
    position: relative
    height: 32px
    width: 32px
    display: inline-block
    top: 10px

  .action
    padding-top: 20px

  .error-panel
    button
      background: none
      border: none
      border-bottom: 2px solid $blue
      padding: 3px 4px
      &:hover
        background-color: $grey-light
      &:focus
        border: 2px solid $blue-light
        outline: none
</style>
