<template>
  <div v-if="project" class="project-item" @click="$emit('click')">
    <div class="logo">
      <img :src="ProjectSVG" alt="Project icon" />
    </div>
    <div class="info">
      <div class="name">
        {{ project.name }}
      </div>
      <div class="description">
        {{ project.description }}
      </div>
    </div>
  </div>
  <div v-else class="user-project-item coming-soon">
    <div class="logo">
      <img :src="ProjectSVG" alt="Project icon" />
    </div>
    <div class="info">
      <div class="name">Upload your own data</div>
      <div class="description">
        <ul>
          <li>Requires login</li>
          <li>Spreadsheet file formats accepted</li>
          <li>4 structured data columns, 1 unstructured data column</li>
          <li>First 5,000 rows will be used</li>
          <li>Max 15MB file size</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Project } from 'src/types/ProjectTypes'
import ProjectSVG from '../assets/project.svg'

export default defineComponent({
  name: 'ProjectListItem',
  props: {
    project: { type: Object as PropType<Project>, required: false },
  },
  setup () {
    return {
      ProjectSVG,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.project-item {
  background: #fff;
  padding: 30px 50px;
  border-radius: 15px;
  border: 1px solid $blue;
  width: 100%;
  color: $text-black;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $blue;
    box-shadow:
      0px 5px 15px 0px rgba(0, 0, 0, 0.03),
      0px 5px 20px 0px rgba(0, 0, 0, 0.03),
      0px 2.5px 10px 0px rgba(0, 0, 0, 0.02),
      0px 1.75px 5px 0px rgba(0, 0, 0, 0.01),
      0px 1px 3px 0px rgba(0, 0, 0, 0.01),
      0px 0.5px 1px 0px rgba(0, 0, 0, 0);
  }

  .logo {
    margin-right: 20px;

    img {
      height: 70px;
    }
  }

  .info {
    flex: 1;
  }

  .name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.user-project-item {
  background: #fff;
  padding: 30px 50px;
  border-radius: 15px;
  border: 0px dashed $blue;
  width: 100%;
  color: $text-black;
  display: flex;
  align-items: center;
  position: relative;

  &.coming-soon::after {
    content: 'Coming Soon';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 43, 57, 0.85);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    pointer-events: none; /* Allows clicks to pass through the overlay if needed */
  }

  .logo {
    margin-right: 20px;

    img {
      height: 70px;
    }
  }

  .info {
    flex: 1;
  }

  .name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
</style>
