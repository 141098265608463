<template>
  <div class="wrapper">
    <template v-if="!isLoading && dashboard">
      <filter-bar
        ref="filterBar"
        :query-id="0"
        :query-scope="dashboard.project.query_scope_default"
        :query-rows="filterRows"
        :allow-themes="false"
        :allow-concepts="false"
        :allow-operators="false"
        :allow-text="false"
        :location="location"
        :filter-warnings="[]"
        @set-query-rows="filterRows = $event"
      />
      <div class="info-bar">
        <div>
          <div v-if="isLoadingCoverage" class="coverage-stats">
            <bf-spinner />
            Updating coverage...
          </div>
          <div v-else class="coverage-stats">
            <b>{{ coverageStats.records }} of {{ coverageStats.totalRecords }} records</b>
            &nbsp;({{ getCoveragePercent(coverageStats.records, coverageStats.totalRecords) }}%)
            <span>&bull;</span>
            <b>{{ coverageStats.verbatims }} of {{ coverageStats.totalVerbatims }} verbatims</b>
            &nbsp;({{ getCoveragePercent(coverageStats.verbatims, coverageStats.totalVerbatims) }}%)
          </div>
          <div v-if="drilldown" class="back-link">
            <router-link
              :to="{
                name: 'trial-results',
                params: { dashboardId: dashboardId },
              }"
            >
              <icon name="chevron-left" color="#068ccc" :size="10" />
              BACK TO MAIN PAGE
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="widgetProps" class="content-wrapper">
        <div class="widget-overview">
          <div ref="overviewRef">
            <div
              v-for="widget in widgetList"
              :key="widget.key"
              :class="[
                'widget-link',
                {
                  active: topWidgetId === `widget_${widget.key}`,
                },
              ]"
              @click="scrollToWidget(`widget_${widget.key}`)"
            >
              <img :src="WidgetIcons[widget.key]" :alt="widget.label" />
              <span>
                {{ widget.label }}
              </span>
            </div>
          </div>
          <div class="marker" :style="{ top: `${overviewMarkerOffset}px` }" />
          <div class="cta">
            <p>Ready to transform your approach to understanding customer feedback?</p>
            <p>
              <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert"
                >Schedule a call now &nbsp;→</a
              >
            </p>
          </div>
        </div>
        <div class="widget-wrapper">
          <div v-if="drilldown" class="drill-down-title">
            {{ drilldownQuery?.name }}
          </div>
          <div ref="scrollRef" class="widget-scroll">
            <div
              v-for="widget in widgetList"
              :id="`widget_${widget.key}`"
              :key="widget.key"
              ref="widgetWrapperRefs"
              class="widget"
            >
              <component
                :is="widget.component"
                ref="widgetRefs"
                v-bind="widgetProps[widget.key]"
                v-on="widgetEvents[widget.key]"
              />
            </div>
            <p>
              Ready to transform your approach to understanding customer feedback?
              <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert">Schedule a call</a> now.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="hasErrored">
      <div class="error-message">
        <h2>Something went wrong</h2>
        <p>There was an error loading this results page.</p>
      </div>
    </template>
    <template v-else>
      <div class="loading-wrapper">
        <bf-spinner />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed, reactive, provide, PropType, toRef } from 'vue'
import { isEqual } from 'lodash'

import { LOAD_ANALYSIS, LOAD_DASHBOARD } from 'src/store/types'
import { Dashboard } from 'src/types/DashboardTypes'
import { QueryLocation, QueryRow, QueryType, SavedQuery } from 'src/types/Query.types'
import FilterBar from 'src/components/project/analysis/results/ThemeBuilder/FilterBar.vue'
import {
  CoverageStats,
  Drilldown,
  fetchCoverageStats,
  FetchedData,
  useTopVisibleWidget,
  WorkbenchMode,
} from './Workbench.utils'
import { isQueryValid } from 'src/components/project/analysis/results/query/utils'
import QueryUtils from 'src/utils/query'
import {
  useWidgetList,
  useWidgetProps,
  WidgetIcons,
  useWidgetEvents,
  WidgetComponentMap,
  WidgetKey,
} from './WidgetProps'
import BfSpinner from 'src/components/Butterfly/BfSpinner/BfSpinner.vue'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import Icon from 'src/components/Icon.vue'
import { applyToggledSegmentsToQueryRows } from 'src/types/utils'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TrialWorkbench',
  components: {
    FilterBar,
    BfSpinner,
    Icon,
  },
  props: {
    dashboardId: { type: Number, required: true },
    drilldown: {
      type: [String, Boolean] as PropType<Drilldown>,
      default: false,
    },
    drilldownId: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup (props) {
    const store = useStore()

    // Getters
    const dashboard = computed(() => store.getters.currentDashboard as Dashboard)
    const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])
    const featureFlags = computed(() => store.getters.featureFlags as Record<string, boolean>)
    const expandedSavedQueries = computed(() => store.getters.expandedSavedQueries as SavedQuery[])
    const expandedThemeGroups = computed(() => store.getters.expandedThemeGroups as ExpandedGroup[])
    const dateFields = computed(() => store.getters.dateFields as SchemaColumn[])

    provide('featureFlags', featureFlags)

    // Component refs
    const scrollRef = ref<HTMLDivElement>()
    const overviewRef = ref<HTMLDivElement>()
    const widgetWrapperRefs = ref<HTMLDivElement[]>([])
    const widgetRefs = ref<InstanceType<WidgetComponentMap[WidgetKey]>[]>([])

    // State
    const drilldownType = toRef(props, 'drilldown')
    const isLoading = ref(true)
    const hasErrored = ref(false)
    const isLoadingCoverage = ref(true)
    const filterRows = ref<QueryRow[]>([])
    const fetchedData = reactive<FetchedData>({})
    const coverageStats = ref<CoverageStats>({
      records: 0,
      totalRecords: 0,
      verbatims: 0,
      totalVerbatims: 0,
    })

    const baseQuery = computed<QueryType>(() => {
      const query = drilldownQuery.value?.query_value
      return {
        type: 'match_all',
        includes: [{ type: 'all_data' }, query as QueryType].filter(Boolean),
      }
    })

    const drilldownQuery = computed<SavedQuery | ExpandedGroup | null>(() => {
      if (props.drilldown === 'theme') {
        const id = Number(props.drilldownId)
        return expandedSavedQueries.value.find((q) => q.id === id) ?? null
      }
      if (props.drilldown === 'theme_group') {
        const id = Number(props.drilldownId)
        return expandedThemeGroups.value.find((q) => q.id === id) ?? null
      }
      return null
    })

    const workbenchMode = computed<WorkbenchMode>(() => {
      if (drilldownQuery.value) {
        return WorkbenchMode.TrialDrilldown
      }
      return WorkbenchMode.TrialOverview
    })

    const validFilterRows = computed(() => {
      if (filterRows.value.length && !isQueryValid(filterRows.value)) return []
      return QueryUtils.convertBotanicQueriesToDashboardFilters(filterRows.value)
    })

    // Sidebar overview marker
    const { topWidgetId } = useTopVisibleWidget(scrollRef, widgetWrapperRefs)
    const overviewMarkerOffset = computed<number>(() => {
      if (!topWidgetId.value || !overviewRef.value) return 0
      const topId = topWidgetId.value
      const topIndex = widgetWrapperRefs.value.findIndex((el) => el.id === topId)
      if (topIndex === -1) return 0
      const linkRef = overviewRef.value.children[topIndex] as HTMLDivElement
      const containerTop = overviewRef.value.getBoundingClientRect().top
      const widgetTop = linkRef.getBoundingClientRect().top
      return widgetTop - containerTop
    })

    // Add or remove filter rows by field and segment
    const toggleFilter = (field: string, segment: string) => {
      const filter = [{ field, segment }]

      if (field === 'All Segments') {
        const values = segment.split(':')
        filter[0].field = values[0].trim()
        filter[0].segment = values[1].trim()
      }

      const rows = applyToggledSegmentsToQueryRows(filter, filterRows.value)
      filterRows.value = rows
    }

    // Widget data
    const { widgetList } = useWidgetList(workbenchMode)
    const { widgetProps } = useWidgetProps(
      isLoading,
      drilldownType,
      baseQuery,
      drilldownQuery,
      validFilterRows,
      fetchedData,
    )
    const { widgetEvents } = useWidgetEvents(validFilterRows, drilldownQuery, fetchedData, toggleFilter)

    const scrollToWidget = (id: string) => {
      const widget = widgetWrapperRefs.value.find((el) => el.id === id)
      if (widget && scrollRef.value) {
        widget.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }

    // Convert coverage float to percentage
    const getCoveragePercent = (numerator: number, denominator: number): number => {
      return Number(Math.round((numerator / Math.max(1, denominator)) * 100).toFixed(2))
    }

    const loadWorkbench = async () => {
      isLoading.value = true
      try {
        await store.dispatch(LOAD_DASHBOARD, {
          dashboardId: props.dashboardId,
          rethrowErrors: true,
          loadThemes: false,
          loadConfig: true,
          isViewer: true,
        })
        await store.dispatch(LOAD_ANALYSIS, {
          analysisId: dashboard.value.analysis.id,
          projectId: dashboard.value.project.id,
        })
        await updateCoverage()
      } catch (error) {
        console.error(error)
        hasErrored.value = true
      } finally {
        isLoading.value = false
      }
    }

    // Call fetchData method on all widgets
    const refreshWidgets = () => {
      widgetRefs.value.forEach((widget, i) => {
        if (widget.fetchData) {
          widget.fetchData()
        } else {
          console.error(`${widgetList.value[i].key} widget missing fetchData method.`)
        }
      })
    }

    const updateCoverage = async () => {
      isLoadingCoverage.value = true
      coverageStats.value = await fetchCoverageStats(
        baseQuery.value,
        validFilterRows.value,
        savedQueries.value,
        dateFields.value.map((field) => field.name),
        dashboard.value,
      )
      isLoadingCoverage.value = false
    }

    watch(validFilterRows, (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        refreshWidgets()
      }
    })

    watch(drilldownType, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        loadWorkbench()
      }
    })

    watch(filterRows, async () => {
      await updateCoverage()
    })

    onMounted(async () => {
      await loadWorkbench()
    })

    return {
      isLoading,
      isLoadingCoverage,
      dashboard,
      filterRows,
      location: QueryLocation.TrialWorkbench,
      coverageStats,
      getCoveragePercent,
      widgetList,
      WidgetIcons,
      widgetProps,
      widgetEvents,
      scrollRef,
      widgetWrapperRefs,
      scrollToWidget,
      overviewRef,
      overviewMarkerOffset,
      topWidgetId,
      hasErrored,
      widgetRefs,
      drilldownQuery,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.wrapper {
  height: 100vh;
  padding: 30px 30px 0;
  color: $text-black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.coverage-stats {
  font-size: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  :deep(.bf-spinner-container) {
    height: 20px;
    .bf-spinner {
      width: 13px !important;
      height: 13px !important;
      border-width: 2px !important;
      margin-right: 8px;
    }
  }

  span {
    margin: 0 10px;
    font-size: 8px;
    vertical-align: top;
  }
}

.content-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1;

  .widget-overview {
    margin-right: 30px;
    position: relative;
    white-space: nowrap;

    > div:first-child {
      border-left: 2px solid $grey-mid-light;
      padding-left: 20px;
    }

    .marker {
      position: absolute;
      left: 0;
      width: 4px;
      height: 25px;
      background-color: $blue;
      transition: top 0.3s;
    }

    .widget-link {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }

      &:hover {
        span {
          color: $text-black;
        }
      }

      img {
        $size: 25px;
        width: $size;
        height: $size;
        margin-right: 13px;
        filter: grayscale(1);
      }

      &.active {
        img {
          filter: grayscale(0);
        }
        span {
          color: $text-black;
        }
      }

      span {
        font-size: 16px;
        color: $grey-mid;
      }
    }

    .cta {
      margin-top: 20px;
      background-color: $white;
      border: 1px $green solid;
      border-radius: 5px;
      padding: 20px;
      text-align: center;
      line-height: 24px;
      font-size: 16px;
      max-width: 300px;
      box-shadow:
        0px 0px 3px 0px rgba(0, 0, 0, 0.03),
        0px 3px 5px 0px rgba(0, 0, 0, 0.05),
        0px 1.7px 2.1px 0px rgba(0, 0, 0, 0.03),
        0px 0.8px 1.1px 0px rgba(0, 0, 0, 0.03),
        0px 0.5px 0.6px 0px rgba(0, 0, 0, 0.02),
        0px 0.3px 0.3px 0px rgba(0, 0, 0, 0.01),
        0px 0.1px 0.1px 0px rgba(0, 0, 0, 0.01);
      white-space: wrap;

      a {
        color: $green;
        font-weight: bold;
      }
    }
  }

  .widget-wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;

    .widget-scroll {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
    }

    .drill-down-title {
      font-size: 30px;
      margin-bottom: 20px;
      padding-top: 2px;
      line-height: 30px;
    }

    .widget {
      margin-bottom: 30px;
      max-width: 1200px;
      width: 100%;
    }

    > p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}

.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $text-grey;

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
  }
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info-bar {
  margin: 15px 0 20px;
  display: flex;

  > div:nth-child(1) {
    flex: 1;
    margin-right: 20px;
  }

  > div:nth-child(2) {
    overflow: hidden;
  }
}

.back-link {
  color: $blue;
  font-size: 12px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  font-weight: bold;

  :deep(.icon-wrapper) {
    position: relative;
    top: 0.5px;
  }
}
</style>
