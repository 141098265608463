import * as types from 'src/store/types'

import Site from 'src/api/site'
import router from 'src/router/index'
import sites from './sites/sites'
import Project from 'src/api/project'
import { Auth0 } from 'src/plugins/auth0'
import { Store } from 'vuex'
import { Nullable } from 'src/types/utils'

export interface Site {
  domain: string
  site_name: string
  user_type: string
  default_sentiment_engine: string
  ai_use: boolean
}

const state = {
  // The env of our application
  env: process.env.APP_ENV,

  // Base URL to use for all backend requests
  baseUrl: null,

  // The currently selected site
  site: null as Site | null,

  // The list of sites the current user can access
  sites: undefined as Nullable<string[]>,

  // Is the current user a developer
  isDev: false,

  ...sites.state
}

export type AppState = typeof state

const mutations: Record<string, (state: AppState, ...args: any[]) => any> = {
  [types.SET_BASE_URL] (state, url) {
    state.baseUrl = url
  },

  [types.SET_SITE] (state, site) {
    state.site = site
  },

  [types.CLEAR_SITE] (state) {
    state.site = null
  },

  [types.SET_SITES] (state, sites) {
    state.sites = sites
  },

  [types.CLEAR_SITES] (state) {
    state.sites = []
    // FIXME: need to unify the sites stuff & get rid of cruft like this
    state.approved_domains = {
      domains: null,
      loading: false,
      error: null
    }
  },
  [types.SET_ISDEV] (state, isDev) {
    state.isDev = isDev
  },
  ...sites.mutations
}

const actions: Record<string, (store: Store<AppState>, ...args: any[]) => any> = {
  async [types.POLL_BACKEND] ({ dispatch, getters }): Promise<void> {

    // Ensure Auth0 tokens are up to date before making any polling request.
    // It is possible that if a tab has been asleep, the first setInterval hit
    // is this polling action and not the refreshToken timer.
    // In this case we should make sure the tokens are up to date.
    await Auth0.refreshTokens()

    // Feature flags depend on (user, project, site) for application.
    // This polling call is started shortly after login so we would
    // like to first obtain the user and project
    // before fetching feature flags.
    if (getters.loggedIn) {
      dispatch(types.FETCH_ANNOUNCEMENTS)
      let users = dispatch(types.FETCH_USER)
      if (getters.currentSite) {
        await dispatch(types.FETCH_PROJECTS)
        await dispatch(types.FETCH_FEATURE_FLAGS)

        // Reload currentAnalysis when it's been modified
        if (getters.currentDashboard || (getters.currentProject && getters.currentAnalysis)) {
          const projectId = getters.currentProject?.id ?? getters.currentDashboard.project.id
          const analysisId = getters.currentAnalysis?.id ?? getters.currentDashboard.analysis.id
          const currentPreview = await Project.getAnalysisPreview(projectId, analysisId)
            .catch(({ status }) => {
              // This can happen if the site is changed after polling starts,
              // resulting in the Site-Name header not matching the project.
              if (status === 404) {
                dispatch(types.CLEAR_ERRORS)
              }
              // If the poll request uses an expired token this will throw an orange banner
              // don't show this because it may happen just before a user is logged out
              if (status === 401) {
                dispatch(types.CLEAR_ERRORS)
              }
            })

          if (!currentPreview) return

          // analysis.modified updates when the analysis is run.
          // analysis.topic_framework_id updates when the analysis finishes running.
          // To keep topic_framework_id in sync we must check that the status is Finished.
          const isFinished = currentPreview.status === 'Finished'
          const isOutdated = !!getters.currentAnalysis && currentPreview.modified > getters.currentAnalysis.modified

          if (isFinished && isOutdated) {
            await dispatch({
              type: types.LOAD_ANALYSIS,
              projectId,
              analysisId,
            })

            // Certain attributes such as "name" may be outdated
            if (getters.currentDashboard?.analysis.id === analysisId) {
              dispatch({
                type: types.LOAD_DASHBOARD,
                dashboardId: getters.currentDashboard.id,
                loadConfig: true,
                isViewer: getters.isViewer
              })
            }
          }
        }
      }
      await users
    }

    // Logged-in check is repeated here because a logout event could have
    // occurred while waiting for the previous dispatches to complete.
    if (getters.loggedIn) {
      await dispatch(types.FETCH_FEATURE_FLAGS)
      if (getters.currentSite) {
        dispatch(types.LOAD_SUBSCRIPTION)
      }
    }
    // ...other pollers here
  },

  async [types.FETCH_SITES] ({ commit }) {
    try {
      let response = await Site.getListOfSites()
      commit(types.SET_SITES, response.data)
    } catch (err) {
      commit(types.FAILURE, err)
    }
  },

  async [types.SELECT_SITE] ({ dispatch, commit, getters }, { domain, authRequired = true }) {
    if (getters.availableSites === undefined) {
      await dispatch(types.FETCH_SITES)
    }

    let selected_site = getters.availableSites.find((item: Site) => item.domain === domain)

    if (selected_site === undefined) {
      if (!authRequired) {
        // Authentication is not required, we can set the site even if
        // the user does not have access to it.
        commit(types.SET_SITE, {domain: domain, site_name: domain})
      }

      commit(types.CLEAR_SITE)
      // we must call router.resolve because router.currentRoute may not contain a name,
      // eg. if it is VueRouter.START_LOCATION or if the router will redirect this path
      // to the start path
      const currentRoute = router.currentRoute.value
      const resolvedRoute = router.resolve(currentRoute)
        // detect manual navigation to a site that doesn't exist or the user does not have
        // permission to access (eg. https://app.kapiche.com/doesnotexist) that will
        // redirect to the "start" route
        if (domain && !currentRoute.name && resolvedRoute?.name === 'start') {
          // forceful navigation from resolved start path, to start path with site domain for
          // displaying an error. This is a known case, we don't care about the errors so they
          // are safe to ignore.
          router.push({ name: 'start', params: { badDomain: domain  } })
        }
        else if (resolvedRoute?.name !== 'start') {
          router.push({ name: 'start' })
        }
    } else if (selected_site.disabled) {
      commit(types.CLEAR_SITE)

      const currentRoute = router.currentRoute.value
      const resolvedRoute = router.resolve(currentRoute)
      if (!currentRoute.name && resolvedRoute.name === 'start') {
        // if the user's membership has been disabled show that as a message
        router.push({ name: 'start', params: { membershipDisabled: domain  } })
      }
      else if (resolvedRoute?.name !== 'start') {
        router.push({ name: 'start' })
      }
    } else {
      commit(types.SET_SITE, selected_site)
      if (getters.currentUser) {
        if (!getters.currentUser.viewer)dispatch(types.FETCH_APPROVED_DOMAINS)
        dispatch(types.FETCH_PROJECTS)
      }
    }
  },
  ...sites.actions
}

const getters: Record<string, (state: AppState) => any> = {
  // App
  availableSites (state) { return state.sites },
  currentSite (state)  { return state.site },
  isDev (state) { return state.isDev },
  ...sites.getters
}
export default {
  state,
  mutations,
  actions,
  getters
}
