<template>
  <div class="header">
    <router-link class="logo" :to="{ name: 'trial-overview' }">
      <img src="../../assets/logo-inverted.svg" alt="Kapiche logo" />
    </router-link>
    <a href="https://kapiche.com" class="home-link"> Return to main website </a>
    <div class="right">
      <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert">
        <bf-button class="demo-button" color="green" size="mini"> SCHEDULE A CALL </bf-button>
      </a>
      <bf-button class="support-button" color="transparent" size="mini"> SUPPORT </bf-button>
      <el-dropdown v-if="currentUser" class="user-dropdown" trigger="click">
        <bf-button class="account-button" color="transparent" size="mini">
          {{ currentUser.full_name }}
          <i class="icon dropdown"></i>
        </bf-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item> Profile </el-dropdown-item>
            <el-dropdown-item> Logout </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <bf-button
        v-else
        class="account-button"
        color="transparent"
        size="mini"
      >
        LOG IN
      </bf-button> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

import { BfButton } from 'components/Butterfly'
import { useStore } from 'src/store'
import { RouterLink } from 'vue-router'
import { UserProfile } from 'src/types/UserTypes'

export default defineComponent({
  name: 'TrialHeader',
  components: {
    RouterLink,
    BfButton,
  },
  setup () {
    const store = useStore()

    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)

    return {
      currentUser,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.header {
  display: flex;
  align-items: center;
  background: $blue-dark;
  padding: 16px 30px;
  font-size: 1rem;
  width: 100vw;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .logo img {
    display: block;
    height: 30px;
  }

  .home-link {
    margin-left: 20px;
  }

  .right {
    margin-left: auto;
  }

  .demo-button {
    margin-right: 20px;
  }

  .account-button,
  .support-button {
    color: $grey-dark;
  }

  .account-button {
    padding-right: 0;
  }
}
</style>
