import HTTPRetryUtil from 'src/utils/httpretry'
import path from 'path'
import { Requirements } from 'src/types/PivotData.types'
import { DashboardFilterType } from 'src/types/DashboardFilters.types'
/**
 * Retrieves data from the chrysalis tunnel
 * @param {Object} options {projectId: Number, dashboardId: Number, chrysalisRef: String, topicId: String}
 * @param {Object} requirements request payload
 * @param {Array} filters chrysalis filters
 */

interface Params {
  projectId: number
  dashboardId?: number
  chrysalisRef: string
  topicId: number
  id?: string
}

export const fetch_pivot_data = async (params: Params, requirements: Requirements, filters: DashboardFilterType[] = []) => {
  const { projectId, dashboardId, chrysalisRef, topicId, id } = params
  const chrysalisPath = path.join(chrysalisRef, '_topics', `${topicId}`, '_pivot_multi/')

  let tunnel_path = ''
  if (dashboardId) {
    tunnel_path = path.join('projects', `${projectId}`, 'dashboards', `${dashboardId}`, 'tunnel', chrysalisPath)
  } else {
    tunnel_path = path.join('projects', `${projectId}`, 'tunnel', chrysalisPath)
  }

  return await HTTPRetryUtil.post(
    tunnel_path,
    {
      body: { ...requirements, filters },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
          id,
        }
      }
    }
  ) as object
}

export const fetch_keyphrases_data = async (
  params: Params,
  requirements: Requirements,
  filters: DashboardFilterType[] = [],
) => {
  const { projectId, dashboardId, chrysalisRef, topicId } = params
  const chrysalisPath = path.join(chrysalisRef, '_topics', `${topicId}`, 'keyphrase/search/')

  let tunnel_path = ''
  if (dashboardId) {
    tunnel_path = path.join('projects', `${projectId}`, 'dashboards', `${dashboardId}`, 'tunnel', chrysalisPath)
  } else {
    tunnel_path = path.join('projects', `${projectId}`, 'tunnel', chrysalisPath)
  }

  try {
    const response = await HTTPRetryUtil.post(tunnel_path, {
      body: { ...requirements, positive_only: true, filters },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
        },
      },
    })
    return response
  } catch (error: any) {
    return { payload: [] }
  }
}

/* Example response payload:
{
    "results": [
        {
            "group1": {
                "type": "theme",
                "field": "Theme",
                "value": "Products",
                "count": 649,
                "nps": 26.964560862865948,
                "impact": -1.0160394138294677
            },
            "group2": {
                "type": "categorical",
                "field": "NPS Category",
                "value": "Promoter",
                "count": 2577,
                "nps": 100,
                "impact": 74.07553567774562
            },
            "intersection": {
                "odds_ratio": 0.83017294162805,
                "npmi": -0.029039477411278602,
                "count": 316,
                "p_value": 0.02720637877318542,
                "impact": 4.590375610656
            }
        },
        {
            "group1": {
                "type": "theme",
                "field": "Theme",
                "value": "Products",
                "count": 649,
                "nps": 26.964560862865948,
                "impact": -1.0160394138294677
            },
            "group2": {
                "type": "categorical",
                "field": "Gender",
                "value": "Male",
                "count": 1249,
                "nps": 32.02562049639712,
                "impact": -0.5402919617213797
            },
            "intersection": {
                "odds_ratio": 0.8169696773636991,
                "npmi": -0.038165558573395424,
                "count": 145,
                "p_value": 0.044057028615114846,
                "impact": -0.3314856714941996
            }
        },
        {
            "group1": {
                "type": "theme",
                "field": "Theme",
                "value": "Products",
                "count": 649,
                "nps": 26.964560862865948,
                "impact": -1.0160394138294677
            },
            "group2": {
                "type": "numeric",
                "field": "Recommendation",
                "value": "10.0",
                "count": 1654,
                "nps": 100,
                "impact": 33.9705174873578
            },
            "intersection": {
                "odds_ratio": 0.7980195589507494,
                "npmi": -0.04150414871282149,
                "count": 192,
                "p_value": 0.013913104106996744,
                "impact": 2.7154261387223464
            }
        },
        {
            "group1": {
                "type": "theme",
                "field": "Theme",
                "value": "Store",
                "count": 929,
                "nps": 31.32400430570506,
                "impact": -0.5340555058728498
            },
            "group2": {
                "type": "categorical",
                "field": "Banner",
                "value": "SUPER STORE",
                "count": 2848,
                "nps": 34.106076571829995,
                "impact": 0.7071883388100488
            },
            "intersection": {
                "odds_ratio": 0.7417500186359266,
                "npmi": -0.04667178970801396,
                "count": 486,
                "p_value": 0.00004370013795614412,
                "impact": -0.30204084707016676
            }
        },
        {
            "group1": {
                "type": "theme",
                "field": "Theme",
                "value": "Store",
                "count": 929,
                "nps": 31.32400430570506,
                "impact": -0.5340555058728498
            },
            "group2": {
                "type": "categorical",
                "field": "Format",
                "value": "Full Line",
                "count": 1244,
                "nps": 33.1456154465004,
                "impact": -0.15477115409798614
            },
            "intersection": {
                "odds_ratio": 0.6809274320468691,
                "npmi": -0.07491997560132654,
                "count": 185,
                "p_value": 0.00001673537957652639,
                "impact": -0.19478495582469435
            }
        },
        {
            "group1": {
                "type": "theme",
                "field": "Theme",
                "value": "Products",
                "count": 649,
                "nps": 26.964560862865948,
                "impact": -1.0160394138294677
            },
            "group2": {
                "type": "numeric",
                "field": "Satisfaction",
                "value": "10.0",
                "count": 1309,
                "nps": 90.52711993888464,
                "impact": 20.826846598983877
            },
            "intersection": {
                "odds_ratio": 0.6361504369406193,
                "npmi": -0.08397584794498507,
                "count": 128,
                "p_value": 0.000013015879152259976,
                "impact": 1.5968236634168846
            }
        }
    ]
}
 */
export const fetch_correlation_data_v2 = async (params: Params, requirements: Requirements, filters: DashboardFilterType[] = []) => {
  const { projectId, dashboardId, chrysalisRef, topicId } = params
  const chrysalisPath = path.join(chrysalisRef, '_topics', `${topicId}`, '_correlations/v2/')

  let tunnel_path = ''
  if (dashboardId) {
    tunnel_path = path.join('projects', `${projectId}`, 'dashboards', `${dashboardId}`, 'tunnel', chrysalisPath)
  } else {
    tunnel_path = path.join('projects', `${projectId}`, 'tunnel', chrysalisPath)
  }

  return await HTTPRetryUtil.post(
    tunnel_path,
    {
      body: { ...requirements, filters },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
        }
      }
    }
  ) as object
}
