<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="false"
    :dev-mode="devMode"
    :has-errored="false"
    class="summary"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <a :href="CONST.widget_help_links.ai_summary" class="widget-action help" target="_blank">
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
      </div>
    </template>

    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>

    <template #header>
      {{ widgetTitle }}
    </template>

    <template #menu>
      <span class="label">AI GENERATED </span>
    </template>

    <template #devPanel>
      <div>
        <!-- Start: {{ new Date(sliceOneData.startTime || 0) }}<br />
        Done: {{ new Date(sliceOneData.doneTime || 0) }}<br />
        Elapsed: {{ ((sliceOneData.doneTime || 0) - (sliceOneData.startTime || 0)) / 1000 }} seconds<br />
        Status: {{ sliceOneData.status }}<br />
        Error: {{ sliceOneData.error }} -->
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel> </template>

    <template #content>
      <div v-if="isLoading" class="loading">
        <br />
        <bf-spinner></bf-spinner>
        <br />
        <br />
        One moment, reading your data...
        <br />
        <br />
      </div>
      <div v-else-if="Object.keys(summary_items).length > 0" class="summary-wrapper">
        <div class="summary-text">
          <p>Below are the top theme drivers for different metrics in this dataset:</p>
          <div
            v-for="metric in metricsWithData"
            :key="metric"
          >
            <p style="font-weight: 700">{{ metric }}</p>
            <ul>
              <li v-for="theme in summary_items[metric]" :key="theme.id">
                Theme
                <router-link
                  v-if="theme.isGroup"
                  :to="{
                    name: 'trial-results-drilldown-theme-group',
                    params: { dashboardId: dashboard.id, drilldownId: theme.id },
                  }"
                >
                  {{ theme.name }}
                </router-link>
                <router-link
                  v-else
                  :to="{
                    name: 'trial-results-drilldown-theme',
                    params: { dashboardId: dashboard.id, drilldownId: theme.id },
                  }"
                >
                  {{ theme.name }}
                </router-link>
                {{ theme.tagline }}
                <b>{{ formatNumber(theme.value) }}</b>
                points.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <bf-button v-else color="blue" @click="fetchWidgetData"> Generate </bf-button>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch, onMounted, computed, PropType } from 'vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-summary.svg'
import { BfButton, BfSpinner } from 'components/Butterfly'
import { Analytics } from 'src/analytics'
import { useFetchData } from 'src/components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils'
import { number } from 'src/utils/formatters'
import { isEqual, startCase } from 'lodash'
import { makeRequirements } from './AiOverviewSummary.requirements'
import { SavedQuery } from 'types/Query.types'
import { fetch_pivot_data } from 'src/store/modules/data/api'
import { PivotData } from 'src/types/widgets.types'
import { ExpandedGroup } from "src/pages/dashboard/Dashboard.utils"
import { SchemaColumn } from "src/types/SchemaTypes"
import { getScorePivotOptions } from "src/utils/score"

interface StatInfo {
  sort: 'asc' | 'desc'
  title: string
  tagline: string
}

interface ThemeValue {
  name: string
  tagline: string
  isGroup: boolean
  value: number
  id: number
}

interface TopThemesResult {
  [stat: string]: ThemeValue[]
}

const { fetch } = useFetchData()

export default defineComponent({
  components: {
    WidgetFrame,
    BfSpinner,
    BfButton,
  },
  props: {
    themes: { type: Array as PropType<(SavedQuery | ExpandedGroup)[]>, required: true },
    devMode: { type: Boolean, required: false, default: false },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    dashboard: { type: Object, default: () => null },
    site: { type: Object, default: () => null, required: false },
    mergedFilters: { type: Array, default: () => null, required: false },
    /** does this data contain NPS? */
    hasNps: { type: Boolean, required: false, default: false },
    /** does this data contain sentiment? */
    hasSentiment: { type: Boolean, required: false, default: false },
    widgetTitle: { type: String, required: false, default: 'Dataset Overview' },
    automaticFetch: { type: Boolean, required: false, default: false },
    isStaff: { type: Boolean, required: false, default: false },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
  },
  setup (props, {}) {
    const analytics = inject<Analytics>('analytics')
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const isLoading = ref(false)
    const summary_items = ref<TopThemesResult>({})
    const width = ref(300)

    const refresh = () => {
      window.location.reload()
    }

    const setChartDimensions = (w: number): void => {
      width.value = w
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      fetchWidgetData()
    }

    const formatNumber = (value: number) => {
      return number(value)
    }

    const fetchWidgetData = async () => {
      try {
        isLoading.value = true

        const stats_info: Record<string, StatInfo> = {
          'NPS Category|npsi_rto__': {
            sort: 'asc',
            title: 'NPS',
            tagline: 'is having an impact of',
          },
          'sentiment__|negative%i_rto__': {
            sort: 'desc',
            title: 'Negative Sentiment',
            tagline: 'is having an impact of',
          },
          'sentiment__|positive%i_rto__': {
            sort: 'desc',
            title: 'Positive Sentiment',
            tagline: 'is having an impact of',
          },
        }

        const scoreColumns = props.schema.filter((col) => col.type === 8)
        const scoreOptions = scoreColumns.map((col) => {
          return getScorePivotOptions(col)
        })

        // Add score fields to stats_info
        scoreOptions.forEach((option) => {
          if (option.range && option.boxVal != null) {
            const aggName = startCase(option.agg).replace("X", option.boxVal.toString())
            stats_info[`${option.name}|box%i_rto__`] = {
              sort: 'asc',
              title: `Score: ${option.name}`,
              tagline: `is reducing the ${aggName} % by`
            }
          } else {
            stats_info[`${option.name}|mean__i_rto__`] = {
              sort: 'asc',
              title: `Score: ${option.name} (${startCase(option.agg)})`,
              tagline: 'is having an impact of',
            }
          }
        })

        const requirements = makeRequirements(
          props.hasNps,
          props.hasSentiment,
          scoreOptions,
          props.themes.map((theme) => ({
            ...theme,
            name: theme.group ? `group_${theme.id}` : `theme_${theme.id}`,
          })),
          false,
        )

        const fetchParams = [
          {
            dashboardId: props.dashboard.id,
            projectId: props.dashboard.project.id,
            chrysalisRef: props.dashboard.project.chrysalis_ref,
            topicId: props.dashboard.analysis.topic_framework_id,
          },
          requirements,
          props.mergedFilters,
        ]

        const cacheKey = { fetchParams, name: 'ai-overview-summary' }
        const data = await fetch<PivotData>(cacheKey, fetch_pivot_data, fetchParams, false)
        const payload = data.payload
        const statsToConsider: string[] = []

        // Initialize an object to hold arrays of data for each stat
        const statsData: Record<string, ThemeValue[]> = {}

        // Determine which stats to consider based on the flags
        Object.keys(stats_info).forEach((stat) => {
          const pushStat = () => {
            statsToConsider.push(stat)
            // Prepare an empty array for each stat
            statsData[stat] = []
          }
          if (stat.startsWith('NPS')) {
            props.hasNps && pushStat()
          } else if (stat.startsWith('sentiment')) {
            props.hasSentiment && pushStat()
          } else {
            // Push stat if no conditions are defined
            pushStat()
          }
        })

        // Process each item in the payload
        payload.forEach((item) => {
          const groupName = item['group__']

          if (groupName === 'overall__') {
            // Skip the "overall__" group
            return
          }

          const id = Number(groupName.replace(/^(group_|theme_)/, ""))
          const isGroup = groupName.startsWith('group_')
          const name = props.themes.find((theme) => {
            return theme.id === id && theme.group === isGroup
          })!.name

          // For each stat, collect the group and its value
          statsToConsider.forEach((stat) => {
            if (item.hasOwnProperty(stat)) {
              const value = parseFloat(item[stat] as string)

              const roundedValue = formatNumber(value)

              // Skip zero or invalid values
              if (!isNaN(value) && roundedValue !== "0") {
                // Skip negative sentiment values
                if (stat.startsWith('sentiment') && value <= 0) {
                  return
                }

                // Add to the array for this stat
                statsData[stat].push({
                  name,
                  tagline: stats_info[stat].tagline,
                  isGroup,
                  value,
                  id,
                })
              }
            }
          })
        })

        // For each stat, sort the data and select the top 3 themes
        const result: TopThemesResult = {}
        statsToConsider.forEach((stat) => {
          const dataArray = statsData[stat]
          const sortDirection = stats_info[stat]?.sort || 'desc'

          // Sort according to the sort direction
          if (sortDirection === 'asc') {
            dataArray.sort((a, b) => a.value - b.value)
          } else {
            dataArray.sort((a, b) => b.value - a.value)
          }

          // Get the top 3 themes
          result[stats_info[stat]?.title || stat] = dataArray.slice(0, 3)
        })

        // Assign to the reactive variable the template uses
        summary_items.value = result
      } catch (e) {
        console.warn(`Error ${e}`)
      } finally {
        isLoading.value = false
      }
    }

    const metricsWithData = computed(() => {
      return Object.keys(summary_items.value)
        .filter((metric) =>
          summary_items.value[metric].length > 0
        )
    })

    watch(
      () => props.mergedFilters,
      (newFilters, oldFilters) => {
        if (!isEqual(oldFilters, newFilters)) {
          if (props.automaticFetch) {
            fetchWidgetData()
          } else {
            summary_items.value = {}
          }
        }
      },
      { deep: true },
    )

    onMounted(() => {
      if (props.automaticFetch) {
        fetchWidgetData()
      }
    })

    return {
      icon,
      root,
      refresh,
      contact,
      reload,
      isLoading,
      fetchWidgetData,
      summary_items,
      setChartDimensions,
      metricsWithData,
      width,
      fetchData: () => fetchWidgetData(),
      formatNumber,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/colours.sass';
@import '~assets/kapiche.sass';

$dark-orange: #dc7070;

.header-icon {
  height: 32px;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
::v-deep {
  .legend-buttons {
    display: none;
  }
  .timeline-legend-container {
    column-count: 2;
    margin-top: -6px;
    li {
      text-align: center;
      .clickable-legend {
        display: inline-flex;
      }
    }
  }
}
.error-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding-bottom: 30px;
}
.action {
  padding-top: 20px;
}
button {
  background: none;
  border: none;
  border-bottom: 2px solid $blue;
  padding: 3px 4px;

  &:hover {
    background-color: $grey-light;
  }
  &:focus {
    border: 2px solid $blue-light;
    outline: none;
  }
  // These settings are required to allow the chart to resize correctly. If you comment these out,
  // weird things happen with the sizing of the timeline.
}
.timeline-container {
  width: inherit;
  align-items: unset;
}
.summary-wrapper {
  max-width: 700px;
  padding: 20px;
  align-self: center;
}
.summary-text {
  color: $text-black;
  font-size: 16px;
  line-height: 1.7rem;
  align-self: center;
  margin-bottom: 20px;
}
.loading {
  text-align: center;
}
.label {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #8064aa;
  margin-bottom: 3px;
  text-transform: uppercase;
  align-self: center;
}
.verbatim-text {
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.6px;
}
.subheader-text {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
}
.el-collapse {
  margin-top: 20px;
}
</style>
