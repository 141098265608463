<template>
  <div>
    <bf-spinner v-if="isLoading" />
    <template v-else-if="themes.length > 0">
      <template v-for="(themeList, themeType) in [newThemes, savedThemes]">
        <div v-if="themeList.length > 0" :key="themeType" class="theme-section">
          <div class="theme-header">
            <span class="left-text">
              <template v-if="themeType === 0">
                In Progress
                <icon
                  color="#95a6ac"
                  name="info"
                  :size="14"
                  hover-message="These themes are not yet saved."
                />
              </template>
              <template v-else>
                Themes
              </template>
            </span>
            <span class="right-text">
              % of all verbatims
            </span>
          </div>
          <div
            v-for="theme in themeList"
            :key="theme.id"
            :class="{ selected: theme.id === selectedThemeID }"
            class="theme-item"
            @click="$emit('theme-clicked', theme.id)"
          >
            <div class="theme-info">
              <span class="left-text">
                <span class="theme-name">{{ theme.name }}</span>
                <template v-if="themeType !== 0">
                  <span v-if="hasChanges[theme.id] !== false" class="unsaved">
                    &dash;&nbsp;unsaved changes
                  </span>
                </template>
              </span>
              <span class="right-text">
                {{ getPercentage[theme.id] }} %
              </span>
            </div>
            <div>
              <el-progress
                :show-text="false"
                :percentage="getPercentage[theme.id]"
              />
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      No themes found.
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { SavedQuery } from 'src/types/Query.types'
import { BfSpinner } from 'components/Butterfly'
import Icon from 'src/components/Icon.vue'
import { CoverageNode } from "./ThemeBuilder.utils"

  const ThemesTab = defineComponent({
    components: {
      BfSpinner,
      Icon,
    },
    props: {
      isLoading: { type: Boolean, required: false, default: true },
      themes: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
      data: { type: Array as PropType<CoverageNode[]>, required: false, default: () => [] },
      selectedThemeID: {
        type: [String, Number, null] as PropType<null | SavedQuery["id"]>,
        default: null,
      },
      hasChanges: {
        type: Object as PropType<Record<SavedQuery["id"], boolean>>,
        required: false,
        default: () => ({}),
      },
    },
    setup (props) {
      const getPercentage = computed(() => {
        return props.themes.reduce((acc, theme) => ({
          ...acc, [theme.id]: parseFloat(((props.data.find((qs) => qs.name === theme.name)?.coverage ?? 0) * 100).toFixed(2))
        }), {} as Record<number, number>)
      })

      const savedThemes = computed(() => {
        return props.themes.filter((theme) => !theme.is_new)
      })

      const newThemes = computed(() => {
        return props.themes.filter((theme) => theme.is_new)
      })

      return {
        getPercentage,
        savedThemes,
        newThemes,
      }
    }
  })
  export default ThemesTab
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .theme-section
    margin-bottom: 20px
    &:last-child
      margin-bottom: 0

  .theme-item
    display: flex
    flex-direction: column
    color: $text-black
    cursor: pointer
    font-size: 14px
    margin-top: 16px
    &.selected
      color: $blue
      border-left: 4px solid $blue
      padding-left: 21px
      margin-left: -25px
      .theme-name
        font-weight: bold


    &:hover
      color: $blue
    .unsaved
      font-size: 14px
      color: $red

  .theme-info
    display: flex
    justify-content: space-between
    align-items: end
    margin-top: 2px
    margin-bottom: 2px
    line-height: 1.15

  .theme-header
    display: flex
    font-weight: bold
    text-transform: uppercase
    justify-content: space-between
    color: $text-black
    font-size: 12px
    margin-bottom: 10px

    .icon-wrapper
      margin-left: 3px
      position: relative
      top: 2px

  .left-text
    text-align: left
  .right-text
    text-align: right
    flex-shrink: 0
</style>
