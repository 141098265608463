<template>
  <div class="ui main text container">
    <h1 class="ui center aligned header staff-only">
      Copy Themes
    </h1>
    <VeeForm ref="form" v-slot="{ errors }">
      <div class="ui centered grid">
        <div class="row">
          <div class="column twelve wide">
            <div :class="{ invalid: errors['fromAnalysis'] }" class="ui input fluid big">
              <Field
                v-slot="{ field }"
                name="fromAnalysis"
                rules="required|integer"
              >
                <input
                  v-bind="field"
                  v-model="fromAnalysis"
                  type="text"
                  name="fromAnalysis"
                  placeholder="From Analysis ID"
                />
              </Field>
            </div>
            <a v-show="errors['fromAnalysis']" class="ui label pointing basic red">{{ errors['fromAnalysis'] }}</a>
          </div>
        </div>
        <div class="row">
          <div class="column twelve wide">
            <div :class="{ invalid: errors['toAnalysis'] }" class="ui input fluid big">
              <Field
                v-slot="{ field }"
                name="toAnalysis"
                rules="required|integer"
              >
                <input
                  v-bind="field"
                  v-model="toAnalysis"
                  type="text"
                  name="toAnalysis"
                  placeholder="To Analysis ID"
                />
              </Field>
            </div>
            <a v-show="errors['toAnalysis']" class="ui label pointing basic red">{{ errors['toAnalysis'] }}</a>
          </div>
        </div>
        <div class="row">
          <div class="column twelve wide">
            <div class="field">
              <toggle-slider
                v-model="deleteExistingQueries"
                label="Delete all pre-existing themes in To Analysis before copying?"
              >
              </toggle-slider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column twelve wide">
            <div class="ui submit primary fluid huge button" @click="doCopy">
              Copy Themes
            </div>
          </div>
        </div>
        <div v-if="hasRequestErrors" class="row">
          <div class="column twelve wide">
            <div class="ui message icon negative">
              <i class="warning sign icon"></i>
              <div class="content">
                <div class="header">
                  There was an error =(
                </div>
                <p v-for="error in serverErrors" :key="error">
                  {{ error }}
                </p>
                <p v-for="(error, index) in validationErrors" :key="index">
                  {{ error.field }}: {{ error.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="successMessage !== null" class="row">
          <div class="column twelve wide">
            <div class="ui message positive">
              <div class="header">
                Copy complete!
              </div>
              <p v-text="successMessage"></p>
            </div>
          </div>
        </div>
      </div>
    </VeeForm>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Form as VeeForm, Field } from 'vee-validate'
  import { mapGetters } from 'vuex'
  import ToggleSlider from 'src/components/widgets/ToggleSlider.vue'
  import Project from 'src/api/project'

  export default defineComponent({
    components: {
      ToggleSlider,
      VeeForm,
      Field,
    },
    data () {
      return {
        fromAnalysis: null,
        toAnalysis: null,
        deleteExistingQueries: false,
        successMessage: null
      }
    },
    computed: {
      ...mapGetters([
        'hasRequestErrors', 'serverErrors', 'validationErrors', 'featureFlags',
      ])
    },
    methods: {
      async doCopy () {
        const { valid } = await this.$refs.form.validate()
        if (valid === false) {
          return
        }

        let el = document.querySelector('div.primary.submit.button')
        el.classList.add('loading')
        this.successMessage = null
        Project.copyQueries(
          this.fromAnalysis,
          this.toAnalysis,
          this.deleteExistingQueries,
          false,
          false,
          undefined,
          false,
          true
        )
          .then((resp) => {
            el.classList.remove('loading')
            if (this.hasRequestErrors === false) {
              this.successMessage = resp.data.detail
            }
          })
          .catch(() => {
            el.classList.remove('loading')
          })
      }
    }
  })
</script>

<style lang="sass" scoped>
  .main.container
    margin-top: 40px
    h1.header
      margin-bottom: 40px
</style>
