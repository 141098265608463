import { App } from 'vue'

type Rec = Record<string, string | number | boolean>
export type ConstDef = Record<string, Rec | string | number | boolean>

export default {
  install (app: App, options: ConstDef): void {
    app.mixin({
      data () {
        return {
          CONST: options
        }
      }
    })
  }
}
