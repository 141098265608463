<template>
  <div v-if="!isAllDataQuery" class="query-row">
    <!-- Segment query -->
    <template v-if="isSegment || isAttribute">
      <div v-if="!firstRow" class="pill operator">
        and
      </div>

      <!-- Field -->
      <div v-if="isAutoTheme" class="pill-container">
        <div class="pill uneditable">
          AI Theme: {{ queryName }}
        </div>
      </div>
      <div v-else class="pill-container field-dropdown">
        <div v-truncate="50" class="pill">
          {{ query.field }}
        </div>
      </div>

      <template v-if="!isAutoTheme">
        <!-- Operator -->
        <div class="pill-container">
          <div class="pill operator">
            {{ query.operator }}
          </div>
        </div>

        <!-- Segment(s) non-date -->
        <template v-if="!isDate && !isRange">
          <div v-for="(segment, i) in pseudoValues" :key="i" class="pill-container" :data-index="i">
            <div v-truncate="50" class="pill value" :class="[segment === undefined ? 'empty' : '', pseudoValues.length === 1 ? 'only-value' : '']">
              {{ (segment || '(No Value)') }}
            </div>
            <div v-if="segment !== undefined && canOr && !isLastOr(i)" class="pill or-joiner" :class="!isLastOr(i) ? 'locked' : ''">
              or
            </div>
          </div>
        </template>

        <!-- Numerical range -->
        <template v-if="!isDate && isRange">
          <div class="pill-container">
            <div v-truncate="50" class="pill value no-or" :class="[pseudoValues[0] === undefined ? 'empty' : '', pseudoValues.length === 1 ? 'only-value' : '']">
              {{ (pseudoValues[0] || '(No Value)') }}
            </div>
          </div>
          <div class="date-range-subtext">
            to
          </div>
          <div class="pill-container">
            <div v-truncate="50" class="pill value no-or" :class="[pseudoValues[1] === undefined ? 'empty' : '', pseudoValues.length === 1 ? 'only-value' : '']">
              {{ (pseudoValues[1] || '(No Value)') }}
            </div>
          </div>
        </template>

        <!-- Date field segments -->
        <div v-if="isDate" class="pill-container">
          <div class="pill value date-value" :class="[query.values[0] === undefined ? 'empty' : '']">
            <div class="date-label">
              {{ formatDate(query.values[0]) }}
            </div>
          </div>
        </div>
        <template v-if="isDate && isRange">
          <!-- Date range end -->
          <div class="date-range-subtext">
            to
          </div>
          <div v-if="isDate" class="pill-container">
            <div class="pill value date-value" :class="[query.values[1] === undefined ? 'empty' : '']">
              <div class="date-label">
                {{ formatDate(query.values[1]) }}
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>

    <!-- Text query -->
    <template v-else>
      <!-- Operator -->
      <div class="pill-container">
        <div class="pill operator">
          <span v-if="!firstRow">and </span>
          {{ query.operator }}
        </div>
      </div>

      <!-- Value(s) -->
      <div v-for="(value, i) in pseudoValues" :key="i" class="pill-container">
        <div class="pill value" :class="[value === undefined ? 'empty' : '', isLastOr(i) ? 'no-or' : '', pseudoValues.length === 1 ? 'only-value' : '']">
          <template v-if="isQuery">
            Theme: {{ (savedQueries.find(({ id }) => id.toString() === value) || {}).name }}
          </template>
          <template v-else-if="value !== undefined">
            <i v-if="conceptColours[value]" v-truncate="50" class="icon circle" :style="'color:' + conceptColours[value]"></i>
            {{ value }}
          </template>
        </div>
        <div v-if="value && canOr && !isLastOr(i)" class="pill or-joiner" :class="!isLastOr(i) ? 'locked' : ''">
          or
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import DataUtils from 'src/utils/data'

  export default defineComponent({
    props: {
      dateFieldIndex: { type: Object, default: () => ({}) },
      conceptColours: { type: Object, required: true },
      savedQueries: { type: Array, required: true },
      firstRow: { type: Boolean, default: false },
      query: { type: Object, required: true },
      queryName: { type: String, required: false, default: '' },
    },
    computed: {
      isAutoTheme () {
        return this.query.field === 'aitopic'
      },
      isAllDataQuery () {
        // "all_data" queries are used for excludes and we don't want to display them. Don't render if we are one.
        return this.query['type'] === 'all_data'
      },
      isQuery () {
        return this.query.type === 'query'
      },
      canOr () {
        return ['is', 'is not', 'includes', 'does not include'].indexOf(this.query.operator) >= 0
      },
      isAttribute () {
        return this.query.type === 'attribute'
      },
      isDate () {
        return !!(this.dateFieldIndex && this.isSegment && this.dateFieldIndex[this.query.field])
      },
      isRange () {
        return this.query.operator.endsWith('in the range')
      },
      isSegment () {
        return this.query.type === 'segment'
      },
      // A view across query values that can include an empty pseudo-value
      pseudoValues () {
        // Check first for INVISIBLE queries, such as the "all data" query on a "does not include"
        if (this.query['type'] === 'all_data') {
          return
        }
        let values = this.query.values
        if (values && values.length === 0) {
          values = values.concat(undefined)
        }
        return values
      },
    },
    methods: {
      formatDate: DataUtils.formatDate,
      // Does the specified OR `index` represent the last one?
      isLastOr (index) {
        return index + 1 === this.query.values.length
      },
    }
  })
</script>
<style lang="sass" scoped>
  /* explicitly importing semantic here, so we know it's used here */
  @import 'semantic/dist/semantic.css'
  @import 'assets/kapiche.sass'

  /* Variables */
  $or-border: 1px solid $text-grey

  div.query-row
    background: white
    margin-top: rem(10px)
    max-width: calc(100% - 20px)
    position: relative

    div.pill-container
      display: inline-flex
    div.pill
      display: inline-block

    div.date-range-subtext
      color: $text-grey
      display: inline-block
      font-size: rem(15px)
      margin: 0 rem(10px)

    /* Base pill style */
    div.pill
      background-color: $blue
      border: 1px solid $blue
      border-radius: 3px
      color: white
      display: inline-block
      font-size: rem(15px)
      font-weight: bold
      height: rem(30px)
      margin-right: rem(10px)
      margin-top: rem(5px)
      padding: rem(4px) rem(9px)
      i.kapiche-icon-chevron-down
        font-size: rem(10px)
        padding-left: rem(10px)
      i.icon.circle
        margin-right: 0 !important
      &.uneditable
        cursor: default
        opacity: 1 !important
        .kapiche-icon-chevron-down
          display: none

      /* Pill classes */
      &.operator
        background-color: $blue-light
        border-color: $blue-light
      &.value:not(.no-or)
        margin-right: 0
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        &.empty
          background-color: $orange
          border-color: $orange
          &.only-value
            border-top-right-radius: 3px
            border-bottom-right-radius: 3px
        &.date-value
          border-top-right-radius: 3px
          border-bottom-right-radius: 3px
          position: relative
          .date-field
            cursor: pointer
            height: 100%
            left: 0
            opacity: 0
            position: absolute
            top: 0
            width: 100%
      &.or-joiner
        background: white
        border-top: $or-border
        border-right: $or-border
        border-bottom: $or-border
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        color: $text-grey
        opacity: 0.5
        &.locked
          background-color: $blue-light
          border-color: $blue-light
          color: white
          cursor: default
          margin-left: rem(1px)
          opacity: 1
        &:hover:not(.locked )
          border-color: $blue-light
          color: $blue-light
          opacity: 1
      &.other-query
        cursor: default
        &:hover
          opacity: 1
      &.remove
        background-color: white
        border-color: $text-grey
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        border-left: 0
        color: $blue
        opacity: 0.5
        padding-left: rem(13px)
        padding-right: rem(13px)
        &:hover
          border-color: $red
          color: $red
          opacity: 1
</style>
