import axios from "axios"
import axiosRetry from "axios-retry"
import { useStore } from "./store"

const baseURL = process.env.API_BASE ?? 'http://localhost:8000'

export const client = axios.create({ baseURL })

axiosRetry(client, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
})

// Append the Site-Name header to all requests
client.interceptors.request.use((config) => {
  const store = useStore()

  try {
    if (store.state.app.site?.domain) {
      config.headers['Site-Name'] = store.state.app.site?.domain
    }
  } catch (e) {
    console.error(e)
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

