import { QueryType } from 'src/types/Query.types'
import { ChrysalisFilter } from 'types/DashboardFilters.types'
import { KeysMatching } from 'src/types/utils'
import HTTPRetryUtil from 'src/utils/httpretry'
import { mergeDashboardFiltersWithBotanicQuery } from 'src/utils/query'
import ChartUtils from 'src/utils/chart'
import dayjs from 'dayjs'
import path from 'path'
import { TableChartRowType } from 'src/types/components/Charts.types'

export interface ConceptDiff {
  concept_id: number
  concept_name: string
  freq_1: number
  freq_2: number
  freq_total: number
  freq_fraction_1: number
  freq_fraction_2: number
  freq_fraction_total: number
  freq_diff: number
  freq_diff_fraction: number
  reldiff: number
}

interface FetchDataParamsQueryListFilters {
  query_list_include: Array<QueryType>
  query_list_exclude: Array<QueryType>
  // These filters are applied to both partitions A and B
  filters: Array<ChrysalisFilter>
  chrysalisRef: string
  topicId: string
  projectId: number
  queryListIncludeOperation: string,
  queryListExcludeOperation: string,
  // These filters define partition A
  filtersA: Array<ChrysalisFilter>
  // These filters define partition B
  filtersB: Array<ChrysalisFilter>
  limit: number
  sortField: keyof ConceptDiff
  sortAsc: boolean,
  minConceptFreq: number
}


export interface TooltipStats {
  label: string
  daysAgo: string
  freq_1: number
  freq_2: number
  freq_total: number
  freq_percent_1: string
  freq_percent_2: string
  freq_percent_total: string
  freq_diff: number
  freq_diff_percent: string
  reldiff: string
  customDates: Array<string | undefined>
}

type FetchResponse = {
  payload: ConceptDiff[]
}

export const fetchDataQueryListFilters = async ({ query_list_include, query_list_exclude, filters, chrysalisRef, topicId, projectId, limit, queryListIncludeOperation, queryListExcludeOperation, filtersA, filtersB, sortField, sortAsc, minConceptFreq }: FetchDataParamsQueryListFilters): Promise<FetchResponse> => {
  const chrysalisPath = path.join(chrysalisRef, '_topics', `${topicId}`, '_diff_queries_filters/')
  return await HTTPRetryUtil.post(
    `projects/${projectId}/tunnel${chrysalisPath}`, {
      body: {
        query_list_include: query_list_include,
        query_list_exclude: query_list_exclude,
        filters: filters,
        partition_filter_groups: [filtersA, filtersB],
        limit,
        list_include_operation: queryListIncludeOperation,
        list_exclude_operation: queryListExcludeOperation,
        sort_field: sortField,
        sort_asc: sortAsc,
        min_concept_freq: minConceptFreq
      },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
        }
      },
    }
  ) as FetchResponse
}


export const splitQuery = (query: Array<QueryType>, startDate: string, endDate: string, dateField: string): [Array<QueryType>, Array<QueryType>] => {
  const filter1 = [{
    field: dateField,
    op: '<',
    value: startDate,
  }]

  const filter2 = [{
    field: dateField,
    op: '>=',
    value: startDate,
  }, {
    field: dateField,
    op: '<=',
    value: endDate,
  }]

  return query.reduce((acc, query) => {
    const copyQuery = () => JSON.parse(JSON.stringify(query))
    return [
      // pass all queries with filter1 applied
      [...acc[0], mergeDashboardFiltersWithBotanicQuery(copyQuery(), filter1)],
      // pass all queries with filter2 applied
      [...acc[1], mergeDashboardFiltersWithBotanicQuery(copyQuery(), filter2)],
    ]
  }, [[], []] as [Array<QueryType>, Array<QueryType>])
}

export const getMaxDiff = (concepts: ConceptDiff[], key: KeysMatching<ConceptDiff, number>): number => {
  return Math.max(...concepts.map((c) => Math.abs(c[key])))
}

export const getDataRows = (
  concepts: ConceptDiff[],
  sortSelection: KeysMatching<ConceptDiff, number>,
  displayKey: KeysMatching<ConceptDiff, number>,
): TableChartRowType[] => {
  const maxDiff = getMaxDiff(concepts, sortSelection)

  return concepts.map((concept) => {
    const columns = [{
      label: concept.freq_diff.toString(),
      value: concept.freq_diff,
    }]

    displayKey === 'freq_diff_fraction' && columns.push({
      label: ChartUtils.formatPercent(concept.freq_diff_fraction * 100),
      value: concept.freq_diff_fraction,
    })

    displayKey === 'reldiff' && columns.push({
      label: ChartUtils.formatPercent(concept.reldiff * 100),
      value: concept.reldiff,
    })

    return {
      bars: [{ percent: (Math.abs(concept[sortSelection]) / maxDiff) * Math.sign(concept[sortSelection]) }],
      label: concept.concept_name,
      columns,
    }
  })
}

export const getDateRange = (
  fromSelection: string,
  customDateRange: Array<Date | undefined>,
): [string, string] => {
  if (fromSelection === 'Custom') {
    return [
      dayjs(customDateRange[0]).format('YYYY-MM-DDT00:00:00'),
      dayjs(customDateRange[1]).format('YYYY-MM-DDT00:00:00'),
    ]
  }

  const days = parseInt(fromSelection.match(/\d+/)?.[0] ?? '0')

  return [
    dayjs().subtract(days, 'days').startOf('day').format('YYYY-MM-DDT00:00:00'),
    dayjs().format('YYYY-MM-DDT00:00:00'),
  ]
}

export const generateCSV = (
  conceptList: ConceptDiff[],
  displaySelection: string,
): Record<string, string | number>[] => {
  return conceptList.map((concept) => {
    const data: Record<string, string | number> = {
      'Concept': concept.concept_name,
      'Freq (#) change': concept.freq_diff,
    }

    if (displaySelection === 'Frequency change') {
      data['Freq (%) change'] = ChartUtils.formatPercent(concept.freq_diff_fraction * 100)
    }

    if (displaySelection === 'Relative difference') {
      data['Relative diff'] = ChartUtils.formatPercent(concept.reldiff * 100)
    }

    return data
  })
}
