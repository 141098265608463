/**
 * All interactions with the /projects endpoints are done here.
 */
import Vue from 'vue'
import { useRouter } from 'vue-router'
import HTTPRetryUtil from 'src/utils/httpretry'
import { Analysis } from 'src/types/AnalysisTypes'
import { UserType } from 'src/types/store/AuthModule.types'
import path from 'path'
import { Dashboard } from 'src/types/DashboardTypes'
import { Results } from './api.types'
import { AppStore } from 'src/store'

const router = useRouter()
const COLUMN_INDEXED_TYPES = new Map([
  [1, 'TEXT'],
  [2, 'NUMBER'],
  [3, 'DATE'],
  [4, 'DATE_TIME'],
  [5, 'LABEL'],
  [6, 'BOOLEAN'],
  [7, 'NPS'],
  [8, 'SCORE'],
  [-1, 'IGNORE']
])

export const COLUMN_LABELED_TYPES = new Map()

for (let [key, value] of COLUMN_INDEXED_TYPES) {
  COLUMN_LABELED_TYPES.set(value, key)
}

export default {

  COLUMN_INDEXED_TYPES,
  COLUMN_LABELED_TYPES,
  REFRESH_INTERVAL: 10000,

  /**
   *  get available stopword lists.
   */
  getStopwordLists () {
    return Vue.http.get('projects/stopword-lists/')
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get paginated analyses.
   * @param pageNumber
   * @param projectId
   * @param sortBy
   */
  async getAnalyses (pageNumber: number, projectId: number, sortBy: string) {
    return await HTTPRetryUtil.retry(
      `projects/${projectId}/analysis/`, {
        params: { page: pageNumber, sort_by: sortBy },
        headers: {},
      }
    )
  },

  /**
   * Create a new analysis
   *
   * @param projectId {Number}
   * @param analysisData {Object}
   */
  createAnalysis (projectId: number, analysisData: Record<string, unknown>) {
    return Vue.http.post('projects/' + projectId + '/analysis/', analysisData)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Rerun an analysis with updated settings
   *
   * @param projectId {Number}
   * @param analysisData {Object}
   */
  rerunAnalysis (projectId: number, analysisId: number, settingsData: Record<string, any>) {
    return Vue.http.put(`projects/${projectId}/analysis/${analysisId}/`, settingsData)
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Retrieve an analysis
   *
   * @param projectId {Number}
   * @param analysisId {Number}
   */
  async fetchAnalysis (projectId: number, analysisId: number) {
    return await HTTPRetryUtil.retry(`projects/${projectId}/analysis/${analysisId}/`)
  },

  /**
   * Delete an analysis
   *
   * @param projectId {Number}
   * @param analysisId {Number}
   */
  deleteAnalysis (projectId: number, analysisId: number) {
    return Vue.http.delete(`projects/${projectId}/analysis/${analysisId}/`)
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Clone an analysis
   *
   * @param projectId {Number}
   * @param analysisId {Number}
   */
  async cloneAnalysis (projectId: number, analysisId: number) {
    return Vue.http.post(`projects/${projectId}/analysis/${analysisId}/clone/`)
  },
  /**
   * Get a paginated list of all data files for a project. If the requested
   * page number is empty, return the last non-empty page. If all pages are
   * empty, return an empty page 1.
   *
   * @param pageNumber {Number}
   * @param projectId {Number}
   * @param sortBy {String}
   * @param pageSize {Number}
   * @returns {*}
   */
  async getDataFiles (pageNumber: number, projectId: number, sortBy: string, pageSize = 4) {
    if (!projectId) return
    return await HTTPRetryUtil.retry(
      `projects/${projectId}/data_files/`, {
        params: { page: pageNumber, sort_by: sortBy, page_size: pageSize, get_last_page_if_empty: true },
        headers: {},
      }
    )
  },

  async getStructuredDataFields (projectId: number) {
    return await HTTPRetryUtil.retry(`projects/${projectId}/structured-data-fields/`)
  },

  async getStructuredDataSegments (projectId: number, field: string) {
    return await HTTPRetryUtil.retry(
      `projects/${projectId}/structured-data-segments/`, {
        params: { field },
        headers: {},
      }
    )
  },

  /**
   * Upload a data file.
   *
   * @param data {Object}
   * @param progressFn {Function}
   * @returns {Promise}
   */
  uploadFile (data: Record<string, any>, progressFn: () => void, beforeFn: () => void) {
    return Vue.http.post('projects/data_files/upload/', data, {
      progress: progressFn,
      before: beforeFn
    })
    .then((response) => Promise.resolve(response.json()), (error) => {
      // Check if the error response has a status code of 401
      if (error.status === 401) {
        // Redirect to the login page
        router.push('login')
      }
      // Reject the promise with the error
      return Promise.reject(error)
    })
  },

  /** INTEGRATIONS CALLS **/
  /**
   * Get a list of surveys to choose from
   * @param type - The integration
   *  matches a specific request - this can avoid race conditions.
   * @returns {*}
   */
  async getSurveyList (type: string) {
    let url = `projects/integrations/${type}/surveys/`
    return await HTTPRetryUtil.retry(url)
  },

  /**
   * Get metadata for a given survey id
   * @param data
   * @returns {*}
   */
  getSurveyMetadata (type: string, surveyId: number) {
    return Vue.http.get(`projects/integrations/${type}/survey-metadata/${surveyId}/`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Create a new integration for a given integrations provider
   * @param data
   * @returns {*}
   */
  createNewIntegration (data: Record<string, any>, headers = {}) {
    return Vue.http.post('projects/integrations/', data, { headers: headers})
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Create a new Survey Monkey integration through the OAuth 2.0 flow.
   * https://developer.surveymonkey.com/api/v3/?python#oauth-2-0-flow
   * @param data - needs to contain, access_token and site_id.
   */
  async createSurveyMonkeyIntegration (data: Record<string, any>) {
    const headers = {'Site-Name': data.site_name}
    return await Vue.http.post('surveymonkey/', data, { headers: headers })
  },

  /**
   * List all integrations tied to a site
   * @param
   * @returns {*}
   */
  getAllIntegrations () {
    return Vue.http.get('projects/integrations/')
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

    /**
   * Update an integration based on django mixin update
   * @param data
   * @returns {*}
   */
  updateIntegration (data: Record<string, any>) {
    return Vue.http.put(`projects/integrations/${data.type}/`, data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

    /**
   * Delete an integration for a given provider
   * @param data
   * @returns {*}
   */
  deleteIntegration (type: string) {
    return Vue.http.delete(`projects/integrations/${type}/`)
      .then((response) => Promise.resolve(response), (error) => Promise.reject(error))
  },

  /**
   * Count the number of projects linked to a given integration
   * @param type
   * @returns {*}
   */
  countIntegrationProjects (type: string) {
    return Vue.http.get(`projects/integrations/${type}/projects/`)
      .then((response) => Promise.resolve(response), (error) => Promise.reject(error))
  },

  /**
   * Get an integration by type assosciated with a given site
   * @param type
   * @returns {*}
   */
  getIntegration (type: string) {
    return Vue.http.get(`projects/integrations/${type}`)
      .then((response) => Promise.resolve(response), (error) => Promise.reject(error))
  },

  /**
   * Create a new surveydata export
   * @param type {String} the integration provider
   * @param data {Object} the data required for the provider
   * @returns {Promise}
   */
  createIntegrationExport (type: string, data: Record<string, any>) {
    return Vue.http.post(`projects/integrations/${type}/responses/`, data)
      .then((response) => Promise.resolve(response), (error) => Promise.reject(error))
  },

  /**
   * Return a file from the blob store as a string
   *
   * @param dataFileId
   * @returns {Promise}
   */
  async getFileFromBlobStore (dataFileId: number) {
    return await HTTPRetryUtil.retry(`projects/data_files/blob/${dataFileId}/`)
  },

  /**
   * Fetch status of a data file.
   *
   * @param fileId {Number}
   * @returns {Promise}
   */
  fetchFileStatus (fileId: number, beforeFn: () => any) {
    return Vue.http.get(`projects/data_files/uploads/${fileId}/`, { before: beforeFn })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete the given data file
   *
   * @param fileId {Number}
   * @param updateAnalyses {Boolean}
   * @returns {Promise}
   */
  deleteDataFile (fileId: number, updateAnalyses = false) {
    const config = {params: {update_analyses: updateAnalyses}}
    return Vue.http.delete(`projects/data_files/uploads/${fileId}/`, config)
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Index data files.
   */
  indexDataFiles (projectId: number, files: number[], mapping: Record<string, string>) {
    return Vue.http.post(`projects/${projectId}/index/`, {
      data_files: files,
      mapping: mapping,
    })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get a paginated list of all projects related to this user
   */
  async getProjects (pageNumber = 1, filterName = '', sortBy = '-modified', filterLabels = []) {
    return await HTTPRetryUtil.retry(
      'projects/', {
        params: { page: pageNumber, filter_name: filterName, sort_by: sortBy, labels: filterLabels.toString() },
        headers: {},
      }
    )
  },

  /**
   * Search for a project by string
   * @param search_string
   * @returns {*|Promise.<T>}
   */
  searchProjects (search_string: string) {
    return Vue.http.post('projects/search/', { search_string })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get an individual project by ID
   * @param id
   * @returns {*}
   */
  getProject (id: number) {
    return Vue.http.get(`projects/${id}/`)
    .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

    /**
   * Check a project name exists for site
   */
  checkProjectName (data: Record<string, any>) {
    return Vue.http.get('projects/available/', {params: { project_name: data } } )
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Create a new project
   */
  createProject (data: Record<string, any>) {
    return Vue.http.post('projects/', data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Update a project
   */
  updateProject (projectId: number, data: Record<string, any>) {
    return Vue.http.patch('projects/' + projectId + '/', data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },
  /**
   * Update a project with data from an integration
   */
  updateProjectFromIntegration (projectId: number, data: Record<string, any>) {
    return Vue.http.put('projects/' + projectId + '/integration/', data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  async createTransformations (projectId: number, data: {
    reference_field: string
    name: string
    schema_type: number
    transformations: {
      from: string,
      to: string,
    }[]
  }[]) {
    let result = await Vue.http.post('projects/' + projectId + '/transformations/', data)
    return await result.json()
  },

  /**
   * Get the number, if any, of new responses for a given integration since last update
   *
   * @param projectId
   */
  getNewResponsesFromIntegration (projectId: number) {
    return Vue.http.get('projects/' + projectId + '/integration/')
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },
  /**
   * Update the auto add files setting
   */
  updateAutoAddFilesSetting (projectId: number, data: Record<string, any>) {
    return Vue.http.patch(
      'projects/' + projectId + '/integration/autoaddfiles/', data
    )
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete a project
   *
   * @param projectId {Number}
   */
  deleteProject (projectId: number) {
    return Vue.http.delete('projects/' + projectId + '/')
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Fetch a single project
   *
   * @param projectId {Number}
   * @returns {Promise}
   */
  async fetchProject (projectId: number) {
    return await HTTPRetryUtil.retry('projects/' + projectId + '/')
  },

  /**
   * Add user to project
   */
  addUserToProject (projectId: number, user: UserType) {
    return Vue.http.post('projects/' + projectId + '/users/', { user })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Remove user from project
   */
  removeUserFromProject (projectId: number, userId: number) {
    return Vue.http.delete(`projects/${projectId}/users/${userId}/`)
      .then(() => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Get a list of members in a project through projectaccess objects
   */
  async getProjectMembers (projectId: number) {
    return await HTTPRetryUtil.retry('projects/' + projectId + '/users/')
  },

  /**
   * Update display attributes for an analysis.
   *
   * @param attributes The map of display attributes
   * @returns {Promise}
   */
  updateAnalysisDisplayAttributes (store: AppStore, attributes: Record<string, any>, analysis?: Analysis) {
    let params: Record<string, any> = {}
    if (attributes.numClusters) {
      params.num_clusters_displayed = attributes.numClusters
    }
    if (attributes.numConcepts) {
      params.num_concepts_displayed = attributes.numConcepts
    }
    if (attributes.stale) {
      params.stale = attributes.stale
    }
    if (attributes.ignored_concepts) {
      params.ignored_concepts = attributes.ignored_concepts
    }

    let currentAnalysis = analysis ?? store.getters.currentAnalysis

    return Vue.http.post(`projects/${currentAnalysis.project}/analysis/${currentAnalysis.id}/update_display_attributes/`, params)
  },

  /**
   * Modify analysis. Can be used for things like changing the name or
   * description of an analysis, etc.
   */
  modifyAnalysis (analysis: Analysis, params: Record<string, any>) {
    return Vue.http.post(
      `projects/${analysis.project}/analysis/${analysis.id}/update_display_attributes/`,
      params
    )
  },

  /**
   * Create a new Dashboard
   */
  createDashboard (params: Record<string, any>) {
    return Vue.http.post(
      `projects/dashboards/`,
      params
    )
  },

  /**
   * Get all dashboards for a project
   */
  getDashboards (): PromiseLike<Results<Dashboard[]>> {
    return Vue.http.get(`projects/dashboards/`)
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error),
      )
  },

  createEmailDigest (dashboardId: number, params: Record<string, any>) {
    return Vue.http.post(
      `projects/dashboards/${dashboardId}/digest/`,
      params
    )
  },

  updateEmailDigest (dashboardId: number, digestId: number, params: Record<string, any>) {
    return Vue.http.patch(
      `projects/dashboards/${dashboardId}/digest/${digestId}/`,
      params
    )
  },

  sendTestEmail (dashboardId: number, params: Record<string, any>, emailDigestId: number) {
    const suffix = emailDigestId === undefined? '' : `${emailDigestId}/`
    return Vue.http.post(
      `projects/dashboards/${dashboardId}/digest/send_test/${suffix}`,
      params
    )
  },

  listEmailDigests (dashboardId: number) {
    return Vue.http.get(
      `projects/dashboards/${dashboardId}/digest/`
    )
  },

  deleteEmailDigest (dashboardId: number, digestId: number) {
    return Vue.http.delete(
      `projects/dashboards/${dashboardId}/digest/${digestId}/`
    )
  },

  getEmailDigestPreview (dashboardId: number, params: Record<string, any>, emailDigestId: number) {
    const suffix = emailDigestId === undefined? '' : `${emailDigestId}/`
    return Vue.http.post(
      `projects/dashboards/${dashboardId}/digest/html_preview/${suffix}`,
      params
    )
  },

  /**
   * Return the JSON for a Dashboard by ID
   */
  getDashboard (dashboardId: number) {
    return Vue.http.get(`projects/dashboards/${dashboardId}/`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Return the JSON for a Dashboard by ID
   */
  getDashboardV2 (dashboardId: number, analysisId: number, projectId: number) {
    return Vue.http.get(`projects/${projectId}/analysis/${analysisId}/dashboards/${dashboardId}/`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Return the list of dashboards this user has access to.
   */
  async getAllDashboards () {
    return await HTTPRetryUtil.retry(`projects/dashboards/access/`)
  },

  /**
   * Return the list of dashboards belonging to the
   * specified site that this user has access to.
   */
  async getAllDashboardsForSite (siteName: string) {
    const headers = { 'Site-Name': siteName }
    return await Vue.http.get(`projects/dashboards/access/`, { headers })
  },

  /**
   * Update a dashboard
   */
  updateDashboard (dashboard: Record<string, any>) {
    return Vue.http.put(`projects/dashboards/${dashboard.id}/`, dashboard)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete a dashboard
   */
   deleteDashboard (dashboardId: number) {
    return Vue.http.delete(`projects/dashboards/${dashboardId}/`)
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Get list of users for a Dashboard
   */
  getDashboardMembers (dashboardId: number) {
    return Vue.http.get(`projects/dashboards/${dashboardId}/members/`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Add user to Dashboard
   */
  addUserToDashboard (dashboardId: number, user: Record<string, any>, link: string) {
    return Vue.http.post(`projects/dashboards/${dashboardId}/members/`, { user, link })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Create invite to Dashboard
   */
  inviteToDashboard (dashboardId: number, email: string, link: string) {
    return Vue.http.post(`projects/dashboards/invitations/`, { email, link, dashboard: dashboardId })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get Dashboard invitation
   */
  getDashboardInvitation (invitationId: number)  {
    return Vue.http.get(`projects/dashboards/invitations/${invitationId}/`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Accept Dashboard invitation
   */
  acceptDashboardInvitation (invitationId: number, data: Record<string, any>, use_v2=false) {
    const url = `projects/dashboards/invitations/${invitationId}/accept/${use_v2 ? 'v2/' : ''}`
    return Vue.http.post(url, data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete user of a Dashboard
   */
  deleteUserFromDashboard (dashboardId: number, userId: number) {
    return Vue.http.delete(`projects/dashboards/${dashboardId}/members/${userId}/`)
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Get list of of potential users for a Dashboard
   */
  searchDashboardUsers (dashboardId: number, query: Record<string, any>) {
    return Vue.http.get(`projects/dashboards/${dashboardId}/users/`, { params: { query } })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  async getAnalysisPreview (projectId: number, analysisId: number) {
    return Vue.http.get(`projects/${projectId}/analysis_preview/${analysisId}/`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Copy themes between two analysis
   *
   * @param fromAnalysisId {Number} the ID of the analysis to copy from
   * @param toAnalysisId {Number} the ID of the analysis to copy to
   * @param deleteExistingQueries {Boolean} whether to delete all existing queries from toAnalysis first
   * @param skipStructured {Boolean} whether to skip copying queries with structured data parameters
   * @param includeQueries {Array} (optional) list of query ids to include
   */
  async copyQueries (
    fromAnalysisId: number,
    toAnalysisId: number,
    deleteExistingQueries: boolean,
    addToDashboards: boolean,
    skipStructured = false,
    includeQueries: number[] | undefined,
    overwriteExistingQueries: boolean,
    copyThemeGroups = true,
  ) {
    return await Vue.http.post(`projects/copy-queries/`, {
      'from_analysis': fromAnalysisId,
      'to_analysis': toAnalysisId,
      'delete_existing_queries': deleteExistingQueries,
      'skip_structured': skipStructured,
      'add_to_dashboards': addToDashboards,
      'include_queries': includeQueries,
      'overwrite_existing_queries': overwriteExistingQueries,
      'copy_theme_groups': copyThemeGroups,
    })
  },

  /**
   * Get if the auto add files setting is enabled (True or False)
   */
  async getAutoAddFilesSetting (projectId: number) {
    return await HTTPRetryUtil.retry(
      'projects/' + projectId + '/integration/autoaddfiles/'
    )
  },

  /**
   * List all notifications tied to a site
   */
  async getAllNotifications () {
    return await HTTPRetryUtil.retry(`projects/notifications/`)
  },

  /**
   * Create a notification
   */
  createNotification (data: Record<string, unknown>): PromiseLike<unknown> {
    return Vue.http.post(`projects/notifications/`, data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Update an notification based on django mixin update
   */
  updateNotification (data: Record<string, unknown>): PromiseLike<unknown> {
    return Vue.http.put(`projects/notifications/${data.type}/`, data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete an Notification for a given provider
   */
  deleteNotification (type: string): PromiseLike<unknown> {
    return Vue.http.delete(`projects/notifications/${type}/`)
      .then((response) => Promise.resolve(response), (error) => Promise.reject(error))
  },

  /**
   * Get an Notification by type assosciated with a given site
   */
  async getNotification (type: string) {
    return await HTTPRetryUtil.retry(`projects/notifications/${type}`)
  },


  /**
   * Get a list of objects for a path in the S3 bucket
   */
  getS3Objects () {
    return Vue.http.get('projects/integrations/s3/objects/')
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get a list of objects for a path in the GCS bucket
   */
  async getGCSObjects () {
    return await HTTPRetryUtil.retry(`projects/integrations/gcs/objects/`)
  },

  /**
   * Change the sentiment label of a single frame/verbatim
   */
  async changeSentiment (chrysalisRef: string,
                         projectId: number,
                         frame_id: number,
                         new_sentiment: 'positive' | 'negative' | 'mixed' | 'neutral'): Promise<unknown> {
    const data = JSON.stringify({
      frame_id: frame_id,
      new_sentiment: new_sentiment
    })
    const chrysalisPath = path.join(chrysalisRef, '_sentiment')
    const res = await HTTPRetryUtil.put(
      path.join('projects', `${projectId}`, 'tunnel', chrysalisPath),
      data,
      {
        params: {
          invalidate_cache: true,
          chrysalisRef: chrysalisRef
        }
      }
    )
    return res
  },
}
