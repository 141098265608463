<template>
  <div class="wrapper">
    <trial-header />
    <div class="content-wrapper">
      <div v-if="isLoading" class="loading-wrapper">
        <bf-spinner />
      </div>
      <template v-else>
        <div>
          <h1>Welcome to Kapiche</h1>
          <!-- <h2>UPLOAD YOUR OWN DATA:</h2>
          <project-list-item /> -->
        </div>
        <div v-if="projects.length === 0" class="no-projects">No demo projects found.</div>
        <div v-else>
          <h2 style="margin-bottom: 5px">DEMO DATASETS:</h2>
          <p v-if="currentUser == null">Don't want to register? Explore one of our demo datasets without an account!</p>
          <div class="project-list">
            <project-list-item
              v-for="project in projects"
              :key="project.id"
              :project="project"
              @click="projectClick(project)"
            />
          </div>
        </div>
        <p>
          Ready to transform your approach to understanding customer feedback?
          <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert">Schedule a call</a> now.
        </p>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'

import TrialHeader from './Header.vue'
import ProjectListItem from './components/ProjectListItem.vue'
import { BfSpinner } from 'components/Butterfly'

import * as TrialProjectAPI from './api/project'
import { useStore } from 'src/store'
import { SET_PROJECTS } from 'src/store/types'
import { Project } from 'src/types/ProjectTypes'
import { useRouter } from 'vue-router'
import { UserProfile } from 'src/types/UserTypes'

export default defineComponent({
  name: 'TrialOverview',
  components: {
    BfSpinner,
    TrialHeader,
    ProjectListItem,
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const isLoading = ref(true)

    const projects = computed(() => store.getters.projects as Project[])
    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)

    const fetchProjects = async () => {
      isLoading.value = true
      try {
        const projects = await TrialProjectAPI.listProjects()
        store.commit(SET_PROJECTS, projects)
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const projectClick = (project: Project) => {
      const dashboardId = project.analyses[0]?.dashboard_ids?.[0]
      router.push({ name: 'trial-results', params: { dashboardId: dashboardId } })
    }

    onMounted(async () => {
      await fetchProjects()
    })

    return {
      isLoading,
      projects,
      currentUser,
      projectClick,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: $grey-light-background;
}

.content-wrapper {
  padding: 0 30px;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  > div {
    margin: 0;
    margin-bottom: 40px;
    width: 100%;
    max-width: 600px;
    min-width: 400px;
  }

  h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    color: $text-black;
    letter-spacing: 0.7px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.no-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;
  color: $text-black;
  opacity: 0.3;
}
</style>
