<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="hasErrored"
    :banner="banner"
    :masked="masked"
    class="quadrant"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <download-export-button
        ref="exportButton"
        :name="exportName+'-Quadrant'"
        :is-loading="isLoading"
        :get-el="getChartEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getSvgExportConfig"
        :make-ppt-slide="makePptSlide"
        short-name="Quadrant"
      ></download-export-button>
      <router-link
        v-if="!isZoomed && zoomToRoute"
        class="widget-action expand"
        :to="zoomToRoute"
      >
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
      <a
        :href="CONST.widget_help_links.quadrant"
        class="widget-action help"
        target="_blank"
      >
        <i class="kapiche-icon-info"></i>
      </a>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img
        class="header-icon"
        :src="icon"
        alt="Quadrant Icon"
      >
    </template>

    <!--======================== HEADING -->
    <template #header>
      Quadrant Chart
    </template>

    <!--======================== MENU -->
    <template #menu>
      <widget-menu
        :menus="menus"
        :vertical="isZoomed"
        :bound="$el"
        @onSelect="setSelection"
      />
    </template>

    <template #panel>
      <quadrant-legend
        :dots="colouredVisibleDots"
        :dots-visible="dotLimit"
        :sort-method="sortMethod"
        :sort-options="sortOptions"
        :group-label-map="groupLabelMap"
        @update-sort-method="sortMethod = $event; updateConfig()"
        @update-dots-visible="dotLimit = $event; updateConfig()"
      />
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <table class="table ui">
          <tr v-for="(group,index) in Object.keys(dataPoints)" :key="`${group}_${index}`">
            <td><strong>{{ group }}</strong></td>
            <td>{{ JSON.stringify(dataPoints[group], null,2) }}</td>
          </tr>
        </table>
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img
            class="errorIcon"
            :src="errorIcon"
            alt="widget error icon"
          />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button> or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button> if the problem persists.
        </div>
        <div v-if="userErrors" class="message">
          {{ userErrors }}
        </div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="colouredVisibleDots.length > 0" #content>
      <quadrant-chart
        v-if="!isLoading && dots.length"
        :x-axis="xAxis"
        :y-axis="yAxis"
        :quad-colors="quadrantColors"
        :dots="colouredVisibleDots.slice(0, dotLimit)"
        :dot-labels="dotLabels"
        :width="width"
        :height="height"
        :verticals="vert"
        @hover="setToolTip"
        @menu-clicked="setToolTip(null)"
      >
        <template #tool-tip>
          <quadrant-tooltip v-bind="toolTip" />
        </template>
        <template
          #interaction-menu="{ dot }"
        >
          <button
            v-if="selectedDataType === 'segment'"
            @click="addSegmentFilter(dot)"
          >
            <span>
              Add <b>{{ dot.label }}</b> as a filter
            </span>
          </button>
          <button
            @click="navigateTo(dot)"
          >
            <span>
              Drill into <b>{{ dot.label }}</b>
              <span
                v-if="selectedDataType === 'theme' && themeToGroupNameMap[+dot.id.replace('q_', '')]"
                :style="{ fontSize: 'inherit', marginLeft: '5px' }"
                class="group-tag"
              >
                [{{ themeToGroupNameMap[+dot.id.replace('q_', '')] }}]
              </span>
              <span
                v-if="selectedDataType === 'group' && groupToGroupNameMap[+dot.id.replace('group_', '')]"
                :style="{ fontSize: 'inherit', marginLeft: '5px' }"
                class="group-tag"
              >
                [{{ groupToGroupNameMap[+dot.id.replace('group_', '')] }}]
              </span>
            </span>
          </button>
        </template>
      </quadrant-chart>
      <quadrant-legend
        v-if="!isZoomed"
        :dots="colouredVisibleDots"
        :dots-visible="dotLimit"
        :sort-method="sortMethod"
        :sort-options="sortOptions"
        :group-label-map="groupLabelMap"
        @update-sort-method="sortMethod = $event; updateConfig()"
        @update-dots-visible="dotLimit = $event; updateConfig()"
      />
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { ComputedRef, PropType, computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import PptxGenJS from 'pptxgenjs'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import QuadrantTooltip from 'components/DataWidgets/QuadrantWidget/QuadrantTooltip.vue'
import QuadrantChart from 'components/charts/QuadrantChart/QuadrantChart.vue'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import icon from 'assets/img/dashboards/dash-quadrant.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { formatNPS, decimalAsPercent, number } from 'src/utils/formatters'
import ProjectAPI from 'src/api/project'
import { WidgetMenuOptions } from 'src/types/components/WidgetMenu.types'
import {
  minMax,
  dotColor,
  quadrantColors as getQuadrantColors,
  menus as getMenus,
  baseRequirements,
  rowsFromPayload,
  overallRow as getOverallRow,
  rowsToCsv,
  Limit,
  SortMethod,
} from './QuadrantWidget.utils'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { SavedQuery } from 'src/types/Query.types'
import { QuadrantChartDot } from 'src/types/components/Charts.types'
import { Analytics } from 'src/analytics'
import { ScoreColumn, schemaColToScoreCol } from 'src/utils/score'
import { SchemaColumn } from "src/types/SchemaTypes"
import QuadrantLegend from "./QuadrantLegend.vue"
import { ChrysalisFilter } from "src/types/DashboardFilters.types"

interface Tooltip {
  label: string
  records: number
  frequency: number
  nps: number
  promoters: number
  detractors: number
  passives: number
  positive: number
  negative: number
  mixed: number
  neutral: number
  aggregationLabel: string
  aggregationValue: number
}

const QuadrantWidget = defineComponent({
  components: {
    WidgetFrame,
    WidgetMenu,
    QuadrantChart,
    QuadrantTooltip,
    DownloadExportButton,
    WidgetMessagePanel,
    QuadrantLegend,
  },
  props: {
    /** for export filename */
    exportName: { type: String, required: false, default: '' },
    /** is this DataWidget in zoomed mode?  */
    isZoomed: { type: Boolean, required: false, default: false},
    /** route object for zoom button */
    zoomToRoute: { type: Object, required: false, default: null },
    /** data to render  */
    data: { type: Object, required: false, default: null },
    /** show developer tools in widget */
    devMode: { type: Boolean, required: false, default: false },
    /** list of queries for the chart */
    queries: { type: Array as PropType<SavedQuery[]>, required: false, default: ()=>[] },
    /** fields for menu */
    segmentFields: { type: Array as PropType<SchemaColumn[]>, required: false, default:()=>[] },
    sortedSegmentsPerField: { type: Object, required: false, default: ()=>null },
    /** does this data contain NPS? */
    hasNps: { type: Boolean, required: false, default: false},
    /** does this data contain sentiment? */
    hasSentiment: { type: Boolean, required: false, default: false},
    /** does this data contain numeric fields? */
    hasNumericFields: { type: Boolean, default: false, required: false },
    /** widget banner to display */
    banner: { type: Object, default: ()=>null, required: false },
    /** list of concepts queries for the chart */
    concepts: { type: Array, required: false, default: () => [] },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'quadrant'>>, required: false, default: null },
    /** route to navigate to when theme/concept is clicked on */
    toQueryRoute: { type: Object, required: false, default: null },
    toConceptRoute: { type: Object, required: false, default: null },
    themeGroups: { type: Array as PropType<ExpandedGroup[]>, required: false, default: () => [] },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    dashboardFilters: { type: Array<ChrysalisFilter>, required: false, default: [] },
  },
  setup (props, { emit }) {
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)
    const exportButton = ref<InstanceType<typeof DownloadExportButton>>()

    const analytics = inject<Analytics>('analytics')
    const featureFlags = inject<Record<string, boolean>>('featureFlags')
    const themeToGroupNameMap = inject<ComputedRef<Record<number, string>>>('themeToGroupNameMapById', computed(() => ({})))
    const groupToGroupNameMap = inject<ComputedRef<Record<number, string>>>('groupToGroupNameMapById', computed(() => ({})))
    const themeNameMap = inject<ComputedRef<Record<number, string>>>('themeNameMap', computed(() => ({})))
    const themeGroupNameMap = inject<ComputedRef<Record<number, string>>>('themeGroupNameMap', computed(() => ({})))
    const showGroupLabels = inject<boolean>('showGroupLabels', true)

    const width = ref(300)
    const height = ref(300)
    const hidden = ref<string[]>([])
    const hoveredDot = ref<number | null>(null)

    const selectedData = ref('')
    const selectedDisplay = ref('')
    const limitY = ref<Limit>(Limit.SELECTION)
    const limitX = ref<Limit>(Limit.SELECTION)

    const makePptSlide = async (pptx: PptxGenJS) => {
      const slide = pptx.addSlide()

      slide.addImage({
        data: await exportButton.value?.generateImageData('image/png'),
        sizing: {
          type: 'contain',
          x: '0%',
          y: '0%',
          w: '100%',
          h: '100%',
        }
      })
    }

    const getSvgExportConfig = () => {
      return { dims: { height: height.value, width: width.value }, css:
        `.text {
            font-weight: bold;
            color: black;
          }
          .point {
            stroke-opacity: 0.6;
            fill-opacity: 1;
            stroke-width: 1.5;
          }
          .hidden {
            display: none;
            cursor: none;
            fill-opacity: 0;
            stroke-opacity: 0;
          }
          .axis-label {
            font-weight: bold;
          }
          line.baseline-line {
            stroke-width: 2;
            stroke-opacity: 0.25;
          }
          text.baseline-line {
            font-weight: bold;
          }
    ` }
    }

    const setChartDimensions = (w: number, h: number) => {
      width.value = w
      height.value = h
    }

    const dataPoints = computed(() => {
      if (!props.data || isLoading.value) return []
      let rows = rowsFromPayload(
        props.data?.queries?.data?.payload,
        props.data?.fields?.data?.payload,
        selectedData.value,
      )
      return rows
    })

    const npsField = computed(() => {
      if (!props.hasNps) return null
      const field = props.segmentFields.find((f) =>
        f.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NPS')
      )
      return field ? field.name : null
    })

    const displayType = computed((): string => {
      if (['Detractors', 'Promoters', 'Passives', npsField.value].includes(selectedDisplay.value)) {
        return 'NPS'
      } else if (['Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment'].includes(selectedDisplay.value)) {
        return 'Sentiment'
      } else if (Object.keys(scoreColumnsMap.value).includes(selectedDisplay.value)) {
        return 'Score'
      } else {
        return 'Numerical Field'
      }
    })

    const numericalFields = computed((): string[] => {
      if (!props.segmentFields) return []
      return props.segmentFields.reduce((p, c) =>
        c.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NUMBER') ? p.concat(c.name) : p,
        [] as string[]
      )
    })

    const scoreColumnsMap = computed((): Record<string, ScoreColumn> => {
      if (!props.segmentFields) return []
      return props.schema.filter((col) => col.type === 8).reduce((map, col) => {
        const formattedCol = schemaColToScoreCol(col)
        map[`${formattedCol.name} (${formattedCol.aggregation.title})`] = formattedCol
        return map
      }, {} as Record<string, ScoreColumn>)
    })

    const hasQueries = computed((): boolean => {
      return props.queries?.length > 0
    })

    const defaultSelectedData = computed((): 'Themes' | 'Top Concepts' => {
      return hasQueries.value ? 'Themes' : 'Top Concepts'
    })

    const validatedOptions = computed((): { display: string, data: string } => {
      // Since we don't store aggregation name in dashboard config, we substitute
      // the display name to what we want in the UI.
      const scoreColumnNames = Object.values(scoreColumnsMap.value).map(col => col.name)
      let display = props.config?.options?.display
      if (display && scoreColumnNames.includes(display)) {
        const targetCol = Object.values(scoreColumnsMap.value).find((col) => col.name === display)
        display = `${targetCol?.name} (${targetCol?.aggregation.title})`
      }

      let data = props.config?.options?.data
      let scoreColOptions = Object.keys(scoreColumnsMap.value)

      const validDisplayValues = [
        npsField.value,
        'Detractors', 'Promoters', 'Passives',
        'Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment',
      ]
      .concat(numericalFields.value)
      .concat(scoreColOptions)

      if (
        !display ||
        !data ||
        !validDisplayValues.includes(display) ||
        (!props.hasNps && [npsField.value, 'Detractors', 'Promoters', 'Passives'].includes(display)) ||
        (!props.hasSentiment && display.endsWith('Sentiment')) ||
        (!props.hasNumericFields && numericalFields.value.includes(display))
      ) {
        if (props.hasNumericFields) {
          display = numericalFields.value[0]
        }
        if (props.hasSentiment) {
          display = 'Positive Sentiment'
        }
        if (props.hasNps) {
          display = npsField.value!
        }
        if (!display) {
          display = 'Themes'
        }

        data = defaultSelectedData.value
      }
      return { display, data }
    })

    const isQueryView = computed((): boolean => {
      return ['Themes', 'Top Concepts', 'Theme Groups'].includes(selectedData.value)
    })

    const isLoading = computed(() => {
      if (!validatedOptions.value || !selectedData.value) return true
      // if we are showing queries then we don't check the fields props
      if (isQueryView.value) {
        if (!props.data?.queries) return true
        return props.data.queries.status === 'fetching'
      } else {
        if (props.data?.queries && props.data?.fields) {
          return props.data.queries.status === 'fetching' ||
                 props.data.fields.status === 'fetching'
        } else {
          return true
        }
      }
    })

    const dots = computed((): QuadrantChartDot[] => {
      if (isLoading.value || !dataPoints.value || !selectedData.value) return []

      const points = dataPoints.value
        .filter((dot) =>
          (dot.group__ !== 'overall__' && selectedData.value && !isQueryView.value) ||
          (dot.label !== '(No Value)' && dot.records !== 0)  // if no value, then freq must be greater than zero
        )
        .map((point, index) => {
          if (!point.label) return null
          let label = point.label.toString()
          let id = `${point.label}_${index}`

          let match
          if (match = label.match(/^q_(\d+)$/)) {
            const qId = Number(match[1])
            const query = props.queries.find((q) => q.id === qId)
            label = query?.name || label
            id = point.label
          }
          if (match = label.match(/^group_(-?\d+)$/)) {
            const qId = Number(match[1])
            const query = props.themeGroups.find((q) => q.id === qId)
            label = query?.name || label
            id = point.label
          }

          let dot: QuadrantChartDot = {
            id,
            label,
            x: 0,
            y: point.frequency*100,
            color: '',
            size: 5,
          }

          const npsDisplay = ['Detractors', 'Promoters', 'Passives']
          const sentimentDisplay = ['Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment']
          if (selectedDisplay.value === npsField.value) {
            dot.x = point['NPS Category|nps__']
          } else if (npsDisplay.includes(selectedDisplay.value)) {
            dot.x = Number(point[`NPS Category|${selectedDisplay.value.slice(0, -1)}%__`])
          } else if (sentimentDisplay.includes(selectedDisplay.value)) {
            dot.x = Number(point[`sentiment__|${selectedDisplay.value.split(' ')[0].toLowerCase()}%__`])
          } else if (Object.keys(scoreColumnsMap.value).includes(selectedDisplay.value)) {
            const scoreField = scoreColumnsMap.value[selectedDisplay.value]
            if (["top box", "bot box"].includes(scoreField.aggregation.type)) {
              dot.x = Number(point[`${scoreField.name}|box%__`])
            } else {
              dot.x = Number(point[`aggVal|mean__`])
            }
          } else {
            // Asuming Numerical Field here.
            dot.x = Number(point[`${selectedDisplay.value}|mean__`])
          }
          return dot
        })

        return points.filter((d): d is QuadrantChartDot =>
          d != null && d.x !== null && d.x !== undefined
        )
    })

    const visibleDots = computed((): QuadrantChartDot[] => {
      return dots.value
        .filter((dot) => dot.label !== 'overall__') // we exclude this here instead of in dots() so the minMax can take it into account
        .map((dot, index)=>({
          ...dot,
          hidden: hidden.value.includes(dot.label),
          hovered: index === hoveredDot.value,
        }))
        .sort((a, b) => {
          switch (sortMethod.value) {
            default:
            case 'highest X':
              return b.x - a.x
            case 'lowest X':
              return a.x - b.x
            case 'highest Y':
              return b.y - a.y
            case 'lowest Y':
              return a.y - b.y
          }
        })
    })

    const groupLabelMap = computed<Record<number, string>>(() => {
      if (selectedData.value === 'Themes') {
        return themeToGroupNameMap.value
      }
      if (selectedData.value === 'Theme Groups') {
        return groupToGroupNameMap.value
      }
      return {}
    })

    const getCsvData = () => {
      let nameMap: Record<number, string> = {}
      if (selectedData.value === 'Themes') {
        nameMap = themeNameMap.value
      }
      if (selectedData.value === 'Theme Groups') {
        nameMap = themeGroupNameMap.value
      }

      return rowsToCsv(
        dataPoints.value,
        displayType.value,
        selectedData.value,
        selectedDisplay.value,
        visibleDots.value,
        nameMap,
        groupLabelMap.value,
      )
    }

    const getChartEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const togglePoint = (index: number) => {
      if (!hidden.value.includes(visibleDots.value[index].label)) {
        hidden.value.push(visibleDots.value[index].label)
      } else {
        // Remove the label with a filter.
        hidden.value =  hidden.value.filter(x => x !== visibleDots.value[index].label)
      }
    }

    const selectAll = () => {
      hidden.value = []
    }

    const deselectAll = () => {
      hidden.value = dots.value.map((d) => d.label)
    }

    const refresh = () => {
      window.location.reload()
    }

    const fetchData = (force = false) => {
      if (!selectedData.value) return
      /** request for segmentation chart overall data */
      const getPivotQueries = () => {
        if (selectedData.value === 'Themes') {
          return props.queries
        } else if (selectedData.value === 'Theme Groups') {
          return props.themeGroups
        } else {
          return props.concepts
        }
      }
      const numericalFields = displayType.value === 'Numerical Field' ? [selectedDisplay.value || ''] : null
      const scoreField = displayType.value === 'Score' ? scoreColumnsMap.value[selectedDisplay.value]: null
      let filters = scoreField?
        [
          {field: scoreField.name, op: '>=', value: scoreField.range[0]},
          {field: scoreField.name, op: '<=', value: scoreField.range[1]}
        ]:
        []

      let requirements = baseRequirements(
        props.hasNps,
        props.hasSentiment,
        numericalFields,
        selectedData.value,
        getPivotQueries() as SavedQuery[],
        ['Themes', 'Theme Groups'].includes(selectedData.value) ? 0 : 25,
        selectedData.value,
        scoreField,
      )

      emit('requires',
        'quadrant__queries',
        requirements.queries,
        force,
        [
          ...props.dashboardFilters,
          ...filters,
        ]
      )

      if (!isQueryView.value) {
        emit('requires',
          'quadrant__fields',
          requirements.fields,
          force,
          [
            ...props.dashboardFilters,
            ...filters,
          ]
        )
      }
    }

    const reload = () => {
      fetchData(true)
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    }

    const updateConfig = () => {
      // We do not want to store the score column as display with the aggregation name,
      // so we substitute the display name to be simply column name when saving the config.
      let display = Object.keys(scoreColumnsMap.value).includes(selectedDisplay.value)
        ? scoreColumnsMap.value[selectedDisplay.value].name
        : selectedDisplay.value

      const updated: typeof props.config =
        Object.assign({}, props.config, {
          options: {
            display: display,
            data: selectedData.value,
            limitX: limitX.value,
            limitY: limitY.value,
            dotLimit: dotLimit.value === Infinity ? null : dotLimit,
            sortMethod: sortMethod,
          }
        })

      emit('config-changed', updated)
    }

    const setSelection = (menu: string, [title, item]: [string, string], fromConfig = false) => {
      switch (menu) {
        case 'Zoom to':
            limitX.value = Number(item)
            limitY.value = Number(item)
            break
        case 'Display':
            if (!fromConfig) {  // don't track programmatic setting of this option
              analytics?.track.quadrantChart.changeDisplay(title, item)
            }
            if (item === npsField.value) {
              selectedDisplay.value = npsField.value
              break
            }
            selectedDisplay.value = item
            break
        case 'Data':
            selectedData.value = item
            if (!fromConfig) {  // don't track programmatic setting of this option
              analytics?.track.quadrantChart.changeField(title, item)
            }
            break
      }
      if (!fromConfig) {
        updateConfig()
      }
      fetchData()
    }

    const setToolTip = (index: number | null) => {
      hoveredDot.value = index === null || index === undefined ? null : index
    }

    const setOptionsFromConfig = () => {
      const validOptions = validatedOptions.value
      selectedDisplay.value = validOptions.display
      selectedData.value = validOptions.data
      limitX.value = props.config?.options?.limitX ?? Limit.SELECTION
      limitY.value = props.config?.options?.limitY ?? Limit.SELECTION
      sortMethod.value = props.config?.options?.sortMethod ?? 'highest Y'
      if (props.config?.options?.dotLimit === null) {
        dotLimit.value = Infinity
      } else {
        dotLimit.value = props.config?.options?.dotLimit ?? 10
      }
    }

    const selectedDataType = computed(() => {
      let type: 'theme' | 'group' | 'concept' | 'segment' | null = null
      switch (selectedData.value) {
        case 'Themes':
          type = 'theme'
          break
        case 'Theme Groups':
          type = 'group'
          break
        case 'Top Concepts':
          type = 'concept'
          break
        default:
          type = 'segment'
          break
      }
      return type
    })

    const addSegmentFilter = (dot: QuadrantChartDot) => {
      emit('segment-clicked', selectedData.value, dot.label)
    }

    const navigateTo = (dot: QuadrantChartDot) => {
      switch (selectedDataType.value) {
        case 'concept':
          emit('go-to-concept', dot.label)
          break
        case 'theme':
          emit('go-to-theme', +dot.id.replace('q_', ''))
          break
        case 'group':
          emit('go-to-theme-group', +dot.id.replace('group_', ''))
          break
        case 'segment':
          emit('go-to-segment', selectedData.value, dot.label)
          break
      }
    }

    const quadrantColors = computed((): Record<string, string> => {
      return getQuadrantColors(selectedDisplay.value, npsField.value!)
    })

    const menus = computed((): WidgetMenuOptions[] => {
      let scoreColOptions = Object.keys(scoreColumnsMap.value)
      return getMenus(
        selectedDisplay.value,
        selectedData.value,
        props.sortedSegmentsPerField,
        props.hasNps,
        props.hasSentiment,
        props.hasNumericFields,
        npsField.value,
        numericalFields.value,
        scoreColOptions,
        props.isZoomed,
        limitX.value,
        hasQueries.value,
        featureFlags!,
      )
    })

    const toolTip = computed((): Tooltip | null => {
      if (hoveredDot.value === null) return null
      const idAttribute = ['Themes', 'Theme Groups'].includes(selectedData.value)
        ? 'id'
        : 'label'
      let id = visibleDots.value[hoveredDot.value][idAttribute]
      const dot = dataPoints.value.find((dp) => dp.label === id)
      if (!dot) return null
      let label = dot.label

      if (selectedDataType.value === 'theme') {
        label = themeNameMap.value[Number(id.replace(/^q_/, ''))]
      }

      if (selectedDataType.value === 'group') {
        label = themeGroupNameMap.value[Number(id.replace(/^group_/, ''))]
      }

      let toolTip: Partial<Tooltip> = {
        label,
        records: dot.records,
        frequency: dot.frequency
      }

      if (props.hasNps && displayType.value === 'NPS') {
        toolTip = {
          ...toolTip,
          nps: dot['NPS Category|nps__'],
          promoters: dot['NPS Category|Promoter%__']/100,
          detractors: dot['NPS Category|Detractor%__']/100,
          passives: dot['NPS Category|Passive%__']/100,
        }
      }
      if (props.hasSentiment && displayType.value === 'Sentiment') {
        toolTip = {
          ...toolTip,
          positive: dot['sentiment__|positive%__']/100,
          negative: dot['sentiment__|negative%__']/100,
          mixed: dot['sentiment__|mixed%__']/100,
          neutral: dot['sentiment__|neutral%__']/100
        }
      }
      if (props.hasNumericFields && displayType.value === 'Numerical Field') {
        toolTip = {
          ...toolTip,
          aggregationLabel: selectedDisplay.value,
          aggregationValue: Number(dot[`${selectedDisplay.value}|mean__`]),
        }
      }
      if (displayType.value === 'Score') {
        const scoreCol = scoreColumnsMap.value[selectedDisplay.value]
        const isBox = ["top box", "bot box"].includes(scoreCol.aggregation.type)
        toolTip = {
          ...toolTip,
          aggregationLabel: isBox? `${scoreCol.aggregation.title} (%)`: scoreCol.aggregation.title,
          aggregationValue: isBox? Number(dot[`${scoreCol.name}|box%__`]): Number(dot[`aggVal|mean__`])
        }
      }
      return toolTip as Tooltip
    })

    const xMinMax = computed((): { min: number, max: number } => {
      const padding = 0
      const isNPS = selectedDisplay.value === npsField.value

      if (!visibleDots.value) return isNPS
        ? { min: -100, max: 100 }
        : { min: 0, max: 100 }

      let dots = []
      switch (limitX.value) {
        case Limit.SELECTION:
          dots = visibleDots.value.filter(dot => dot.label !== 'overall__' && !dot.hidden).map(dot => dot.x)
          break
        case Limit.FULL:
        case Limit.DATA:
        default:
          dots = visibleDots.value.filter(dot => dot.label !== 'overall__').map(dot => dot.x)
          break
      }

      // if we are in selection mode & have unselected everything:
      //  - return FULL range for sentiment & NPS
      //  - otherwise include all dots (with overall) to determine full range to return
      if (dots.length === 0 && limitX.value === Limit.SELECTION) {
        if (
          (displayType.value === 'Sentiment') ||
          (!isNPS && displayType.value === 'NPS')
        ) return { min: 0, max: 100 }
        if (isNPS) return { min: -100, max: 100}
        dots = visibleDots.value.map((dot) => dot.x)
      }

      if (
        limitX.value === Limit.FULL &&
        displayType.value === 'Sentiment'
      ) return { min: 0, max: 100 }

      const result = minMax(dots, padding)
      let {min, max} = result

      // min width to avoid wierd visual
      if (min === max) {
        max += 3
      }

      // if showing NPS & no range limit, then -100 to 100
      if (isNPS && limitX.value === Limit.FULL) {
        min = -100
        max = 100
      }

      // if showing NPS (with limit) ensure -100 to 100
      if (isNPS && limitX.value !== Limit.FULL) {
        min = min < -100 ? -100 : min
        max = max > 100 ? 100 : max
      }
      return { min, max }
    })

    const yMinMax = computed((): { min: number, max: number } => {
      const padding = 0
      if (!visibleDots.value) return { min: 0, max: 100 }
      let dots = []
      switch (limitY.value) {
        case Limit.FULL:
          return { min: 0, max: 100 }
        case Limit.SELECTION:
          dots = visibleDots.value.filter(dot => dot.label !== 'overall__' && !dot.hidden).map(dot => dot.y)
          break
        case Limit.DATA:
        default:
          dots = visibleDots.value.filter(dot => dot.label !== 'overall__').map(dot => dot.y)
          break
      }

      // if we are in selection mode & have unselected everything return FULL range
      if (dots.length === 0 && limitY.value === Limit.SELECTION) return { min: 0, max: 100 }

      let { max, min } = minMax(
        dots,
        padding
      )

      // minimum height so we don't end up in a weird visual
      if (max === 0) {
        max += 3
      }
      return {
        max,
        min
      }
    })

    // we set the color here because it requires the max Y value to have been already been calculated
    // and doing it in visible dots causes an infinite depth self-reference error
    const colouredVisibleDots = computed((): QuadrantChartDot[] => {
      return visibleDots.value.map(dot=>({
        ...dot,
        color: dotColor(dot.x, dot.y, selectedDisplay.value, npsField.value!, yMinMax.value, xMinMax.value),
      }))
    })

    // Labels to display alongside dots e.g parent group names
    const dotLabels = computed(() => {
      const labelPairs =
        colouredVisibleDots.value.reduce((arr, dot) => {
          if (selectedDataType.value === 'theme') {
            const themeId = Number(dot.id.replace('q_', ''))
            return arr.concat([[dot.id, themeToGroupNameMap.value[themeId]]])
          }
          if (selectedDataType.value === 'group') {
            const groupId = Number(dot.id.replace('group_', ''))
            return arr.concat([[dot.id, groupToGroupNameMap.value[groupId]]])
          }
          return arr
        }, [] as [string, string][])

      return showGroupLabels.value? Object.fromEntries(labelPairs): {}
    })

    const overallRow = computed(() => {
      if (!props.data) return null
      return getOverallRow(props.data?.queries?.data?.payload)
    })

    const yAxis = computed(() => {
      let min = 0
      let max = 100
      if (yMinMax.value) {
        max = yMinMax.value.max
        min = yMinMax.value.min
      }

      return {
        label: 'Relative Frequency',
        min,
        max,
        percent: true,
        markers: [
          {
            label: `${min}%`,
            value: min
          },
          {
            label: `${max}%`,
            value: max
          }
        ]
      }
    })

    const xAxis = computed(() => {
      let min = 0
      let max = 100
      if (xMinMax.value) {
        min = xMinMax.value.min
        max = xMinMax.value.max
      }
      // Find the score Column names that have aggregation "top box"/ "bot box"
      let scoreColOptions = Object.values(scoreColumnsMap.value)
        .filter((col) => !["top box", "bot box"].includes(col.aggregation.type))
        .map((col) => `${col.name} (${col.aggregation.title})`)
      const percent =
        !numericalFields.value
        .concat(scoreColOptions)
        .concat(npsField.value!)
        .includes(selectedDisplay.value)

      return {
        label: selectedDisplay.value,
        min: min,
        max: max,
        percent,
        markers: [
          {
            label: `${min}${percent ? '%' : ''}`,
            value: min
          },
          {
            label: `${max}${percent ? '%' : ''}`,
            value: max
          }
        ]
      }
    })

    // returns array of objects for the vertical overall bar on the chart
    // we one have one, the overall, but the chart supports multiple,
    // hence it has to be an array
    const vert = computed(() => {
      if (!selectedDisplay.value || !dataPoints.value) return []
      if (!yMinMax.value || !yMinMax.value.max) return []
      const overall = overallRow.value
      if (!overall) return []
      let value = 0
      let label = ''
      let color = "#000"
      const scoreNames = Object.keys(scoreColumnsMap.value)
      const npsLabels = ['Detractors', 'Passives', 'Promoters']
      const sentimentLabels = ['Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment']
      if (selectedDisplay.value === npsField.value) {
        value = Number(overall['NPS Category|nps__'])
        label = `Overall NPS (${formatNPS(value)})`
      } else if (npsLabels.includes(selectedDisplay.value)) {
        value = Number(overall[`NPS Category|${selectedDisplay.value.slice(0, -1)}%__`])
        label = `Overall ${selectedDisplay.value} (${decimalAsPercent(value/100)})`
      } else if (sentimentLabels.includes(selectedDisplay.value)) {
        const sentimentKey = `sentiment__|${selectedDisplay.value.split(' ')[0].toLowerCase()}\%__`
        value = Number(overall[sentimentKey])
        label = `Overall ${selectedDisplay.value} (${decimalAsPercent(value/100)})`
      } else if (scoreNames.includes(selectedDisplay.value)) {
        const scoreCol = scoreColumnsMap.value[selectedDisplay.value]
        const isBox = ["top box", "bot box"].includes(scoreCol.aggregation.type)
        value = isBox? Number(overall[`${scoreCol.name}|box%__`]): Number(overall['aggVal|mean__'])
        label = `Overall ${selectedDisplay.value} (${number(value, '0.00')}${isBox? '%': ''})`
      } else {
        // Assuming Numerical Fields here.
        value = Number(overall[`${selectedDisplay.value}|mean__`])
        label = `Overall ${selectedDisplay.value} (${number(value, '0.00')})`
      }

      if (yMinMax.value && yMinMax.value.max) {
        color = dotColor(
          0,
          yMinMax.value.max,
          selectedDisplay.value,
          npsField.value!,
          yMinMax.value,
          xMinMax.value,
        )
      }

      return [{
        id: label,
        label,
        value,
        color,
        showLine: value >= xMinMax.value.min && value <= xMinMax.value.max
      }]
    })

    const userErrors = computed(() => {
      if (isQueryView.value) {
        return props.data?.queries?.userError
      } else {
        return props.data?.queries?.userError || props.data?.fields?.userError
      }
    })

    const hasErrored = computed(() => {
      // if we are showing queries then we don't check the fields props
      if (isQueryView.value) {
        return !!props.data?.queries?.error
      } else {
        return !!props.data?.queries?.error || !!props.data?.fields?.error
      }
    })

    watch(() => props.config, () => {
      setOptionsFromConfig()
    }, {
      deep: true,
    })

    watch(() => props.queries, () => {
      fetchData()
    }, {
      deep: true,
    })

    onMounted(() => {
      setOptionsFromConfig()
      fetchData()
    })

    const sortMethod = ref<SortMethod>('highest Y')
    const dotLimit = ref<number>(10)

    const sortOptions = computed<[string, SortMethod][]>(() => {
      return [
        [`Highest ${xAxis.value.label}`, 'highest X'],
        [`Lowest ${xAxis.value.label}`, 'lowest X'],
        [`Highest ${yAxis.value.label}`, 'highest Y'],
        [`Lowest ${yAxis.value.label}`, 'lowest Y'],
      ]
    })

    return {
      icon,
      errorIcon,
      root,
      makePptSlide,
      getSvgExportConfig,
      exportButton,
      getCsvData,
      getChartEl,
      isLoading,
      setChartDimensions,
      setSelection,
      selectAll,
      deselectAll,
      visibleDots,
      hidden,
      dataPoints,
      reload,
      contact,
      refresh,
      dots,
      width,
      height,
      setToolTip,
      selectedData,
      selectedDataType,
      navigateTo,
      togglePoint,
      quadrantColors,
      menus,
      toolTip,
      colouredVisibleDots,
      yAxis,
      xAxis,
      vert,
      userErrors,
      hasErrored,
      hasQueries,
      themeToGroupNameMap,
      groupToGroupNameMap,
      addSegmentFilter,
      featureFlags,
      dotLabels,
      scoreColumnsMap,
      sortOptions,
      sortMethod,
      dotLimit,
      updateConfig,
      groupLabelMap,
    }
  },
})

export default QuadrantWidget
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .checkbox-list
    padding: 10px 0
    overflow: auto
    > div:not(:last-child)
      margin-bottom: 4px

  ::v-deep .content
    padding: 40px 20px 20px 20px !important

  ::v-deep .svgContainer
    overflow: hidden

  ::v-deep footer
    display: none

  .error-panel
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    padding-bottom: 30px

  .message
    display: flex
    flex-direction: row
    justify-content: center
    background-color: rgba(255,0,0, 0.1)
    padding: 6px
    color: $text-black
    width: 100%
    max-height: 30px
    position: absolute
    bottom: 0

  .errorIcon
    position: relative
    height: 32px
    width: 32px
    display: inline-block
    top: 10px

  .action
    padding-top: 20px

  button
    background: none
    border: none
    border-bottom: 2px solid $blue
    padding: 3px 4px
    &:hover
      background-color: $grey-light
    &:focus
      border: 2px solid $blue-light
      outline: none

  button.legend-button
    background-color: transparent
    display: inline-block
    outline: none
    font-family: $standard-font
    font-size: 12px
    font-weight: bold
    text-align: center
    text-decoration: none
    margin: 0
    border: solid 1px transparent
    border-radius: 2px
    padding: 0.1em 0
    color: #a8a8a8
    &:first-of-type
      margin-right: 10px
    &:hover
      cursor: pointer
      color: #068ccc
      background-color: transparent

  .empty-message
    text-align: center
    color: $subdued
    font-size: 2rem
    margin: 40px 0

.legend
  width: 100%
</style>
